import * as yup from 'yup';

export const validationScheme = yup
    .object()
    .shape({
        promocodeValue: yup
            .string()
            .trim()
            .min(2, 'errorInputs.noLessTwoLetters')
            .max(16, 'errorInputs.noMoreSixteenLetter')
            .matches(
                /^[A-Za-z0-9]+$/gi,
                'errorInputs.promoLatin'
            )
            .required('errorInputs.required'),
    })
    .required();
