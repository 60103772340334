const PAYMENT_PROVIDER_IDS = {
  // CLOUD: 2,
  // TODO: uncomment once payment service is ready
  // RF: 3,
  CRYPTO: 4,
  PAYZE: 5,
  RU_CLOUDPAYMENTS: 7,
} as const;

export { PAYMENT_PROVIDER_IDS };
