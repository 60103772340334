import React from 'react';
import { Container, Layout, Logo, Typography } from 'shared/_ui';
import styles from './styles.module.scss';

type AuthenticationContainerProps = {
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  hint?: React.ReactNode;
  layoutControls?: React.ReactNode | React.ReactNode[];
};

const AuthenticationContainer: React.FC<AuthenticationContainerProps> = ({
  title,
  children,
  footer,
  hint,
  layoutControls,
}) => {
  const classNamePrefix = 'container';

  return (
    <Layout controls={layoutControls} className={styles.wrapper}>
      <Container className={styles[classNamePrefix]}>
        <div className={styles.logo}>
          <Logo size="middle" redirect />
        </div>
        {title && (
          <div className={styles.title}>
            <Typography.Title level={2}>{title}</Typography.Title>
          </div>
        )}
        <div className={styles.body}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </Container>
      {hint && <div className={styles.hint}>{hint}</div>}
    </Layout>
  );
};

export { AuthenticationContainer };
