import React from 'react';
import styles from './styles.module.scss';
import { Button, Download } from 'shared/_ui';
import { Device } from 'entities/Device';
import { useTranslation } from 'react-i18next';
import { ConfigBody, useFetchMobileConfig } from 'entities/Connection';
import { ConfigVpnType } from 'shared/api';
import { notify } from 'shared';

type DownloadConfigProps = {
  device: Device;
  connectionId: number;
  server: string;
};

const DownloadConfig: React.FC<DownloadConfigProps> = ({
  device,
  connectionId,
  server,
}) => {
  const { t } = useTranslation();

  const [fetchConfig] = useFetchMobileConfig();

  const downloadConfig = async () => {
    const params: ConfigBody = {
      type:
        device === 'android'
          ? ConfigVpnType.ANDROID_SWAN
          : ConfigVpnType.APPLE_NATIVE,
      radiusUserId: connectionId,
      serverName: server,
    };

    try {
      await fetchConfig(params).unwrap();
    } catch (error) {
      notify.error(t('shared.error.download'));
    }
  };

  return (
    <Button
      size="large"
      className={styles.button}
      prefix={<Download className={styles.download} />}
      type="secondary"
      onClick={downloadConfig}
    >
      {t('guide.config.download')}
    </Button>
  );
};

export { DownloadConfig };
