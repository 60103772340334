import { Device } from 'entities/Device';
import { Installation } from 'entities/Installation';
import React from 'react';

type SliderContextType = {
  next: () => void;
  prev: () => void;
  device: Device;
  slideNumber: number;
  setDevice: (device: Device) => void;
  chosenServerId?: number;
  setChosenServerId: (id: number) => void;
  chosenCountry?: string;
  setChosenCountry: (country: string) => void;
  installationType: Installation;
  setInstallationType: (installationType: Installation) => void;
  xrayConnectionLink?: string;
  setXrayConnectionLink: (connectionLink: string) => void;
  isConnectionCreate: boolean;
};

const SliderContext = React.createContext<SliderContextType>(null!);

const useSliderContext = () => React.useContext(SliderContext);

type SliderContextProviderProps = {
  children: React.ReactNode;
  state: SliderContextType;
};

const SliderContextProvider: React.FC<SliderContextProviderProps> = ({
  children,
  state,
}) => {
  return (
    <SliderContext.Provider value={state}>{children}</SliderContext.Provider>
  );
};

export { useSliderContext, SliderContextProvider };
