import React from 'react';
import classNames from 'classnames';
import { PrivateGate } from '../Icon';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type LogoSize = 'large' | 'middle' | 'small';

type LogoProps = {
  size?: LogoSize;
  redirect?: boolean;
  className?: string;
};

const Logo: React.FC<LogoProps> = ({
  size = 'middle',
  className,
  redirect = false,
}) => {
  const classNamePrefix = 'logo';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-${size}`],
    { [styles.redirect]: redirect }
  );

  const { i18n } = useTranslation();

  const href =
    i18n.language === 'ru'
      ? 'https://privategatevpn.com/ru'
      : 'https://privategatevpn.com/';

  if (redirect) {
    return (
      <a className={_className} href={href} target="_blank" rel="noreferrer">
        <PrivateGate />
        <span>Private Gate</span>
      </a>
    );
  }

  return (
    <div className={_className}>
      <PrivateGate />
      <span>Private Gate</span>
    </div>
  );
};

export { Logo };
