import * as yup from 'yup';

export const validationScheme = yup
  .object()
  .shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .email('shared.error.email')
      .required('shared.error.required'),
  })
  .required();
