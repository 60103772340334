import { $rtkApi } from 'shared/api/rtkApi';

export interface XrayServer {
  id: number;
  country: string;
}

const availableServersApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    fetchXrayServers: build.query<XrayServer[], null>({
      query: () => ({
        url: '/api/xray/servers',
      }),
    }),
  }),
  overrideExisting: false,
});

export const useFetchXrayServers = availableServersApi.useFetchXrayServersQuery;
