import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const InfinityIcon: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 24 24"
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.433 8.596c-1.153 0-2.237.449-3.036 1.246l-1.396 1.34-1.375-1.32c-.815-.817-1.901-1.266-3.055-1.266-1.154 0-2.239.451-3.053 1.266-.817.816-1.267 1.9-1.267 3.055 0 1.152.449 2.238 1.266 3.053.814.816 1.899 1.266 3.054 1.266 1.153 0 2.239-.449 3.036-1.248l1.395-1.338 1.376 1.32c.815.816 1.901 1.266 3.055 1.266s2.238-.449 3.053-1.266c.817-.814 1.267-1.9 1.267-3.055s-.449-2.238-1.266-3.055c-.817-.815-1.901-1.264-3.054-1.264zm-7.576 5.605c-.687.688-1.884.688-2.572 0-.344-.344-.533-.801-.533-1.285 0-.486.189-.941.535-1.287.342-.344.799-.533 1.284-.533s.942.189 1.305.551l1.321 1.27-1.34 1.284zm8.861 0c-.687.689-1.866.705-2.59-.018l-1.321-1.27 1.339-1.285c.688-.688 1.886-.688 2.573-.002.344.346.533.801.533 1.287s-.19.944-.534 1.288z"></path>
    </svg>
  );
};

export { InfinityIcon };
