import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorage } from 'usehooks-ts';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/lib/notification';
import { getRestoreSuccess } from 'shared/consts/routes';
import { validationScheme } from '../../lib/validationScheme';
import { CreateReset, useCreateReset } from '../../api/reset';

import styles from './styles.module.scss';
import { Button, Flex, MaterialInput, Typography } from 'shared/_ui';

const RESTORE_EMAIL = 'restore-email';

export const RestoreForm: FC = () => {
  // eslint-disable-next-line
  const [_, setEmail] = useLocalStorage<string | null>(RESTORE_EMAIL, null);
  const { t } = useTranslation();
  const [create, { isLoading }] = useCreateReset();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateReset>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: CreateReset) => {
    await create(data)
      .unwrap()
      .then(() => {
        setEmail(data.email);
        navigate(getRestoreSuccess());
      })
      .catch((error) => {
        if (error?.status === 400) {
          notify.error(t('authentication.notify.userNotFound'));
        } else {
          notify.error(t('shared.error.base'));
        }
      });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" spacing={16} className={styles.wrapper}>
        <div className={styles.field}>
          <MaterialInput
            type="email"
            placeholder={t('authentication.restore.email')}
            {...register('email')}
          />
          {errors.email && (
            <Typography.Text type="danger">
              {t(
                errors.email?.message
                  ? errors.email?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </div>
        <Button loading={isLoading} className={styles.button} type="primary">
          {t('authentication.restore.send')}
        </Button>
      </Flex>
    </form>
  );
};
