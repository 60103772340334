import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { clearStorages } from 'shared';
import { useTranslation } from 'react-i18next';
import { useUserActions } from 'entities/User';
import { notify } from 'shared/lib/notification';
import {
  ChangePasswordValues,
  useChangePassword,
} from '../../api/changePassword';
import { validationScheme } from '../../lib/validationScheme';
import { Button, Flex, MaterialInput, Typography } from 'shared/_ui';
import styles from './styles.module.scss';

export const ChangePasswordForm: FC = () => {
  const { t } = useTranslation();
  const [changePassword, { isLoading }] = useChangePassword();
  const { logout } = useUserActions();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordValues>({
    mode: 'onSubmit',
    defaultValues: { currentPassword: '', newPassword: '' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (values: ChangePasswordValues) => {
    await changePassword(values)
      .unwrap()
      .then(() => {
        logout();
        clearStorages(true);
      })
      .catch(() => {
        notify.error(t('authentication.password.wrong'));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" spacing={24} className={styles.wrapper}>
        <div className={styles.field}>
          <Typography.Text>
            {t('authentication.password.current')}
          </Typography.Text>
          <MaterialInput.Password
            placeholder={t('authentication.password.enter')}
            {...register('currentPassword')}
          />
          {errors.currentPassword && (
            <Typography.Text type="danger">
              {t(
                errors.currentPassword?.message
                  ? errors.currentPassword?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </div>
        <div className={styles.field}>
          <Typography.Text>{t('authentication.password.new')}</Typography.Text>
          <MaterialInput.Password
            autoComplete="new-password"
            placeholder={t('authentication.password.enter')}
            {...register('newPassword')}
          />
          {errors.newPassword && (
            <Typography.Text type="danger">
              {t(
                errors.newPassword?.message
                  ? errors.newPassword?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </div>
        <Button loading={isLoading} className={styles.button} type="primary">
          {t('authentication.password.changeButton')}
        </Button>
      </Flex>
    </form>
  );
};
