/* eslint-disable fsd-stable/fsd-layer-imports */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from 'entities/User';
import { LOCAL_STORAGE_USER_KEY } from 'shared/consts/localStorage';

const getToken = () => {
    const authData = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_USER_KEY) || '{}'
    ) as User;

    return authData.token || '';
};

export const $rtkApi = createApi({
    reducerPath: 'rtkApi',
    tagTypes: ['UserInfo', 'ServiceProblemInfo'],
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${getToken()}`);
            return headers;
        },
    }),
    endpoints: () => ({}),
});
