import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
  element?: HTMLElement;
}

const Portal: React.FC<PortalProps> = ({
  children,
  element = document.body,
}) => {
  return ReactDOM.createPortal(children, element);
};

export { Portal };
