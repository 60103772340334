import React from 'react';
import styles from './styles.module.scss';
import { GuideSlide } from 'entities/Guide';
import { useSliderContext } from '../../model';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Arrow, Button, Flex, Typography } from 'shared/_ui';
import { InstallationTypeSelector } from 'entities/Installation';
import { getTariffType } from 'entities/Tariff';
import { useFetchUserInfo } from 'entities/Connection';

type InstallationTypeSlideProps = { className?: string };

const InstallationTypeSlide: React.FC<InstallationTypeSlideProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  const { installationType, slideNumber, setInstallationType, next, prev } =
    useSliderContext();
  const { data: userInfo } = useFetchUserInfo(null);

  const tariff = userInfo ? getTariffType(userInfo?.tariff) : 'free';

  return (
    <GuideSlide
      className={classNames(
        className,
        styles.slide,
        styles[`slide-${installationType}`]
      )}
      head={
        <Flex direction="column" spacing={16}>
          <Flex spacing={20}>
            <Flex className={styles.number} align="center" justify="center">
              <Typography.Text size={6}>2</Typography.Text>
            </Flex>
            <Typography.Title level={2}>
              {t('installation.select')}
            </Typography.Title>
          </Flex>
          <Typography.Text
            className={styles.description}
            type="secondary"
            size={4}
          >
            {t('installation.description')}
          </Typography.Text>
        </Flex>
      }
      body={
        <InstallationTypeSelector
          tariff={tariff}
          hidePopups={slideNumber !== 1}
          value={installationType}
          onChange={setInstallationType}
        />
      }
      controls={
        <Flex justify="space-between" spacing={10}>
          <Button
            onClick={prev}
            className={styles.back}
            type="secondary"
            prefix={<Arrow className={styles.arrow} />}
          >
            {t('shared.text.back')}
          </Button>
          <Button
            onClick={next}
            className={styles.next}
            type="primary"
            disabled={installationType === 'app' && tariff === 'free'}
            suffix={<Arrow className={styles.arrow} />}
          >
            {t('shared.text.continue')}
          </Button>
        </Flex>
      }
    />
  );
};

export { InstallationTypeSlide };
