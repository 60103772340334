import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Device } from 'entities/Device';
import { Installation } from 'entities/Installation';
import { SliderContextProvider } from '../../model';
import { DeviceSlide } from '../DeviceSlide';
import { Carousel, Flex } from 'shared/_ui';
import { InstallationTypeSlide } from '../InstallationTypeSlide';
import classNames from 'classnames';
import Slider from 'react-slick';
import { InstructionSlide } from '../InstructionSlide';
import { SuccessSlide } from '../SuccessSlide';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { useStoreUiControls } from 'shared/lib';
import { useRefContext } from 'shared/context';
import { CountrySelectSlide } from '../CountrySelectSlide';
import { useMatchMedia } from 'shared';

const ANIMATION_TIME = 500;

const GuideSlider: React.FC = () => {
  const [centerPadding, setCenterPadding] = React.useState('168px');
  const [slideNumber, setSlideNumber] = React.useState<number>(0);
  const [chosenServerId, setChosenServerId] = useState<number | undefined>();
  const [device, setDevice] = React.useState<Device>('mac');
  const [chosenCountry, setChosenCountry] = useState<string | undefined>();
  const [xrayConnectionLink, setXrayConnectionLink] = useState<
    string | undefined
  >();
  const [installationType, setInstallationType] =
    React.useState<Installation>('app');

  const { isMobile } = useMatchMedia();

  const { refs } = useRefContext();

  const { data } = useStoreUiControls(CONTROL_IDS.GUIDE);

  const carouselRef = React.useRef<Slider | null>(null);

  const next = () => {
    setTimeout(() => {
      refs.current[REF_IDS.LAYOUT]?.scrollTo({ top: 0, behavior: 'smooth' });
    });
    setTimeout(() => {
      carouselRef.current?.slickNext();
    });
  };

  const prev = () => {
    setTimeout(() => {
      refs.current[REF_IDS.LAYOUT]?.scrollTo({ top: 0, behavior: 'smooth' });
    });
    setTimeout(() => {
      carouselRef.current?.slickPrev();
    });
  };

  React.useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const baseWidth = 1173;
      const sidePaddings = 36 * 2;
      const slidePadding = 8 * 2;
      let padding = 16;

      if (windowWidth > baseWidth + sidePaddings) {
        padding =
          (windowWidth - baseWidth - sidePaddings + padding + slidePadding) / 2;
      }

      if (isMobile) {
        padding = 0;
      }

      setCenterPadding(`${Math.max(padding, 0)}px`);
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <SliderContextProvider
      state={{
        device,
        setDevice,
        chosenCountry,
        setChosenCountry,
        installationType,
        setInstallationType,
        slideNumber,
        next,
        chosenServerId,
        setChosenServerId,
        xrayConnectionLink,
        setXrayConnectionLink,
        prev,
        isConnectionCreate: data?.isConnectionCreate,
      }}
    >
      <Flex direction="column" justify="center" className={styles.container}>
        <Carousel
          lazyLoad="ondemand"
          speed={ANIMATION_TIME}
          waitForAnimate
          draggable={false}
          swipe={false}
          infinite={false}
          arrows={false}
          ref={carouselRef}
          centerMode
          centerPadding={centerPadding}
          beforeChange={(_, slide) => setSlideNumber(slide)}
        >
          <DeviceSlide
            className={classNames(
              styles.slide,
              slideNumber === 0
                ? styles['slide-active']
                : styles['slide-inactive']
            )}
          />
          <InstallationTypeSlide
            className={classNames(
              styles.slide,
              slideNumber === 1
                ? styles['slide-active']
                : styles['slide-inactive']
            )}
          />
          {installationType === 'app' && (
            <CountrySelectSlide
              className={classNames(
                styles.slide,
                slideNumber === 2
                  ? styles['slide-active']
                  : styles['slide-inactive']
              )}
            />
          )}
          <InstructionSlide
            isActive={slideNumber === (installationType === 'app' ? 3 : 2)}
            className={classNames(
              styles.slide,
              slideNumber === (installationType === 'app' ? 3 : 2)
                ? styles['slide-active']
                : styles['slide-inactive']
            )}
          />
          <SuccessSlide
            isActive={slideNumber === (installationType === 'app' ? 4 : 3)}
            className={classNames(
              styles.slide,
              slideNumber === (installationType === 'app' ? 4 : 3)
                ? styles['slide-active']
                : styles['slide-inactive']
            )}
          />
        </Carousel>
      </Flex>
    </SliderContextProvider>
  );
};

export { GuideSlider };
