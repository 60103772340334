import React, { useEffect, useState } from 'react';
import { AuthenticationContainer } from 'entities/Authentication';
import styles from './styles.module.scss';
import { Button, Envelope, Flex, Typography } from 'shared/_ui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRouteLogin, getRouteMain } from 'shared/consts';
import { useUserAuthData } from 'entities/User';
import { useLocalStorage } from 'usehooks-ts';
import { useCreateInvite } from 'features/CreateInvite';
import { notify } from 'shared';

const EmailVerificationPage: React.FC = () => {
  const [email] = useLocalStorage<string | null>('user-email', null);

  const { t } = useTranslation();
  const authData = useUserAuthData();
  const navigate = useNavigate();

  const { state } = useLocation();

  const [createInvite, { isLoading }] = useCreateInvite();
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);

      if (countdown === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Это должно решаться на уровне routes
  React.useEffect(() => {
    if (authData) navigate(getRouteMain());
  }, [authData, navigate]);

  return (
    <AuthenticationContainer
      title={t('authentication.mail.confirmationLinkSent')}
      footer={
        <Flex direction="column">
          <div className={styles.footer}>
            <Typography.Text type="secondary">
              {t('authentication.mail.didNotReceiveEmail')}
            </Typography.Text>
            <Button
              disabled={isLoading || countdown > 0}
              onClick={() => {
                if (state?.userData) {
                  createInvite(state.userData)
                    .unwrap()
                    .catch((e) => {
                      if (e.status === 400) {
                        notify.info(t('authentication.mail.sendCooldown'));
                      }
                    });
                }

                setCountdown(60);

                setInterval(() => {
                  setCountdown((prev) => prev--);
                }, 1000);
              }}
              className={styles.send}
              type="link"
            >
              {t('authentication.mail.sendAgain')}
            </Button>

            {countdown > 0 && (
              <Typography.Text className={styles.cooldown} type="secondary">
                {t('authentication.mail.countdown')}
                {countdown}
              </Typography.Text>
            )}
          </div>
        </Flex>
      }
      hint={
        <div className={styles.hint}>
          <Typography.Text type="secondary">
            {t('authentication.registration.alreadyHaveAccount')}
          </Typography.Text>
          <Typography.Link type="primary">
            <Link to={getRouteLogin()}>
              {t('authentication.registration.login')}
            </Link>
          </Typography.Link>
        </div>
      }
    >
      <Flex direction="column" spacing={32} className={styles.wrapper}>
        <div className={styles.description}>
          <Typography.Text type="default">
            {t('authentication.mail.description')}
          </Typography.Text>
          <Typography.Text type="secondary">
            {t('authentication.mail.checkSpam')}
          </Typography.Text>
        </div>
        {email && (
          <Typography.Mark className={styles.mail}>
            <Envelope className={styles.envelope} />
            <Typography.Text strong type="default" size={2}>
              {email}
            </Typography.Text>
          </Typography.Mark>
        )}
      </Flex>
    </AuthenticationContainer>
  );
};

export { EmailVerificationPage };
