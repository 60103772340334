import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Youtube: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3764 0.477893C22.4096 0.741298 23.2217 1.51458 23.4982 2.49859C23.9981 4.28034 24.0001 8 24.0001 8C24.0001 8 24.0001 11.7197 23.4982 13.5014C23.2217 14.4854 22.4096 15.2587 21.3764 15.5221C19.5055 16 12 16 12 16C12 16 4.49458 16 2.62364 15.5221C1.59039 15.2587 0.778402 14.4854 0.501813 13.5014C0 11.7197 0 8 0 8C0 8 0 4.28034 0.501813 2.49859C0.778402 1.51458 1.59039 0.741298 2.62364 0.477893C4.49458 0 12 0 12 0C12 0 19.5055 0 21.3764 0.477893ZM15.8328 8.00032L9.59766 11.4283V4.57227L15.8328 8.00032Z"
        fill="white"
      />
    </svg>
  );
};

export { Youtube };
