import * as yup from 'yup';

export const validationScheme = yup
  .object()
  .shape({
    token: yup.string().required('shared.error.required'),
    password: yup
      .string()
      .trim()
      .min(6, 'shared.error.minSixLetter')
      .required('shared.error.required'),
  })
  .required();
