import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Triangle: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.444853C8 0.591912 7.90949 0.713235 7.8039 0.863971L4.67128 5.58824C4.46009 5.90074 4.26399 6 3.99749 6C3.73099 6 3.53991 5.90074 3.32872 5.58824L0.191075 0.863971C0.0905091 0.709559 0 0.588235 0 0.441176C0 0.169118 0.281584 0 0.719045 0L7.27593 0.00367647C7.71339 0.00367647 8 0.172794 8 0.444853Z"
        fill="white"
      />
    </svg>
  );
};

export { Triangle };
