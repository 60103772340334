import React from 'react';
import {
  Button,
  Delete,
  Download,
  Dropdown,
  Ellipses,
  Flex,
  Popconfirm,
  Question,
  Swap,
  Typography,
  DropdownHandles,
  Popover,
  Android,
  Apple,
  PopoverHandles,
} from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import {
  ConfigBody,
  Connection,
  useDeleteConnection,
  useFetchMobileConfig,
} from 'entities/Connection';
import { notify } from 'shared';
import styles from './styles.module.scss';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS } from 'shared/consts';
import { useConnectionDevices } from 'entities/Device';
import { ConfigVpnType } from 'shared/api';

type ConnectionActionsProps = {
  id: Connection['id'];
  server: string;
};

const ConnectionActions: React.FC<ConnectionActionsProps> = ({
  id,
  server,
}) => {
  const { t } = useTranslation();

  const dropdownRef = React.useRef<DropdownHandles>(null);

  const downloadConfigRef = React.useRef<PopoverHandles>(null);

  const { open } = useStoreUiControls(CONTROL_IDS.GUIDE);

  const [deleteConnection, { isLoading }] = useDeleteConnection();

  const [fetchConfig] = useFetchMobileConfig();

  const { deleteDevice } = useConnectionDevices();

  const onChange = () => {
    console.log('change');
  };

  const onDelete = async () => {
    try {
      await deleteConnection(id).unwrap();
      deleteDevice(id);
      notify.success(t('connection.notify.successDeleted'));
      closeDropdown();
    } catch (error) {
      notify.error(t('shared.error.base'));
      closeDropdown();
    }
  };

  const downloadConfig = async (device: 'android' | 'ios') => {
    const params: ConfigBody = {
      type:
        device === 'android'
          ? ConfigVpnType.ANDROID_SWAN
          : ConfigVpnType.APPLE_NATIVE,
      radiusUserId: id,
      serverName: server,
    };

    try {
      downloadConfigRef.current?.close();
      await fetchConfig(params).unwrap();
    } catch (error) {
      notify.error(t('shared.error.download'));
    }
  };

  const closeDropdown = () => {
    dropdownRef.current?.close();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      position="bottom-right"
      contentClassName={styles.content}
      content={
        <Flex spacing={15} direction="column">
          <Popover
            ref={downloadConfigRef}
            offset={12}
            position="top"
            content={
              <Flex
                className={styles['which-device']}
                direction="column"
                spacing={16}
              >
                <Typography.Text size={2}>
                  {t('connection.whichDevice')}
                </Typography.Text>
                <Flex spacing={8}>
                  <Button
                    onClick={() => downloadConfig('ios')}
                    className={styles.device}
                    type="text"
                    prefix={<Apple className={styles.apple} />}
                  >
                    IOS
                  </Button>
                  <Button
                    onClick={() => downloadConfig('android')}
                    className={styles.device}
                    type="text"
                    prefix={<Android className={styles.android} />}
                  >
                    Android
                  </Button>
                </Flex>
              </Flex>
            }
          >
            <Button
              className={styles.action}
              type="text"
              prefix={<Download className={styles.download} />}
            >
              <Typography.Text size={2}>
                {t('connection.downloadConfig')}
              </Typography.Text>
            </Button>
          </Popover>
          <Button
            onClick={open}
            className={styles.action}
            type="text"
            prefix={<Question className={styles.question} />}
          >
            <Typography.Text size={2}>
              {t('connection.howToInstall')}?
            </Typography.Text>
          </Button>
          <Popconfirm
            title={t('connection.confirm.changeDevice')}
            onConfirm={onChange}
            disabled
          >
            <Button
              disabled
              className={styles.action}
              type="text"
              prefix={<Swap className={styles.swap} />}
            >
              <Typography.Text size={2}>
                {t('connection.changeDevice')}
              </Typography.Text>
            </Button>
          </Popconfirm>
          <Popconfirm
            title={t('connection.confirm.delete')}
            onConfirm={onDelete}
          >
            <Button
              loading={isLoading}
              className={styles.action}
              type="text"
              prefix={<Delete className={styles.delete} />}
            >
              <Typography.Text size={2}>
                {t('connection.delete')}
              </Typography.Text>
            </Button>
          </Popconfirm>
        </Flex>
      }
    >
      <Button
        className={styles.control}
        type="text"
        icon={<Ellipses className={styles.ellipses} />}
      >
        {t('connection.actions')}
      </Button>
    </Dropdown>
  );
};

export { ConnectionActions };
