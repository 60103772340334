import { $rtkApi } from 'shared/api';
import { ConfigVpnType } from 'shared/api/fetchProfileTypes';

export interface ConfigBody {
    type: ConfigVpnType;
    radiusUserId: number;
    serverName: string;
}
interface ConfigResp {
    ocal: { eap_id: string };
    name: string;
    remote: { addr: string };
    type: string;
    uuid: string;
}

const mobileConfigApi = $rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        reportExcel: builder.mutation<ConfigResp, ConfigBody>({
            query(args) {
                return {
                    url: `/api/radius/user/profile`,
                    method: 'POST',
                    body: args,
                    responseHandler: async (response) => {
                        const result = await response.blob();
                        const hiddenElement = document.createElement('a');
                        const url = window.URL || window.webkitURL;
                        hiddenElement.href = url.createObjectURL(result);
                        hiddenElement.id = 'hiddenConfig';
                        hiddenElement.target = '_blank';
                        if (args.type === ConfigVpnType.APPLE_NATIVE) {
                            hiddenElement.download = `PrivateGate_VPN_config_Apple.mobileconfig`;
                        }
                        if (args.type === ConfigVpnType.ANDROID_SWAN) {
                            hiddenElement.download = `PrivateGate_VPN_config_Android.profile`;
                        }
                        hiddenElement.click();
                        return { data: null };
                        // window.location.assign(
                        //     window.URL.createObjectURL(await response.blob())
                        // );
                    },
                    cache: 'no-cache',
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const useFetchMobileConfig = mobileConfigApi.useReportExcelMutation;
