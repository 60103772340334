import React from 'react';
import { TelegramUser, useTelegramLogin } from './api/telegramLogin';
import { useUserActions } from 'entities/User';
import { notify } from 'shared';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type AuthByTelegramProps = {
  botName: string;
  buttonSize?: 'large' | 'medium' | 'small';
  requestAccess?: 'write';
  lang?: string;
};

const AuthByTelegram: React.FC<AuthByTelegramProps> = ({
  botName,
  buttonSize = 'large',
  requestAccess = 'write',
  lang,
}) => {
  const [telegramLogin] = useTelegramLogin();
  const { t, i18n } = useTranslation();

  const { setAuthData } = useUserActions();

  const onSubmit = async (values: TelegramUser) => {
    await telegramLogin(values)
      .unwrap()
      .then((res) => {
        setAuthData(res);
      })
      .catch(() => {
        notify.error(t('authentication.login.error'));
      });
  };

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://telegram.org/js/telegram-widget.js?22`;
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', buttonSize);
    script.setAttribute('data-request-access', requestAccess);
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-lang', lang ?? i18n.language);
    script.async = true;

    document.getElementById('telegram-login-button')?.appendChild(script);

    (window as any).onTelegramAuth = (user: TelegramUser) => {
      onSubmit(user);
    };

    return () => {
      if (document.getElementById('telegram-login-button')) {
        document.getElementById('telegram-login-button')!.innerHTML = '';
      }
    };
  }, [botName]);

  return <div id="telegram-login-button" className={styles.widget}></div>;
};

export { AuthByTelegram };
