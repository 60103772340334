import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { notify } from 'shared/lib/notification';
import { getRouteLogin } from 'shared/consts/routes';
import { ResetValues, useReset } from '../../api/reset';
import { validationScheme } from '../../lib/validationScheme';

import styles from './styles.module.scss';
import { Button, Flex, MaterialInput, Typography } from 'shared/_ui';

interface ResetFormValues extends ResetValues {
  password: string;
  token: string;
}

export const ResetForm: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigation = useNavigate();
  const [reset, { isLoading }] = useReset();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetFormValues>({
    mode: 'onSubmit',
    defaultValues: { token, password: '' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: ResetFormValues) => {
    const params: ResetValues = {
      token: data.token,
      password: data.password,
    };
    await reset(params)
      .unwrap()
      .then(() => {
        notify.success(t('authentication.notify.passwordChanged'));
        navigation(getRouteLogin());
      })
      .catch((error) => {
        if (error?.status === 400) {
          notify.error(t('authentication.notify.wrongCaptcha'));
        } else {
          notify.error(t('shared.base.error'));
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" spacing={24} className={styles.wrapper}>
        <div className={styles.field}>
          <Typography.Text>{t('authentication.password.new')}</Typography.Text>
          <MaterialInput.Password
            placeholder={t('authentication.password.enter')}
            {...register('password')}
          />
          {errors.password && (
            <Typography.Text type="danger">
              {t(
                errors.password?.message
                  ? errors.password?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </div>
        <Button loading={isLoading} className={styles.button} type="primary">
          {t('authentication.password.createButton')}
        </Button>
      </Flex>
    </form>
  );
});
