import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Facebook: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.751953 11.3114C0.751953 16.7803 4.84856 21.3278 10.2064 22.25V14.3053H7.37005V11.25H10.2064V8.80525C10.2064 6.05525 12.0339 4.52808 14.6188 4.52808C15.4375 4.52808 16.3206 4.65 17.1393 4.77192V7.58333H15.6899C14.303 7.58333 13.9882 8.25525 13.9882 9.11142V11.25H17.0136L16.5096 14.3053H13.9882V22.25C19.346 21.3278 23.4426 16.7812 23.4426 11.3114C23.4426 5.2275 18.3372 0.25 12.0973 0.25C5.85734 0.25 0.751953 5.2275 0.751953 11.3114Z"
        fill="white"
      />
    </svg>
  );
};

export { Facebook };
