import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type HeaderProps = {
  children: React.ReactNode;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ children, className }) => {
  const classNamePrefix = 'header';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <header className={_className}>
      <div className={styles.container}>{children}</div>
    </header>
  );
};

export { Header };
