import { ITariff } from 'shared/api';
import { TariffType } from '../types';

export const getTariffType = (tariff: ITariff): TariffType => {
  if (
    tariff.price === 0 &&
    tariff.bandwidthName === 'MBYTES' &&
    tariff.bandwidthAmount < 20
  ) {
    return 'free';
  }

  if (
    tariff.price === 0 &&
    tariff.bandwidthName === 'MBYTES' &&
    tariff.bandwidthAmount >= 20
  ) {
    return 'free-premium';
  }

  return 'premium';
};
