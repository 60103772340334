import React from 'react';

interface Props {
  className?: string;
}

export const SuccessIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="93"
      height="93"
      viewBox="0 0 93 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.135 92.4443C71.5402 92.4443 92.1352 71.8494 92.1352 46.4443C92.1352 21.0392 71.5402 0.444336 46.135 0.444336C20.7298 0.444336 0.134766 21.0392 0.134766 46.4443C0.134766 71.8494 20.7298 92.4443 46.135 92.4443ZM62.3494 42.4572C63.7163 41.0904 63.7163 38.8743 62.3494 37.5075C60.9826 36.1406 58.7665 36.1406 57.3997 37.5075L42.904 52.0031L34.8703 43.9695C33.5035 42.6026 31.2874 42.6026 29.9206 43.9695C28.5537 45.3363 28.5537 47.5524 29.9206 48.9192L40.4291 59.4278C41.796 60.7946 44.012 60.7946 45.3789 59.4278L62.3494 42.4572Z"
        fill="url(#paint0_linear_3764_14211)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3764_14211"
          x1="76.6768"
          y1="82.0888"
          x2="30.7519"
          y2="0.444134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0BC455" />
          <stop offset="1" stopColor="#81FD5A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
