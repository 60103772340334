import { PeriodName } from 'shared/api';

export const convertToMonths = (value: number, unit: PeriodName): number => {
  if (unit === 'MONTH') {
    return value;
  }

  if (unit === 'YEAR') {
    return value * 12;
  }

  return value;
};
