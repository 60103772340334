import { $rtkApi } from 'shared/api/rtkApi';
import { ResponseUser } from '../model/userSchema';

const refreshTokenApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        refresh: build.mutation<ResponseUser, null>({
            query: () => ({
                url: '/api/token/refresh',
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});

export const useRefreshToken = refreshTokenApi.useRefreshMutation;
