import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Android: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3682_13829)">
        <path
          d="M12.7015 13.5619C16.1976 13.5619 19.0319 10.9457 19.0319 7.71843C19.0319 4.49119 16.1976 1.875 12.7015 1.875C9.2053 1.875 6.37109 4.49119 6.37109 7.71843C6.37109 10.9457 9.2053 13.5619 12.7015 13.5619Z"
          stroke="#525E67"
          strokeWidth="1.00173"
        />
        <path
          d="M17.5014 6.25781H7.90152C7.05629 6.25781 6.37109 6.94301 6.37109 7.78823V17.3882C6.37109 18.2334 7.05629 18.9186 7.90152 18.9186H17.5014C18.3467 18.9186 19.0319 18.2334 19.0319 17.3882V7.78823C19.0319 6.94301 18.3467 6.25781 17.5014 6.25781Z"
          stroke="#525E67"
          strokeWidth="1.00173"
        />
        <path
          d="M8.37943 0.943285C8.25836 0.724869 8.33728 0.449661 8.55569 0.328591C8.77411 0.207522 9.04932 0.286436 9.17039 0.504852L11.6324 4.94636C11.7534 5.16478 11.6745 5.43998 11.4561 5.56105C11.2377 5.68212 10.9625 5.60321 10.8414 5.38479L8.37943 0.943285Z"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M19.3113 9.3883C19.3113 8.46623 20.0588 7.71875 20.9808 7.71875C21.9029 7.71875 22.6504 8.46623 22.6504 9.3883V15.3013C22.6504 16.2234 21.9029 16.9708 20.9808 16.9708C20.0588 16.9708 19.3113 16.2234 19.3113 15.3013V9.3883Z"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M13.3992 16.1383C13.3992 15.2162 14.1467 14.4688 15.0687 14.4688C15.9908 14.4688 16.7383 15.2162 16.7383 16.1383V22.0513C16.7383 22.9734 15.9908 23.7208 15.0687 23.7208C14.1467 23.7208 13.3992 22.9734 13.3992 22.0513V16.1383Z"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M17.0268 0.943285C17.1479 0.724869 17.069 0.449661 16.8506 0.328591C16.6321 0.207522 16.3569 0.286436 16.2359 0.504852L13.7739 4.94636C13.6528 5.16478 13.7317 5.43998 13.9502 5.56105C14.1686 5.68212 14.4438 5.60321 14.5648 5.38479L17.0268 0.943285Z"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M6.09496 9.3883C6.09496 8.46623 5.34748 7.71875 4.42541 7.71875C3.50334 7.71875 2.75586 8.46623 2.75586 9.3883V15.3013C2.75586 16.2234 3.50334 16.9708 4.42541 16.9708C5.34748 16.9708 6.09496 16.2234 6.09496 15.3013V9.3883Z"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M12.0071 16.1383C12.0071 15.2162 11.2596 14.4688 10.3375 14.4688C9.41545 14.4688 8.66797 15.2162 8.66797 16.1383V22.0513C8.66797 22.9734 9.41545 23.7208 10.3375 23.7208C11.2596 23.7208 12.0071 22.9734 12.0071 22.0513V16.1383Z"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path d="M12.7015 13.5619C16.1976 13.5619 19.0319 10.9457 19.0319 7.71843C19.0319 4.49119 16.1976 1.875 12.7015 1.875C9.2053 1.875 6.37109 4.49119 6.37109 7.71843C6.37109 10.9457 9.2053 13.5619 12.7015 13.5619Z" />
        <path d="M17.5014 6.25781H7.90152C7.05629 6.25781 6.37109 6.94301 6.37109 7.78823V17.3882C6.37109 18.2334 7.05629 18.9186 7.90152 18.9186H17.5014C18.3467 18.9186 19.0319 18.2334 19.0319 17.3882V7.78823C19.0319 6.94301 18.3467 6.25781 17.5014 6.25781Z" />
        <path d="M6.0957 7.96875H19.313H6.0957Z" fill="#525E67" />
        <path
          d="M6.0957 7.96875H19.313"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M9.78216 5.15027C9.93584 5.15027 10.0604 5.02569 10.0604 4.87201C10.0604 4.71833 9.93584 4.59375 9.78216 4.59375C9.62849 4.59375 9.50391 4.71833 9.50391 4.87201C9.50391 5.02569 9.62849 5.15027 9.78216 5.15027Z"
          fill="#525E67"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
        <path
          d="M15.6259 5.15027C15.7796 5.15027 15.9042 5.02569 15.9042 4.87201C15.9042 4.71833 15.7796 4.59375 15.6259 4.59375C15.4722 4.59375 15.3477 4.71833 15.3477 4.87201C15.3477 5.02569 15.4722 5.15027 15.6259 5.15027Z"
          fill="#525E67"
          stroke="#525E67"
          strokeWidth="0.500865"
        />
      </g>
      <defs>
        <clipPath id="clip0_3682_13829">
          <rect
            width="20.452"
            height="23.9998"
            transform="translate(2.47852)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Android };
