import { BandWidthName, PeriodName, $rtkApi } from 'shared/api';
import { Currency } from 'shared/api/fetchTariffs';

export interface Connection {
  id: number;
  login: string;
  password: string;
  outgoingCountry: string; // enum
  enabled: boolean;
  subscription: string;
}

interface UserInfo {
  paidTo: string | null;
  createDate: string;
  tariff: {
    id: number;
    price: number;
    currency: Currency;
    periodValue: number;
    periodName: PeriodName;
    numberOfAccounts: number;
    bandwidthAmount: number;
    bandwidthName: BandWidthName;
  };
  connections: Connection[];
}
const fetchUserInfoApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    fetchUserInfo: build.query<UserInfo, null>({
      query: () => ({
        url: '/api/user/info',
      }),
      providesTags: ['UserInfo'],
    }),
  }),
  overrideExisting: false,
});

export const useFetchUserInfo = fetchUserInfoApi.useFetchUserInfoQuery;
export const useFetchUserInfoResult =
  fetchUserInfoApi.endpoints.fetchUserInfo.useQueryState;
