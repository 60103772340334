import { yupResolver } from '@hookform/resolvers/yup';
import { PromocodeValues, usePromocode } from 'entities/Profile';
import React from 'react';
import { useForm } from 'react-hook-form';
import { validationScheme } from '../lib';
import { Button, Flex, MaterialInput, Typography } from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared';

const PromoCodeForm: React.FC = () => {
  const { t } = useTranslation();

  const [activate, { isLoading }] = usePromocode();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PromocodeValues>({
    mode: 'onSubmit',
    defaultValues: { promocodeValue: '' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (values: PromocodeValues) => {
    try {
      await activate(values).unwrap();
      notify.success(t('promoCode.success'));
    } catch (error: any) {
      if (error?.status === 400) {
        notify.error(t('promoCode.error'));
      } else {
        notify.error(t('shared.error.base'));
      }
    } finally {
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" spacing={20}>
        <Flex direction="column" spacing={8}>
          <MaterialInput
            type="text"
            placeholder={t('promoCode.enter')}
            {...register('promocodeValue')}
          />
          {errors.promocodeValue && (
            <Typography.Text type="danger">
              {t(
                errors.promocodeValue?.message
                  ? errors.promocodeValue?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </Flex>
        <Button loading={isLoading} type="primary">
          {t('promoCode.activateButton')}
        </Button>
      </Flex>
    </form>
  );
};

export { PromoCodeForm };
