export const INSTALLATIONS = ['config', 'manual', 'app'] as const;

export const INSTALLATION_DATAS: Record<
  (typeof INSTALLATIONS)[number],
  {
    label: string;
    time: string;
    description: string;
    benefits?: string;
    recommended?: string;
    freeDescription?: string;
  }
> = {
  app: {
    label: 'installation.app.label',
    time: 'installation.app.time',
    description: 'installation.app.description',
    benefits: 'installation.app.benefits',
    recommended: 'installation.app.recommended',
    freeDescription: 'installation.app.freeDescription',
  },
  config: {
    label: 'installation.config.label',
    time: 'installation.config.time',
    description: 'installation.config.description',
  },
  manual: {
    label: 'installation.manual.label',
    time: 'installation.manual.time',
    description: 'installation.manual.description',
  },
} as const;
