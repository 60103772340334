import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Profile: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8799 22.2012C5.89648 22.2012 0.922852 17.2275 0.922852 11.2441C0.922852 5.25 5.88574 0.287109 11.8691 0.287109C17.8633 0.287109 22.8369 5.25 22.8369 11.2441C22.8369 17.2275 17.874 22.2012 11.8799 22.2012ZM11.8799 14.8965C15.1133 14.8965 17.6055 16.0566 18.6904 17.3457C20.1406 15.7344 21.0107 13.5967 21.0107 11.2441C21.0107 6.17383 16.9395 2.11328 11.8691 2.11328C6.79883 2.11328 2.75977 6.17383 2.75977 11.2441C2.75977 13.5967 3.62988 15.7236 5.06934 17.3457C6.16504 16.0566 8.64648 14.8965 11.8799 14.8965ZM11.8799 13.0703C9.81738 13.0488 8.20605 11.3301 8.19531 9.02051C8.18457 6.85059 9.81738 5.05664 11.8799 5.05664C13.9424 5.05664 15.5645 6.85059 15.5645 9.02051C15.5645 11.3301 13.9531 13.0918 11.8799 13.0703Z"
        fill="white"
      />
    </svg>
  );
};

export { Profile };
