const CONTROL_IDS = {
  AVAILABLE_PLANS: 'availablePlans',
  PROMOCODE_MODAL: 'promoCodeModal',
  GUIDE: 'GUIDE',
  PREMIUM_EXPIRATION_BANNER: 'PREMIUM_EXPIRATION_BANNER',
  PREMIUM_EXPIRED_BANNER: 'PREMIUM_EXPIRED_BANNER',
  PREMIUM_EXPIRED_BANNER_SEVEN_DAYS: 'PREMIUM_EXPIRED_BANNER_SEVEN_DAYS',
  PREMIUM_EXPIRATION_BANNER_9: 'PREMIUM_EXPIRATION_BANNER_9',
  PREMIUM_EXPIRATION_BANNER_1: 'PREMIUM_EXPIRATION_BANNER_1',
  SPEED_LIMIT_BANNER: 'SPEED_LIMIT_BANNER',
  HOW_IT_WORKS_MODAL: 'HOW_IT_WORKS_MODAL',
};

export { CONTROL_IDS };
