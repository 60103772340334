import { $rtkApi } from 'shared/api/rtkApi';
export interface PromocodeValues {
    promocodeValue: string;
}

const setPromocodeApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        promocode: build.mutation<object, PromocodeValues>({
            query: (params) => ({
                url: '/api/promocode',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});

export const usePromocode = setPromocodeApi.usePromocodeMutation;
