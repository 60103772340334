import { ITariff } from 'shared/api';
import { getTariffType } from './getTariffType';
import { TFunction } from 'i18next';
import { convertToMonths } from './convertToMonths';

export const getTariffName = (tariff: ITariff): Parameters<TFunction>[] => {
  const tariffType = getTariffType(tariff);

  if (tariffType === 'free') {
    return [['tariff.name.free']];
  }

  if (tariffType === 'free-premium') {
    return [['tariff.name.freePremium']];
  }

  if (tariffType === 'premium') {
    return [
      ['tariff.name.premium'],
      [
        'shared.plural.months',
        { count: convertToMonths(tariff.periodValue, tariff.periodName) },
      ],
    ];
  }

  return [['tariff.name.free']];
};
