import { GuideStep } from 'entities/Guide';
import { Language } from 'entities/Language';
import React from 'react';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { useTranslation, Trans } from 'react-i18next';
import { Copy, Flex, Typography } from 'shared/_ui';
import { InstructionProps } from '../instruction.props';

const AndroidConfigInstruction: React.FC<InstructionProps> = ({
  connection,
}) => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'android',
    'config',
    i18n.language as Language
  );

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.android.config.step.1.title')}
        description={
          <ul>
            <li>
              <Trans i18nKey="guide.android.config.step.1.description.1">
                <a
                  href="https://play.google.com/store/apps/details?id=org.strongswan.android"
                  target="_blank"
                  rel="noreferrer"
                ></a>
                <a
                  href="https://drive.google.com/file/d/162fspHvI5kQR34ElfQwLFmmsyM1LdS2P/view"
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </Trans>
            </li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.android.config.step.2.title')}
        description={
          <ul>
            <li>{t('guide.android.config.step.2.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.android.config.step.3.title')}
        description={
          <ul>
            <li>{t('guide.android.config.step.3.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={4}
        image={getImageByStep(4)}
        title={t('guide.android.config.step.4.title')}
        description={
          <ul>
            <li>{t('guide.android.config.step.4.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(5)}
        title={t('guide.android.config.step.5.title')}
        description={
          <ol>
            <li>{t('guide.android.config.step.5.description.1')}</li>
            <li>{t('guide.android.config.step.5.description.2')}</li>
            <li>{t('guide.android.config.step.5.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={6}
        image={getImageByStep(6)}
        title={t('guide.android.config.step.6.title')}
        description={
          <ol>
            <li>{t('guide.android.config.step.6.description.1')}</li>
            <li>{t('guide.android.config.step.6.description.2')}</li>
            <li>{t('guide.android.config.step.6.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={7}
        image={getImageByStep(7)}
        title={t('guide.android.config.step.7.title')}
        description={
          <ul>
            <li>{t('guide.android.config.step.7.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={8}
        image={getImageByStep(8)}
        title={t('guide.android.config.step.8.title')}
        description={
          <ol>
            <li>{t('guide.android.config.step.8.description.1')}</li>
            <li>{t('guide.android.config.step.8.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={9}
        image={getImageByStep(9)}
        title={t('guide.android.config.step.9.title')}
        description={
          <Flex direction="column" spacing={24}>
            <ul>
              <li>{t('guide.android.config.step.9.description.1')}</li>
            </ul>
            <Flex
              direction="column"
              spacing={8}
              style={{ width: 'fit-content' }}
            >
              <Typography.Text type="secondary">
                {t('connection.android.title.password')}
              </Typography.Text>
              <Copy variant="filled" text={connection.password} />
            </Flex>
          </Flex>
        }
        final={false}
      />
      <GuideStep
        number={10}
        image={getImageByStep(10)}
        title={t('guide.android.config.step.10.title')}
        description={
          <ol>
            <li>{t('guide.android.config.step.10.description.1')}</li>
            <li>{t('guide.android.config.step.10.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={11}
        image={getImageByStep(11)}
        title={t('guide.android.config.step.11.title')}
        description={
          <ul>
            <li>{t('guide.android.config.step.11.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={12}
        image={getImageByStep(12)}
        title={t('guide.android.config.step.12.title')}
        final={false}
      />
      <GuideStep
        number={13}
        image={getImageByStep(13)}
        title={t('guide.android.config.step.13.title')}
        final={true}
      />
      <GuideStep
        number={14}
        image={getImageByStep(14)}
        title={t('guide.android.config.step.14.title')}
        final={true}
      />
      <GuideStep
        number={15}
        image={getImageByStep(15)}
        title={t('guide.android.config.step.15.title')}
        description={
          <ul>
            <li>{t('guide.android.config.step.15.description.1')}</li>
          </ul>
        }
        final={true}
      />
    </Flex>
  );
};

export { AndroidConfigInstruction };
