import { $rtkApi } from './rtkApi';
import { Payment } from './createPayment';

const getPaymentStatus = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchPaymentStatusRefresh: build.mutation<Payment, string>({
            query: (args) => ({
                url: `/api/payment/${args}`,
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});

export const useGetPaymentTariffRefresh =
    getPaymentStatus.useFetchPaymentStatusRefreshMutation;
