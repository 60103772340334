import { $rtkApi } from 'shared/api/rtkApi';

const deleteConnectionApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        deleteConnection: build.mutation<null, number>({
            query: (id) => ({
                url: '/api/radius/user/delete',
                method: 'POST',
                body: { id },
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});

export const useDeleteConnection =
    deleteConnectionApi.useDeleteConnectionMutation;
