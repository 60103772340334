import React from 'react';
import { useLocalStorage } from 'usehooks-ts';

const LOCAL_STORAGE_VERSION_KEY = '__VERSION';
const CURRENT_VERSION = '1.0.0';

const useLocalStorageVersion = () => {
  const [version, setVersion] = useLocalStorage<string>(
    LOCAL_STORAGE_VERSION_KEY,
    CURRENT_VERSION
  );

  React.useEffect(() => {
    if (version !== CURRENT_VERSION) {
      localStorage.clear();
      setVersion(CURRENT_VERSION);
    }
  }, []);
};

export { useLocalStorageVersion };
