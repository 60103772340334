import { MutableRefObject, useEffect, useRef } from 'react';
import { useUserAuthData } from '../model/userSelectors';
import { useRefreshToken } from '../api/refreshToken';
import { useUserActions } from '../model/userSlice';
import { checkTokenLifetime } from './checkTokenLifetime';

type IntervalType = MutableRefObject<ReturnType<typeof setInterval>>;
const ONE_HOUR = 3600000;

export const useTokenLifetimeVerificationTimer = () => {
    const timerRef = useRef() as IntervalType;
    const [refreshToken] = useRefreshToken();
    const authData = useUserAuthData();
    const { setAuthData } = useUserActions();

    useEffect(() => {
        let isRefresh = false;
        if (authData) {
            timerRef.current = setInterval(() => {
                isRefresh = checkTokenLifetime(
                    authData.tokenLifetime,
                    authData.tokenExpirationDate
                );

                if (isRefresh) {
                    refreshToken(null)
                        .unwrap()
                        .then((res) => {
                            setAuthData(res);
                        });
                }
            }, ONE_HOUR);
        } else {
            clearInterval(timerRef.current);
        }
        return () => {
            clearInterval(timerRef.current);
        };
    }, [authData, refreshToken, setAuthData]);
};
