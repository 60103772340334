import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Success: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 58 58"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <circle cx="29" cy="29" r="28" stroke="#1FCE56" strokeWidth="2" />
      <path
        d="M38.0917 24.7782L26.7779 36.0919L18.9998 28.3137"
        stroke="#1FCE56"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Success };
