import React from 'react';
import styles from './styles.module.scss';
import { Button, Flex, Modal, SlowConnection, Typography } from 'shared/_ui';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { useStoreUiControls } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { useRefContext } from 'shared/context';

const SevenDaysExpiredWarning: React.FC = () => {
  const { t } = useTranslation();

  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  const { isOpen, close } = useStoreUiControls(
    CONTROL_IDS.PREMIUM_EXPIRED_BANNER_SEVEN_DAYS
  );

  const { open: openTariffs } = useStoreUiControls(CONTROL_IDS.AVAILABLE_PLANS);
  const { refs } = useRefContext();

  const scrollToTariffList = () => {
    const layoutRef = refs.current[REF_IDS.LAYOUT];
    const availablePlansRef = refs.current[REF_IDS.AVAILABLE_PLANS];

    if (layoutRef && availablePlansRef) {
      const scrollOffset = 32;

      const elementPosition =
        availablePlansRef.getBoundingClientRect().top + layoutRef.scrollTop;
      const offsetPosition = elementPosition - scrollOffset;

      layoutRef.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const onClick = () => {
    close();
    openTariffs();
    scrollToTariffList();
  };

  React.useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(() => {
        setIsMounted(false);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setIsMounted(true);
    }
  }, [isOpen]);

  if (!isMounted) return null;

  return (
    <Modal isOpen={true} onClose={close}>
      <Flex className={styles.container} direction="column" spacing={32}>
        <Flex direction="column" spacing={24} align="center">
          <SlowConnection className={styles.user} />
          <Typography.Title level={1}>
            {t('banner.speedLimit.title')}
          </Typography.Title>
          <Flex direction="column" spacing={8}>
            <Typography.Text size={3}>
              {t('banner.speedLimit.subtitle')}
            </Typography.Text>
            <Typography.Text type="secondary" size={3}>
              {t('banner.speedLimit.description')}
            </Typography.Text>
          </Flex>
        </Flex>
        <Button className={styles.button} onClick={onClick} type="primary">
          {t('banner.speedLimit.button')}
        </Button>
      </Flex>
    </Modal>
  );
};

export { SevenDaysExpiredWarning };
