import { AuthenticationContainer } from 'entities/Authentication';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Typography } from 'shared/_ui';
import { SuccessIcon } from './SuccessIcon';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

export const SuccessfulPaymentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHomeClickHandler = () => {
    navigate('/');
  };

  return (
    <AuthenticationContainer>
      <Flex className={styles.wrapper} direction="column" align="center">
        <SuccessIcon className={styles.icon} />
        <Typography.Title className={styles.heading} level={1}>
          {t('banner.successfulPayment.heading')}
        </Typography.Title>
        <Typography.Text size={3} className={styles.hint}>
          {t('banner.successfulPayment.hint')}
        </Typography.Text>
        <Button
          onClick={onHomeClickHandler}
          className={styles.button}
          type="primary"
        >
          {t('banner.successfulPayment.button')}
        </Button>
      </Flex>
    </AuthenticationContainer>
  );
};
