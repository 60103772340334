import { Device } from 'entities/Device';
import { Installation } from 'entities/Installation';
import { Language } from 'entities/Language';

import mac_ru_app from 'shared/assets/videos/guides/ru/mac/app.mp4';
import mac_en_app from 'shared/assets/videos/guides/en/mac/app.mp4';
import mac_ru_config from 'shared/assets/videos/guides/ru/mac/config.mp4';
import mac_en_config from 'shared/assets/videos/guides/en/mac/config.mp4';
import mac_ru_manual from 'shared/assets/videos/guides/ru/mac/manual.mp4';
import mac_en_manual from 'shared/assets/videos/guides/en/mac/manual.mp4';
import windows_ru_app from 'shared/assets/videos/guides/ru/windows/app.mp4';
import windows_en_app from 'shared/assets/videos/guides/en/windows/app.mp4';
import windows_ru_config from 'shared/assets/videos/guides/ru/windows/config.mp4';
import windows_en_config from 'shared/assets/videos/guides/en/windows/config.mp4';
import windows_ru_manual from 'shared/assets/videos/guides/ru/windows/manual.mp4';
import windows_en_manual from 'shared/assets/videos/guides/en/windows/manual.mp4';
import iphone_ru_app from 'shared/assets/videos/guides/ru/iphone/app.mp4';
import iphone_en_app from 'shared/assets/videos/guides/en/iphone/app.mp4';
import iphone_ru_config from 'shared/assets/videos/guides/ru/iphone/config.mp4';
import iphone_en_config from 'shared/assets/videos/guides/en/iphone/config.mp4';
import iphone_ru_manual from 'shared/assets/videos/guides/ru/iphone/manual.mp4';
import iphone_en_manual from 'shared/assets/videos/guides/en/iphone/manual.mp4';
import android_ru_app from 'shared/assets/videos/guides/ru/android/app.mp4';
import android_en_app from 'shared/assets/videos/guides/en/android/app.mp4';
import android_ru_config from 'shared/assets/videos/guides/ru/android/config.mp4';
import android_en_config from 'shared/assets/videos/guides/en/android/config.mp4';
import android_ru_manual from 'shared/assets/videos/guides/ru/android/manual.mp4';
import android_en_manual from 'shared/assets/videos/guides/en/android/manual.mp4';

const videos: Record<Language, Record<Device, Record<Installation, string>>> = {
  ru: {
    mac: {
      app: mac_ru_app,
      config: mac_ru_config,
      manual: mac_ru_manual,
    },
    windows: {
      app: windows_ru_app,
      config: windows_ru_config,
      manual: windows_ru_manual,
    },
    iphone: {
      app: iphone_ru_app,
      config: iphone_ru_config,
      manual: iphone_ru_manual,
    },
    android: {
      app: android_ru_app,
      config: android_ru_config,
      manual: android_ru_manual,
    },
  },
  en: {
    mac: {
      app: mac_en_app,
      config: mac_en_config,
      manual: mac_en_manual,
    },
    windows: {
      app: windows_en_app,
      config: windows_en_config,
      manual: windows_en_manual,
    },
    iphone: {
      app: iphone_en_app,
      config: iphone_en_config,
      manual: iphone_en_manual,
    },
    android: {
      app: android_en_app,
      config: android_en_config,
      manual: android_en_manual,
    },
  },
};

export const getVideoByParams = (
  device: Device,
  type: Installation,
  language: Language
) => {
  return videos[language][device][type];
};
