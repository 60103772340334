import React from 'react';
import styles from './styles.module.scss';
import { Clock, Flex, Popover, Radio, Typography } from 'shared/_ui';
import { INSTALLATION_DATAS } from '../../const';
import { Installation } from '../../types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from 'shared';

type InstallationTypeSelectorProps = {
  value: Installation;
  tariff: string;
  hidePopups?: boolean;
  onChange: (value: Installation) => void;
};

const InstallationTypeSelector: React.FC<InstallationTypeSelectorProps> = ({
  onChange,
  hidePopups,
  tariff,
  value,
}) => {
  return (
    <Flex className={styles.group} direction="column" spacing={15}>
      {Object.entries(INSTALLATION_DATAS).map(([type, data], index) => {
        return (
          <InstallationCheckbox
            hidePopups={hidePopups}
            align={
              index === 0
                ? 'end'
                : index === Object.entries(INSTALLATION_DATAS).length - 1
                ? 'start'
                : undefined
            }
            tariff={tariff}
            key={type}
            {...data}
            value={type as Installation}
            checked={type === value}
            onChange={onChange}
          />
        );
      })}
    </Flex>
  );
};

type InstallationCheckboxProps = {
  label: string;
  time: string;
  description: string;
  benefits?: string;
  recommended?: string;
  value: Installation;
  checked: boolean;
  onChange: (value: Installation) => void;
  align?: 'start' | 'end';
  tariff: string;
  freeDescription?: string;
  hidePopups?: boolean;
};

const InstallationCheckbox: React.FC<InstallationCheckboxProps> = ({
  description,
  label,
  time,
  value,
  onChange,
  checked,
  benefits,
  tariff,
  freeDescription,
  recommended,
  hidePopups,
  align,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  return (
    <Popover
      align={align}
      isOpen={checked && !hidePopups ? checked : undefined}
      offset={isMobile ? 5 : 30}
      position={isMobile ? ['top', 'bottom'] : 'right'}
      reposition={false}
      mode={isMobile ? undefined : 'hover'}
      content={
        <Typography.Text className={styles.description} type="tertiary">
          {tariff === 'free' && freeDescription
            ? t(freeDescription)
            : t(description)}
          {!freeDescription && benefits && (
            <span className={styles.benefits}>
              {t(benefits)
                .split('*')
                .map((benefit) => (
                  <li>{benefit}</li>
                ))}
            </span>
          )}
        </Typography.Text>
      }
    >
      <Radio
        name="installation"
        value={value}
        onChange={onChange}
        checked={checked}
        className={classNames(styles.item, {
          [styles['item-active']]: checked,
        })}
      >
        <Flex spacing={12} direction="column">
          {recommended && isMobile && (
            <div className={styles.recommended}>{t(recommended)}</div>
          )}
          <Typography.Text size={isMobile ? 3 : 5} strong>
            {t(label)}
          </Typography.Text>
          <Flex justify="space-between">
            <Flex spacing={5} align="center">
              <Clock className={styles.clock} />
              <Typography.Text type="secondary" size={isMobile ? 1 : 2}>
                {t(time)}
              </Typography.Text>
            </Flex>
            {recommended && !isMobile && (
              <div className={styles.recommended}>{t(recommended)}</div>
            )}
          </Flex>
        </Flex>
      </Radio>
    </Popover>
  );
};

export { InstallationTypeSelector };
