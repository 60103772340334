import { $rtkApi } from './rtkApi';

export type PaymentStatus = 'IN_PROGRESS' | 'SUCCESS' | 'FAIL';

export interface CreatePayBody {
    tariffId: number;
    providerId?: number | null;
}
interface Amounts {
    electronic: number;
}
interface ItemCloudPaymentsReceipt {
    amount: number;
    label: string;
    packageCode: string;
    price: number;
    quantity: string;
    spic: string;
    vat: string;
}
interface CloudPayments {
    AdditionalReceiptInfos: Array<string>;
    amounts: Amounts;
    Items: Array<ItemCloudPaymentsReceipt>;
}
interface Receipt {
    CloudPayments: CloudPayments;
}

export interface Payment {
    amount: number;
    currency: string;
    paymentId: string;
    paymentStatus: PaymentStatus;
    paymentUrl: string;
    widgetType: string;
    widgetUrl?: string;
    token?: string;
    receiptData?: Receipt;
}

const createPaymentApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        createPayment: build.mutation<Payment, CreatePayBody>({
            query: (arg: CreatePayBody) => {
                return {
                    url: '/api/pay',
                    method: 'POST',
                    body: {
                        tariffId: arg.tariffId,
                        providerId: arg.providerId,
                    },
                };
            },
        }),
        createPaymentRefresh: build.mutation<Payment, CreatePayBody>({
            query: (arg: CreatePayBody) => ({
                url: '/api/pay',
                method: 'POST',
                body: { tariffId: arg.tariffId, providerId: arg.providerId },
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});

export const useCreatePayment = createPaymentApi.useCreatePaymentMutation;

export const useCreatePaymentRefresh =
    createPaymentApi.useCreatePaymentRefreshMutation;
