import React from 'react';
import classNames from 'classnames';
import { Arrow } from '../Icon';
import styles from './styles.module.scss';

type CollapseProps = {
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  children: React.ReactNode;
  header: React.ReactNode;
  className?: string;
  arrowClassName?: string;
};

const Collapse = React.forwardRef<HTMLDivElement, CollapseProps>(
  (
    {
      header,
      isOpen: externalIsOpen,
      children,
      onToggle,
      className,
      arrowClassName,
    },
    ref
  ) => {
    const [height, setHeight] = React.useState<number>(0);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const [internalIsOpen, setInternalIsOpen] = React.useState(false);

    const isControlled = externalIsOpen !== undefined;

    const isOpen = isControlled ? externalIsOpen : internalIsOpen;

    const adjustHeightIfOpen = () => {
      if (contentRef.current) {
        setHeight(isOpen ? contentRef.current.scrollHeight : 0);
      }
    };

    const toggle = () => {
      const newState = !isOpen;
      if (isControlled && onToggle) {
        onToggle(newState);
      } else {
        setInternalIsOpen(newState);
      }
    };

    React.useEffect(() => {
      if (isControlled) {
        setInternalIsOpen(externalIsOpen!);
      }
    }, [externalIsOpen, isControlled]);

    React.useEffect(() => {
      adjustHeightIfOpen();
    }, [isOpen, children]);

    React.useEffect(() => {
      window.addEventListener('resize', adjustHeightIfOpen);
      return () => window.removeEventListener('resize', adjustHeightIfOpen);
    }, [isOpen, children]);

    const classNamePrefix = 'collapse';
    const _className = classNames(className, styles[classNamePrefix], {
      [styles[`${classNamePrefix}-open`]]: isOpen,
      [styles[`${classNamePrefix}-closed`]]: !isOpen,
    });

    return (
      <div ref={ref} className={_className}>
        <button className={styles.header} onClick={toggle}>
          <div className={styles.text}>{header}</div>
          <div className={classNames(arrowClassName, styles.arrow)}>
            <Arrow className={styles.icon} />
          </div>
        </button>
        <div
          className={styles.content}
          style={{
            height,
          }}
          ref={contentRef}
        >
          {children}
        </div>
      </div>
    );
  }
);

export { Collapse };
