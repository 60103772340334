import React from 'react';
import {
  Button,
  Delete,
  Dropdown,
  Ellipses,
  Flex,
  Popconfirm,
  Typography,
  DropdownHandles,
} from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import { Connection, useDeleteXrayConnection } from 'entities/Connection';
import { notify } from 'shared';
import styles from './styles.module.scss';
import { useConnectionDevices } from 'entities/Device';

type ConnectionActionsProps = {
  id: Connection['id'];
};

const XrayConnectionActions: React.FC<ConnectionActionsProps> = ({ id }) => {
  const { t } = useTranslation();

  const dropdownRef = React.useRef<DropdownHandles>(null);

  const [deleteConnection, { isLoading }] = useDeleteXrayConnection();

  const { deleteDevice } = useConnectionDevices();

  const onDelete = async () => {
    try {
      await deleteConnection(id).unwrap();
      deleteDevice(id);
      notify.success(t('connection.notify.successDeleted'));
      closeDropdown();
    } catch (error) {
      notify.error(t('shared.error.base'));
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    dropdownRef.current?.close();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      position="bottom-right"
      contentClassName={styles.content}
      content={
        <Flex spacing={15} direction="column">
          <Popconfirm
            title={t('connection.confirm.delete')}
            onConfirm={onDelete}
          >
            <Button
              loading={isLoading}
              className={styles.action}
              type="text"
              prefix={<Delete className={styles.delete} />}
            >
              <Typography.Text size={2}>
                {t('connection.delete')}
              </Typography.Text>
            </Button>
          </Popconfirm>
        </Flex>
      }
    >
      <Button
        className={styles.control}
        type="text"
        icon={<Ellipses className={styles.ellipses} />}
      >
        {t('connection.actions')}
      </Button>
    </Dropdown>
  );
};

export { XrayConnectionActions };
