import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ResetForm } from 'features/ResetPassword';
import { useUserAuthData } from 'entities/User';
import { getRouteMain } from 'shared/consts/routes';
import { AuthenticationContainer } from 'entities/Authentication';
import { useTranslation } from 'react-i18next';

export const ResetPage: React.FC = () => {
  const { t } = useTranslation();
  const authData = useUserAuthData();
  const navigate = useNavigate();

  // Это должно решаться на уровне routes
  React.useEffect(() => {
    if (authData) navigate(getRouteMain());
  }, [authData, navigate]);

  return (
    <AuthenticationContainer title={t('authentication.password.reset')}>
      <ResetForm />
    </AuthenticationContainer>
  );
};
