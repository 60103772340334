import React from 'react';
import styles from './styles.module.scss';
import { GuideSlide } from 'entities/Guide';
import { useSliderContext } from '../../model';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Arrow, Button, Flex, Typography } from 'shared/_ui';
import { CountrySelectDropdown } from './CountrySelectDropdown';

type Props = {
  className?: string;
};

export const CountrySelectSlide = ({ className }: Props) => {
  const { next, prev, chosenCountry, device } = useSliderContext();

  const { t } = useTranslation();

  return (
    <GuideSlide
      className={classNames(
        className,
        styles.slide,
        styles[`slide-${device}`],
        styles[`slide-${chosenCountry?.toLowerCase()}-${device}`]
      )}
      head={
        <Flex direction="column" spacing={16}>
          <Flex spacing={20}>
            <Flex className={styles.number} align="center" justify="center">
              <Typography.Text size={6}>2</Typography.Text>
            </Flex>
            <Typography.Title level={2}>
              {t('country.selectCountry')}
            </Typography.Title>
          </Flex>
          <Typography.Text
            className={styles.description}
            type="secondary"
            size={4}
          >
            {t('country.selectCountryDescription')}
          </Typography.Text>
        </Flex>
      }
      body={
        <div>
          <CountrySelectDropdown />
        </div>
      }
      controls={
        <Flex justify="space-between" spacing={10}>
          <Button
            onClick={prev}
            className={styles.back}
            type="secondary"
            prefix={<Arrow className={styles.arrow} />}
          >
            {t('shared.text.back')}
          </Button>
          <Button
            onClick={next}
            className={styles.next}
            type="primary"
            suffix={<Arrow className={styles.arrow} />}
          >
            {t('shared.text.continue')}
          </Button>
        </Flex>
      }
    />
  );
};
