import React from 'react';
import { usePrevious } from '@uidotdev/usehooks';
import { useFetchUserInfo } from 'entities/Connection';
import { useUserData } from 'entities/User';
import { getTariffType } from 'entities/Tariff';

export const useUserPlan = () => {
  const { setUserData } = useUserData();

  const { data: userInfo } = useFetchUserInfo(null);

  const prevPlan = usePrevious(userInfo);

  React.useEffect(() => {
    if (!userInfo?.tariff || !prevPlan?.tariff) return;

    if (prevPlan.tariff !== userInfo.tariff) {
      setUserData((prev) => ({
        ...prev,
        prevPlan: getTariffType(userInfo.tariff),
        lastPlanChangeDate: prevPlan.paidTo,
      }));
    }
  }, [userInfo, prevPlan]);
};
