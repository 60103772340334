import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type ContentProps = {
  children: React.ReactNode;
  className?: string;
};

const Content: React.FC<ContentProps> = ({ children, className }) => {
  const classNamePrefix = 'content';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <div className={_className}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export { Content };
