import { useLocalStorage } from 'usehooks-ts';
import { TariffType } from 'entities/Tariff';

export type UserData = {
  isNew: boolean;
  prevPlan: TariffType | null;
  lastPlanChangeDate: string | null;
};

const USER_DATA_KEY = 'USER_DATA';

export const useUserData = () => {
  const [userData, setUserData] = useLocalStorage<UserData>(USER_DATA_KEY, {
    isNew: true,
    prevPlan: null,
    lastPlanChangeDate: null,
  });

  return { userData, setUserData };
};
