import { $rtkApi } from 'shared/api/rtkApi';

export interface Server {
    server: string;
}
const CACHE_TIMER = 3600;


const fetchAccessServersApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAccessServers: build.query<Server[], null>({
            query: () => ({
                url: '/api/dictionary/as',
            }),
            keepUnusedDataFor: CACHE_TIMER,
        }),
    }),
    overrideExisting: false,
});

export const useFetchAccessServers =
    fetchAccessServersApi.useFetchAccessServersQuery;
