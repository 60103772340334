import { GuideStep } from 'entities/Guide';
import { Language } from 'entities/Language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'shared/_ui';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { InstructionProps } from '../instruction.props';

const IphoneConfigInstruction: React.FC<InstructionProps> = () => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'iphone',
    'config',
    i18n.language as Language
  );

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.iphone.config.step.1.title')}
        description={
          <ul>
            <li>{t('guide.iphone.config.step.1.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.iphone.config.step.2.title')}
        description={
          <ul>
            <li>{t('guide.iphone.config.step.2.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.iphone.config.step.3.title')}
        description={
          <ul>
            <li>{t('guide.iphone.config.step.3.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={4}
        image={getImageByStep(4)}
        title={t('guide.iphone.config.step.4.title')}
        description={
          <ul>
            <li>{t('guide.iphone.config.step.4.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(5)}
        title={t('guide.iphone.config.step.5.title')}
        description={
          <ol>
            <li>{t('guide.iphone.config.step.5.description.1')}</li>
            <li>{t('guide.iphone.config.step.5.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={6}
        image={getImageByStep(6)}
        title={t('guide.iphone.config.step.6.title')}
        description={
          <ol>
            <li>{t('guide.iphone.config.step.6.description.1')}</li>
            <li>{t('guide.iphone.config.step.6.description.2')}</li>
            <li>{t('guide.iphone.config.step.6.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={7}
        image={getImageByStep(7)}
        title={t('guide.iphone.config.step.7.title')}
        description={
          <ol>
            <li>{t('guide.iphone.config.step.7.description.1')}</li>
            <li>{t('guide.iphone.config.step.7.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={8}
        image={getImageByStep(8)}
        title={t('guide.iphone.config.step.8.title')}
        description={
          <ul>
            <li>{t('guide.iphone.config.step.8.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={9}
        image={getImageByStep(9)}
        title={t('guide.iphone.config.step.9.title')}
        description={
          <ol>
            <li>{t('guide.iphone.config.step.9.description.1')}</li>
            <li>{t('guide.iphone.config.step.9.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={10}
        image={getImageByStep(10)}
        title={t('guide.iphone.config.step.10.title')}
        final={false}
      />
      <GuideStep
        number={11}
        image={getImageByStep(11)}
        title={t('guide.iphone.config.step.11.title')}
        final={false}
      />
      <GuideStep
        number={12}
        image={getImageByStep(12)}
        title={t('guide.iphone.config.step.12.title')}
        description={
          <ol>
            <li>{t('guide.iphone.config.step.12.description.1')}</li>
            <li>{t('guide.iphone.config.step.12.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={13}
        image={getImageByStep(13)}
        title={t('guide.iphone.config.step.13.title')}
        description={
          <ol>
            <li>{t('guide.iphone.config.step.13.description.1')}</li>
            <li>{t('guide.iphone.config.step.13.description.2')}</li>
            <li>{t('guide.iphone.config.step.13.description.3')}</li>
          </ol>
        }
        final={true}
      />
    </Flex>
  );
};

export { IphoneConfigInstruction };
