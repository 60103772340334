import { PAYMENT_PROVIDER_IDS } from '../const';

const providerNames: Record<number, string> = {
  // TODO: uncomment once payment service is ready
  // [PAYMENT_PROVIDER_IDS.RF]: 'provider.name.rf',
  [PAYMENT_PROVIDER_IDS.CRYPTO]: 'provider.name.crypto',
  [PAYMENT_PROVIDER_IDS.PAYZE]: 'provider.name.payze',
  [PAYMENT_PROVIDER_IDS.RU_CLOUDPAYMENTS]: 'provider.name.ruCloudPayments',
};

const getProviderName = (id: number): string => {
  return providerNames[id];
};

export { getProviderName };
