import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export type TextType =
  | 'default'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger';
type TextTransform = 'capitalize' | 'uppercase' | 'lowercase';

export type TextProps = {
  children: React.ReactNode;
  type?: TextType;
  size?: number;
  strong?: boolean;
  transform?: TextTransform;
  className?: string;
};

const Text: React.FC<TextProps> = ({
  children,
  type = 'default',
  strong,
  size,
  transform,
  className,
}) => {
  const classNamePrefix = 'text';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-${type}`],
    styles[`${classNamePrefix}-size-${size}`],
    styles[`${classNamePrefix}-transform-${transform}`],

    {
      [styles[`${classNamePrefix}-strong`]]: strong,
    }
  );

  return <p className={_className}>{children}</p>;
};

export { Text };
