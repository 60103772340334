import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Image, Tag, Typography } from 'shared/_ui';
import styles from './styles.module.scss';
import classNames from 'classnames';

type ImageSizeOptions = 'default' | 'large' | 'extra-large';

type GuideStepProps = {
  number: number;
  title: string;
  description?: React.ReactNode;
  image: string | string[];
  final: boolean;
  className?: string;
  imageSize?: ImageSizeOptions | ImageSizeOptions[];
};

const GuideStep: React.FC<GuideStepProps> = ({
  number,
  title,
  description,
  image,
  final,
  className,
  imageSize = 'default',
}) => {
  const { t } = useTranslation();

  const classNamePrefix = 'step';

  return (
    <Flex
      spacing={16}
      justify="space-between"
      wrap
      className={classNames(className, styles[classNamePrefix])}
    >
      <div className={styles.left}>
        <div className={styles.number}>
          <Tag color="gray" className={styles['number-tag']}>{`${t(
            'instruction.step'
          )} ${number}`}</Tag>
          {final && <Tag color="green">{t('instruction.final')}</Tag>}
        </div>
        <div className={styles.title}>
          <Typography.Text className={styles.text} size={4}>
            {title}
          </Typography.Text>
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      {Array.isArray(image) ? (
        <div className={styles[`images-wrapper`]}>
          {image.map((image, imageIndex) => (
            <Image
              key={imageIndex}
              className={classNames(
                styles.image,
                Array.isArray(imageSize)
                  ? styles[`image-size-${imageSize[imageIndex]}`]
                  : styles[`image-size-${imageSize}`]
              )}
              alt={t('image')}
              src={image}
            />
          ))}
        </div>
      ) : (
        <Image
          className={classNames(
            styles.image,
            styles[`image-size-${imageSize}`]
          )}
          alt={t('image')}
          src={image}
        />
      )}
    </Flex>
  );
};

export { GuideStep };
