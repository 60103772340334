import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type RadioProps<T> = {
  className?: string;
  value: T;
  name: string;
  checked?: boolean;
  onChange?: (value: T) => void;
  children?: React.ReactNode;
  disabled?: boolean;
};

const Radio = <T extends string>({
  className,
  value,
  name,
  checked = false,
  onChange,
  children,
  disabled = false,
}: RadioProps<T>) => {
  const classNamePrefix = 'radio';
  const _className = classNames(className, styles[classNamePrefix], {
    [styles[`${classNamePrefix}-disabled`]]: disabled,
  });

  return (
    <label className={_className}>
      <input
        className={styles.input}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange?.(value)}
      />
      <div className={styles.text}>{children ? children : value}</div>
    </label>
  );
};

export type RadioGroupOption<T extends string> = {
  label: React.ReactNode;
  value: T;
  disabled?: boolean;
};

type RadioGroupProps<T extends string> = {
  className?: string;
  itemClassName?: ((active: boolean) => string) | string;
  options: T[] | RadioGroupOption<T>[];
  name: string;
  defaultValue?: T;
  value?: T;
  onChange?: (value: T) => void;
};

const RadioGroup = <T extends string>({
  className,
  itemClassName,
  options,
  name,
  defaultValue,
  value: externalValue,
  onChange,
}: RadioGroupProps<T>) => {
  const classNamePrefix = 'group';
  const _className = classNames(className, styles[classNamePrefix]);

  const [value, setValue] = React.useState<T | undefined>(defaultValue);

  const handleChange = (newValue: T) => {
    setValue(newValue);

    if (externalValue === undefined) {
      setValue(newValue);
    }
    onChange?.(newValue);
  };

  React.useEffect(() => {
    if (externalValue !== undefined) {
      setValue(externalValue);
    }
  }, [externalValue]);

  return (
    <div className={_className}>
      {options.map((option) => {
        if (typeof option === 'string') {
          return (
            <Radio
              key={option}
              className={
                typeof itemClassName === 'string'
                  ? itemClassName
                  : itemClassName?.(value === option)
              }
              value={option}
              name={name}
              checked={value === option}
              onChange={handleChange}
            />
          );
        } else {
          return (
            <Radio
              key={option.value}
              className={
                typeof itemClassName === 'string'
                  ? itemClassName
                  : itemClassName?.(value === option.value)
              }
              value={option.value}
              name={name}
              checked={value === option.value}
              onChange={handleChange}
              disabled={option.disabled}
            >
              {option.label}
            </Radio>
          );
        }
      })}
    </div>
  );
};

Radio.Group = RadioGroup;

export { Radio };
