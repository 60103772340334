import { CountryCode } from '../types';

const countryCodes: Record<string, CountryCode> = {
  россия: 'RU',
  russia: 'RU',
  казахстан: 'KZ',
  kazakhstan: 'KZ',
  германия: 'DE',
  germany: 'DE',
  сша: 'US',
  usa: 'US',
};

export const getCountryCode = (
  countryName: string
): CountryCode | undefined => {
  const normalizedCountryName = countryName.trim().toLowerCase();
  return countryCodes[normalizedCountryName];
};
