import React from 'react';
import styles from './styles.module.scss';
import { Flex } from '../Flex';
import { Loading } from '../Loading';
import { Error } from '../Icon';
import { Typography } from '../Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Empty } from '../Icon/Empty';

type QueryStatusBoundaryProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  refresh?: () => void;
  errorMessage?: string;
  emptyMessage?: string;
};

const QueryStatusBoundary: React.FC<QueryStatusBoundaryProps> = ({
  children,
  isLoading = false,
  isError = false,
  isEmpty = false,
  refresh,
  errorMessage,
  emptyMessage,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Flex justify="center">
        <Loading variant="double-spin" size="large" />
      </Flex>
    );
  }

  if (isError) {
    return (
      <Flex
        className={styles.container}
        direction="column"
        align="center"
        spacing={16}
      >
        <Error className={styles.error} />
        <Flex direction="column" align="center" spacing={32}>
          <Flex direction="column" align="center" spacing={8}>
            <Typography.Title level={4}>
              {t('shared.error.data')}
            </Typography.Title>
            <Typography.Text size={3} type="secondary">
              {errorMessage ?? t('shared.text.checkConnectionAndTryAgain')}
            </Typography.Text>
          </Flex>
          {refresh && (
            <Button onClick={refresh} className={styles.button} type="default">
              {t('shared.text.repeat')}
            </Button>
          )}
        </Flex>
      </Flex>
    );
  }

  if (isEmpty) {
    return (
      <Flex direction="column" align="center" spacing={16}>
        <Empty className={styles.empty} />
        <Typography.Title level={4}>
          {emptyMessage ?? t('shared.text.noData')}
        </Typography.Title>
      </Flex>
    );
  }

  return <>{children}</>;
};

export { QueryStatusBoundary };
