import { $rtkApi } from './rtkApi';

export type BandWidthName = 'BYTES' | 'KBYTES' | 'MBYTES';
export type PeriodName = 'DAY' | 'MONTH' | 'YEAR';
export type Currency = 'USD' | 'RUB';

export interface ITariff {
  id: number;
  price: number;
  currency: Currency;
  periodValue: number;
  periodName: PeriodName;
  bandwidthAmount: number;
  bandwidthName: BandWidthName;
  numberOfAccounts: number;
}

const CACHE_TIMER = 3600;

const fetchTariffsApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTariffs: build.query<ITariff[], null>({
      query: () => ({
        url: '/api/dictionary/tariffs',
      }),
      keepUnusedDataFor: CACHE_TIMER,
    }),
  }),
  overrideExisting: false,
});

export const useFetchTariffs = fetchTariffsApi.useFetchTariffsQuery;
