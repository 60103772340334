export const calculateMonthlyPayment = (
  months: number,
  totalAmount: number
): number => {
  if (months <= 0) {
    return 0;
  }

  return Math.floor((totalAmount / months) * 100) / 100;
};
