import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCreateReset } from 'features/RestoreAuth';
import { useUserAuthData } from 'entities/User';
import { getRouteLogin, getRouteMain } from 'shared/consts/routes';

import styles from './styles.module.scss';
import { AuthenticationContainer } from 'entities/Authentication';
import { Button, Envelope, Flex, Typography } from 'shared/_ui';
import { notify } from 'shared';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

const RESTORE_EMAIL = 'restore-email';

export const SuccessRestorePage: React.FC = () => {
  const [email] = useLocalStorage<string | null>(RESTORE_EMAIL, null);
  const { t } = useTranslation();
  const [create] = useCreateReset();
  const authData = useUserAuthData();
  const navigate = useNavigate();

  // Это должно решаться на уровне routes
  React.useEffect(() => {
    if (authData) navigate(getRouteMain());
  }, [authData, navigate]);

  const sendAgain = () => {
    if (!email) return;

    create({ email })
      .unwrap()
      .then(() => {
        notify.success(t('auth.successRestore.success'));
      })
      .catch((error) => {
        if (error?.status === 400) {
          notify.error(t('auth.successRestore.error'));
        } else {
          notify.error(t('shared.error.base'));
        }
      });
  };

  return (
    <div>
      <AuthenticationContainer
        title={t('authentication.restore.instructionLinkSent')}
        footer={
          email && (
            <div className={styles.footer}>
              <Typography.Text type="secondary">
                {t('authentication.mail.didNotReceiveEmail')}
              </Typography.Text>
              <Button onClick={sendAgain} className={styles.send} type="link">
                {t('authentication.mail.sendAgain')}
              </Button>
            </div>
          )
        }
        hint={
          <div className={styles.hint}>
            <Typography.Text type="secondary">
              {t('authentication.registration.alreadyHaveAccount')}
            </Typography.Text>
            <Typography.Link type="primary">
              <Link to={getRouteLogin()}>
                {t('authentication.registration.login')}
              </Link>
            </Typography.Link>
          </div>
        }
      >
        <Flex direction="column" spacing={32} className={styles.wrapper}>
          <div className={styles.description}>
            <Typography.Text type="default">
              {t('authentication.restore.description')}
            </Typography.Text>
            <Typography.Text type="secondary">
              {t('authentication.mail.checkSpam')}
            </Typography.Text>
          </div>
          {email && (
            <Typography.Mark className={styles.mail}>
              <Envelope className={styles.envelope} />
              <Typography.Text strong type="default" size={2}>
                {email}
              </Typography.Text>
            </Typography.Mark>
          )}
        </Flex>
      </AuthenticationContainer>
    </div>
  );
};
