import { ResponseUser } from 'entities/User';
import { $rtkApi } from 'shared/api/rtkApi';

export interface LoginValues {
  login: string;
  password: string;
}

const loginApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ResponseUser, LoginValues>({
      query: (params) => {
        return {
          url: '/api/login',
          method: 'POST',
          body: params,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const useLogin = loginApi.useLoginMutation;
