import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Ellipses: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M14.3926 28.0742C6.77734 28.0742 0.447266 21.7441 0.447266 14.1289C0.447266 6.5 6.76367 0.183594 14.3789 0.183594C22.0078 0.183594 28.3379 6.5 28.3379 14.1289C28.3379 21.7441 22.0215 28.0742 14.3926 28.0742ZM7.81641 16.166C8.9375 16.166 9.85352 15.25 9.85352 14.1152C9.85352 12.9805 8.92383 12.0645 7.81641 12.0645C6.69531 12.0645 5.76562 12.9805 5.76562 14.1152C5.76562 15.25 6.69531 16.166 7.81641 16.166ZM14.3926 16.166C15.5273 16.166 16.4434 15.25 16.4434 14.1152C16.4434 12.9805 15.5273 12.0645 14.3926 12.0645C13.2578 12.0645 12.3418 12.9805 12.3418 14.1152C12.3418 15.25 13.2578 16.166 14.3926 16.166ZM20.9824 16.166C22.0898 16.166 23.0195 15.25 23.0195 14.1152C23.0195 12.9805 22.0898 12.0645 20.9824 12.0645C19.8477 12.0645 18.9316 12.9805 18.9316 14.1152C18.9316 15.25 19.8477 16.166 20.9824 16.166Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export { Ellipses };
