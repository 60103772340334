import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { Theme } from '../../types';

export const useTheme = () => {
  const [theme, setTheme] = useLocalStorage<Theme>('theme', () => {
    try {
      const theme = localStorage.getItem('theme');

      if (!theme) return 'dark';
      const themeString = JSON.parse(theme);

      return themeString;
    } catch (e) {
      localStorage.setItem('theme', JSON.stringify('dark'));
      return 'dark';
    }
  });

  React.useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return { theme, setTheme };
};
