import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type FooterProps = {
  children: React.ReactNode;
  className?: string;
};

const Footer: React.FC<FooterProps> = ({ children, className }) => {
  const classNamePrefix = 'footer';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <footer className={_className}>
      <div className={styles['footer-container']}>{children}</div>
    </footer>
  );
};

export { Footer };
