import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type LinkType = 'primary' | 'secondary';

type LinkProps = {
  children: React.ReactNode;
  type?: LinkType;
  className?: string;
  href?: string;
  target?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const Link: React.FC<LinkProps> = ({
  children,
  type = 'primary',
  className,
  href,
  target,
  onClick,
}) => {
  const classNamePrefix = 'link';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-${type}`]
  );

  return (
    <a onClick={onClick} href={href} className={_className} target={target}>
      {children}
    </a>
  );
};

export { Link };
