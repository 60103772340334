import React from 'react';

export const useModal = (initial = false) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initial);
  const [isMount, setIsMount] = React.useState<boolean>(initial);

  const openModal = React.useCallback(() => {
    setIsOpen(true);
    setIsMount(true);
  }, []);

  const closeModal = React.useCallback(
    () =>
      new Promise((resolve) => {
        setIsOpen(false);

        setTimeout(() => {
          setIsMount(false);
          resolve(null);
        }, 300);
      }),
    []
  );

  return { isMount, isOpen, openModal, closeModal, setIsOpen };
};
