import React from 'react';
import styles from './styles.module.scss';
import { Flex, Modal, Typography } from 'shared/_ui';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS } from 'shared/consts';
import { PromoCodeForm } from 'features/PromoCodeForm';
import { useTranslation } from 'react-i18next';

const PromoCodeModal: React.FC = () => {
  const { t } = useTranslation();

  const { isOpen, close } = useStoreUiControls(CONTROL_IDS.PROMOCODE_MODAL);

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Flex direction="column" className={styles.content} spacing={42}>
        <Typography.Title level={2}>
          {t('promoCode.yourPromoCode')}
        </Typography.Title>
        <PromoCodeForm />
      </Flex>
    </Modal>
  );
};

export { PromoCodeModal };
