import { GuideStep } from 'entities/Guide';
import { Language } from 'entities/Language';
import React from 'react';
import { Copy, Flex, Typography } from 'shared/_ui';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { useTranslation } from 'react-i18next';
import { InstructionProps } from '../instruction.props';

const WindowsConfigInstruction: React.FC<InstructionProps> = ({
  connection,
}) => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'windows',
    'config',
    i18n.language as Language
  );

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.windows.config.step.1.title')}
        description={
          <ul>
            <li>{t('guide.windows.config.step.1.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.windows.config.step.2.title')}
        description={
          <ul>
            <li>{t('guide.windows.config.step.2.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.windows.config.step.3.title')}
        description={
          <ol>
            <li>{t('guide.windows.config.step.3.description.1')}</li>
            <li>{t('guide.windows.config.step.3.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        imageSize="large"
        number={4}
        image={getImageByStep(4)}
        title={t('guide.windows.config.step.4.title')}
        description={
          <ul>
            <li>{t('guide.windows.config.step.4.description.1')}</li>
            <Flex
              direction="column"
              spacing={12}
              style={{
                width: 'fit-content',
                marginTop: 16,
                marginBottom: 32,
              }}
            >
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.provider')}
                </Typography.Text>
                <Copy
                  disabled
                  variant="filled"
                  text={t('connection.windows.value.provider')}
                />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.name')}
                </Typography.Text>
                <Copy variant="filled" text={connection.name} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.server')}
                </Typography.Text>
                <Copy variant="filled" text={connection.server} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.vpnType')}
                </Typography.Text>
                <Copy
                  disabled
                  variant="filled"
                  text={t('connection.windows.value.vpnType')}
                />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.dataType')}
                </Typography.Text>
                <Copy
                  disabled
                  variant="filled"
                  text={t('connection.windows.value.dataType')}
                />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.user')}
                </Typography.Text>
                <Copy variant="filled" text={connection.login} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.windows.title.password')}
                </Typography.Text>
                <Copy variant="filled" text={connection.password} />
              </Flex>
            </Flex>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(5)}
        title={t('guide.windows.config.step.5.title')}
        description={
          <ul>
            <li>{t('guide.windows.config.step.5.description.1')}</li>
          </ul>
        }
        final={true}
      />
    </Flex>
  );
};

export { WindowsConfigInstruction };
