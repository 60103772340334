import { $rtkApi } from 'shared/api/rtkApi';

const deleteConnectionApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    deleteXrayConnection: build.mutation({
      query: (id) => ({
        url: `/api/xray/connection/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserInfo'],
    }),
  }),
  overrideExisting: false,
});

export const useDeleteXrayConnection =
  deleteConnectionApi.useDeleteXrayConnectionMutation;
