import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Flex,
  Loading,
  Popconfirm,
  Triangle,
  Typography,
} from 'shared/_ui';
import { DropdownHandles } from 'shared/_ui';
import {
  getCountryCode,
  useEditCountries,
  useFetchCountries,
} from 'entities/Country';
import styles from './styles.module.scss';
import { notify } from 'shared';

type CountrySelectProps = {
  id: number;
  country?: string;
  disabled?: boolean;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  id,
  country,
  disabled = false,
}) => {
  const dropdownRef = React.useRef<DropdownHandles>(null);

  const { data: countries, isLoading } = useFetchCountries(null);
  const [editCountry, { isLoading: isEditLoading }] = useEditCountries();

  const { t } = useTranslation();

  const countryCode = country && getCountryCode(country);

  const selectedCountry = countryCode
    ? t(`country.name.${countryCode.toLowerCase()}`, { defaultValue: country })
    : null;

  const onSelect = async (value: number) => {
    dropdownRef.current?.close();

    try {
      await editCountry({ id, newOutgoingCountryId: value }).unwrap();
      notify.success(t('country.notify.successChanged'));
    } catch (error: any) {
      notify.error(error.data.description);
    }
  };

  if (isLoading || isEditLoading) {
    return <Loading size="small" variant="squares" />;
  }

  return (
    <Dropdown
      ref={dropdownRef}
      contentClassName={styles.content}
      className={styles.dropdown}
      position="bottom-right"
      disabled={disabled}
      content={
        countries?.length ? (
          <Flex direction="column" className={styles.select}>
            {countries?.map((country) => (
              <Popconfirm
                key={country.id}
                title={t('country.confirm.change')}
                onConfirm={() => onSelect(country.id)}
              >
                <Button className={styles.country} type="text">
                  <Typography.Text size={5}>
                    {t(`country.name.${country.name.toLowerCase()}`, {
                      defaultValue: country.name,
                    })}
                  </Typography.Text>
                </Button>
              </Popconfirm>
            ))}
          </Flex>
        ) : (
          <div className={styles.empty}>
            <Typography.Text size={4}>{t('shared.text.empty')}</Typography.Text>
          </div>
        )
      }
    >
      <Button
        className={styles.control}
        type="text"
        suffix={<Triangle className={styles.triangle} />}
      >
        <Typography.Text size={5}>
          {selectedCountry ?? t('country.name.empty')}
        </Typography.Text>
      </Button>
    </Dropdown>
  );
};

export { CountrySelect };
