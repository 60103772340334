import { Connection } from 'entities/Connection';
import { $rtkApi } from 'shared/api/rtkApi';

const createConnectionApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    createXrayConnection: build.mutation<Connection, number>({
      query: (id) => ({
        url: '/api/xray/connection',
        method: 'POST',
        body: { serverId: id },
      }),
      invalidatesTags: ['UserInfo'],
    }),
  }),
  overrideExisting: false,
});

export const useCreateXrayConnection =
  createConnectionApi.useCreateXrayConnectionMutation;
