import React from 'react';
import { Button, Flex, Popover, Typography } from 'shared/_ui';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMatchMedia } from 'shared';

type ConnectionAddProps = {
  onConnectionAdd: () => void;
  disabled?: boolean;
  className?: string;
};

const ConnectionAdd: React.FC<ConnectionAddProps> = ({
  onConnectionAdd,
  disabled = false,
  className,
}) => {
  const { t } = useTranslation();

  const { isMobile } = useMatchMedia();

  const classNamePrefix = 'connection-add';
  const _className = classNames(className, styles[classNamePrefix], {
    [styles[`${classNamePrefix}-disabled`]]: disabled,
  });

  return (
    <Popover
      mode="hover"
      hidden={!disabled}
      content={<p className={styles.hint}>{t('connection.full')}</p>}
    >
      <Button
        disabled={disabled}
        className={_className}
        onClick={onConnectionAdd}
        ghost
      >
        <Flex
          direction={disabled ? 'row' : 'column'}
          justify="space-between"
          align="center"
          spacing={disabled ? 4 : 0}
        >
          <Typography.Text
            type="secondary"
            size={disabled ? 5 : undefined}
            className={styles.plus}
          >
            +
          </Typography.Text>
          <Typography.Text type="secondary" size={isMobile ? 3 : 5}>
            {t('connection.add')}
            {disabled ? '(🔒)' : ''}
          </Typography.Text>
        </Flex>
      </Button>
    </Popover>
  );
};
export { ConnectionAdd };
