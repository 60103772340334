import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type LoadingVariant = 'dots' | 'spin' | 'double-spin' | 'squares';
type LoadingSize = 'small' | 'middle' | 'large';

type LoadingProps = {
  className?: string;
  variant?: LoadingVariant;
  size?: LoadingSize;
};

const Loading: React.FC<LoadingProps> = ({
  className,
  variant = 'spin',
  size = 'middle',
}) => {
  const classNamePrefix = 'loading';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-${variant}`],
    styles[`${classNamePrefix}-${size}`]
  );

  return (
    <div className={_className}>
      <div className={classNames(styles[variant], styles[size])}></div>
    </div>
  );
};

export { Loading };
