import { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import classNames from 'classnames';
import cls from './Stack.module.scss';

type FlexJustify =
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
type FlexAlign = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
type FlexDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';

const justifyClasses: Record<FlexJustify, string> = {
    'flex-start': cls['justify-start'],
    center: cls['justify-center'],
    'flex-end': cls['justify-end'],
    'space-between': cls['justify-between'],
    'space-around': cls['justify-around'],
};

const alignClasses: Record<FlexAlign, string> = {
    'flex-start': cls['align-start'],
    center: cls['align-center'],
    'flex-end': cls['align-end'],
    stretch: cls['align-stretch'],
    baseline: cls['align-baseline'],
};

const directionClasses: Record<FlexDirection, string> = {
    column: cls['direction-column'],
    'column-reverse': cls['direction-column-reverse'],
    row: cls['direction-row'],
    'row-reverse': cls['direction-row-reverse'],
};

type DivProps = DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

interface StackProps extends DivProps {
    spacing?: number;
    direction?: FlexDirection;
    align?: FlexAlign;
    justify?: FlexJustify;
    max?: boolean;
    wrap?: boolean;
    className?: string;
}

export const Stack: FC<StackProps> = (props) => {
    const {
        spacing,
        direction = 'row',
        align = 'center',
        justify = 'flex-start',
        max,
        wrap,
        className,
        children,
    } = props;

    const mods = { [cls.max]: max, [cls.wrap]: wrap };
    const classes = [
        className,
        justifyClasses[justify],
        alignClasses[align],
        directionClasses[direction],
    ];

    return (
        <div
            style={{ gap: spacing }}
            className={classNames(cls.flex, mods, classes)}
        >
            {children}
        </div>
    );
};
