import { ResponseUser } from 'entities/User';
import { $rtkApi } from 'shared/api/rtkApi';

export interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
  auth_date: number;
  hash: string;
}

const telegramLogin = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    telegramLogin: build.mutation<ResponseUser, TelegramUser>({
      query: (params) => {
        return {
          url: '/api/telegram-login',
          method: 'POST',
          body: params,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const useTelegramLogin = telegramLogin.useTelegramLoginMutation;
