import React from 'react';
import { Description, DescriptionItem, Flex, Typography } from 'shared/_ui';
import classNames from 'classnames';
import styles from './styles.module.scss';

type TariffCardProps = {
  tag?: React.ReactNode;
  name: string;
  price: string | null;
  params: DescriptionItem[];
  control?: React.ReactNode;
  className?: string;
  current?: boolean;
};

const TariffCard: React.FC<TariffCardProps> = ({
  tag,
  name,
  price,
  params,
  control,
  className,
}) => {
  const prefixClassName = 'tariff';
  const _className = classNames(className, styles[prefixClassName]);

  return (
    <div className={_className}>
      {tag && <div className={styles.tag}>{tag}</div>}
      <div className={styles.container}>
        <div className={styles.name}>
          <Typography.Mark className={styles.mark}>{name}</Typography.Mark>
        </div>
        {price && (
          <div className={styles.price}>
            <Typography.Title level={4}>{price}</Typography.Title>
          </div>
        )}
        <Flex
          className={styles.bottom}
          direction="column"
          spacing={32}
          justify="space-between"
        >
          <div className={styles.params}>
            <Description items={params} />
          </div>
          {control && <div className={styles.control}>{control}</div>}
        </Flex>
      </div>
    </div>
  );
};

export { TariffCard };
