import React from 'react';
import { Button, Logo } from 'shared/_ui';
import { ThemeSwitcher } from 'features/ThemeSwitcher';
import { UserProfileDropdown } from 'features/UserProfileDropdown';
import styles from './styles.module.scss';
import { Faq } from 'features/Faq';
import { useMatchMedia } from 'shared';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/_ui';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS } from 'shared/consts';
import classNames from 'classnames';
import { useFetchUserInfo, useFetchXrayConnections } from 'entities/Connection';
import { LanguageSwitch } from 'features/SwitchLanguage';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const { data: xrayConnections } = useFetchXrayConnections(null);
  const { data: userInfo } = useFetchUserInfo(null);

  const { isOpen, open, close, setData } = useStoreUiControls(
    CONTROL_IDS.GUIDE
  );

  const isLimit =
    !userInfo ||
    userInfo?.connections.length + (xrayConnections?.length || 0) >=
      userInfo?.tariff.numberOfAccounts;

  const openGuide = () => {
    open();
    setData({ isConnectionCreate: true });
  };

  const closeGuide = () => {
    close();
    setData({ isConnectionCreate: false });
  };

  return (
    <div className={styles.header}>
      <Logo size="large" redirect />
      {!isMobile && !isLimit && (
        <Button
          size="large"
          prefix={<Plus className={styles.plus} />}
          className={classNames(styles.button, {
            [styles['button-active']]: isOpen,
          })}
          type="text"
          shape="round"
          onClick={isOpen ? closeGuide : openGuide}
        >
          {isOpen ? t('connection.cancelAdding') : t('connection.addNewDevice')}
        </Button>
      )}
      <nav className={styles.nav}>
        {!isMobile && <Faq />}
        {!isMobile && <ThemeSwitcher />}
        <UserProfileDropdown languageSwitcherSlot={<LanguageSwitch />} />
      </nav>
    </div>
  );
};

export { Header };
