import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { Button } from '../Button';
import { ClosedEye, OpenEye } from '../Icon';

export type InputProps = {
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  className?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  name?: string;
  maxLength?: number;
  value?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  label?: {
    text: string;
    for?: string;
  };
  suffix?: React.ReactNode;
  variant?: 'filled' | 'outlined';
} & React.InputHTMLAttributes<HTMLInputElement>;

const MaterialInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      placeholder,
      disabled,
      className,
      inputMode,
      name,
      value,
      onChange,
      id,
      maxLength,
      type,
      label,
      suffix,
      variant = 'outlined',
      ...otherProps
    } = props;

    const _className = classNames(className, styles['input'], {
      [styles['input--has-value']]: !!value,
    });

    return (
      <div
        className={classNames(
          styles['input__wrapper'],
          styles[`${variant}-input__wrapper`],
          { [styles['input__wrapper--no-label']]: !label }
        )}
      >
        <div className={_className}>
          <input
            placeholder={placeholder}
            disabled={disabled}
            ref={ref}
            inputMode={inputMode}
            name={name}
            onChange={onChange}
            value={inputMode}
            id={label ? label.for : id}
            maxLength={maxLength}
            type={type}
            {...otherProps}
          />
          {suffix && <span className={styles['input__suffix']}>{suffix}</span>}
        </div>
        {label && (
          <label className={styles['input__label']} htmlFor={label.for}>
            {label.text}
          </label>
        )}
      </div>
    );
  }
) as React.ForwardRefExoticComponent<
  InputProps & React.RefAttributes<HTMLInputElement>
> & {
  Password: typeof MaterialInputPassword;
};

const MaterialInputPassword = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [isShow, setIsShow] = React.useState<boolean>(false);

    const onToggleShow = (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault();
      setIsShow((prev) => !prev);
    };

    return (
      <MaterialInput
        type={isShow ? 'text' : 'password'}
        ref={ref}
        {...props}
        suffix={
          <Button
            htmlType="button"
            type="text"
            onClick={onToggleShow}
            ghost
            icon={
              isShow ? (
                <ClosedEye className={styles.eye} />
              ) : (
                <OpenEye className={styles.eye} />
              )
            }
          />
        }
      />
    );
  }
);

MaterialInput.Password = MaterialInputPassword;

export { MaterialInput };
