import React from 'react';
import classNames from 'classnames';
import { ArrowContainer, Popover as TinyPopover } from 'react-tiny-popover';
import styles from './styles.module.scss';

type PopoverPosition = 'top' | 'right' | 'bottom' | 'left';
type PopoverMode = 'click' | 'hover' | 'all';

type PopoverProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: PopoverPosition | PopoverPosition[];
  force?: boolean;
  hidden?: boolean;
  className?: string;
  contentClassName?: string;
  offset?: number;
  disabled?: boolean;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  mode?: PopoverMode;
  reposition?: boolean;
  align?: 'start' | 'end';
};

export type PopoverHandles = {
  open: () => void;
  close: () => void;
};

const Popover = React.forwardRef<PopoverHandles, PopoverProps>(
  (
    {
      children,
      content,
      position = 'top',
      className,
      contentClassName,
      offset = 10,
      disabled = false,
      isOpen,
      setIsOpen,
      mode = 'click',
      hidden,
      reposition = false,
      align,
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [hoverVisible, setHoverVisible] = React.useState<boolean>(false);
    const [clickVisible, setClickVisible] = React.useState<boolean>(false);

    const isControlled = isOpen !== undefined;

    React.useEffect(() => {
      if (isControlled) {
        setIsVisible(isOpen);
      }

      return () => {
        setIsVisible(false);
      };
    }, [isControlled]);

    React.useEffect(() => {
      if (isControlled) return;

      if (mode === 'hover') {
        setIsVisible(hoverVisible);
      } else if (mode === 'click') {
        setIsVisible(clickVisible);
      } else {
        setIsVisible(hoverVisible || clickVisible);
      }
    }, [hoverVisible, clickVisible, mode]);

    const closePopover = () => {
      if (!disabled) {
        setIsOpen?.(false);
        setClickVisible(false);
        setHoverVisible(false);
      }
    };

    const openPopover = () => {
      if (!disabled) {
        setIsOpen?.(true);
        setClickVisible(true);
        setHoverVisible(true);
      }
    };

    const togglePopover = () => {
      if (!isVisible) {
        openPopover();
      } else {
        closePopover();
      }
    };

    React.useImperativeHandle(ref, () => ({
      open: () => setClickVisible(true),
      close: () => setClickVisible(false),
    }));

    const triggerProps = {
      onClick: () => {
        if (isControlled) return;
        if (mode === 'click' || mode === 'all') togglePopover();
      },
      onMouseEnter: () => {
        if (isControlled) return;
        if (mode === 'hover' || mode === 'all') setHoverVisible(true);
      },
      onMouseLeave: () => {
        if (isControlled) return;
        if (mode === 'hover' || mode === 'all') setHoverVisible(false);
      },
    };

    const stopPropagation = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      e.stopPropagation();
    };

    return (
      <TinyPopover
        align={align}
        reposition={reposition}
        containerClassName={className}
        isOpen={isVisible && !hidden}
        positions={Array.isArray(position) ? position : [position]}
        padding={offset}
        onClickOutside={closePopover}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'var(--border-color-6)'}
            arrowSize={6}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div
              onMouseUp={stopPropagation}
              onMouseDown={stopPropagation}
              className={classNames(contentClassName, styles.content)}
            >
              {content}
            </div>
          </ArrowContainer>
        )}
      >
        <span {...triggerProps}>{children}</span>
      </TinyPopover>
    );
  }
);

export { Popover };
