import { GuideStep } from 'entities/Guide';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'shared/_ui';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { Language } from 'entities/Language';
import { InstructionProps } from '../instruction.props';
import { DownloadConfig } from 'features/DownloadConfig';

const MacConfigInstruction: React.FC<InstructionProps> = ({ connection }) => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'mac',
    'config',
    i18n.language as Language
  );

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.mac.config.step.1.title')}
        description={
          <Flex direction="column" spacing={20}>
            <ol>
              <li>{t('guide.mac.config.step.1.description.1')}</li>
              <li>{t('guide.mac.config.step.1.description.2')}</li>
            </ol>
            <DownloadConfig
              connectionId={connection.id}
              server={connection.server}
              device="mac"
            />
          </Flex>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.mac.config.step.2.title')}
        description={
          <ol>
            <li>{t('guide.mac.config.step.2.description.1')}</li>
            <li>{t('guide.mac.config.step.2.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.mac.config.step.3.title')}
        description={
          <ol>
            <li>{t('guide.mac.config.step.3.description.1')}</li>
            <li>{t('guide.mac.config.step.3.description.2')}</li>
            <li>{t('guide.mac.config.step.3.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={4}
        image={getImageByStep(4)}
        title={t('guide.mac.config.step.4.title')}
        description={
          <ol>
            <li>{t('guide.mac.config.step.4.description.1')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(5)}
        title={t('guide.mac.config.step.5.title')}
        final={false}
      />
      <GuideStep
        number={6}
        image={getImageByStep(6)}
        title={t('guide.mac.config.step.6.title')}
        final={false}
      />
      <GuideStep
        number={7}
        image={getImageByStep(7)}
        title={t('guide.mac.config.step.7.title')}
        description={
          <ol>
            <li>{t('guide.mac.config.step.7.description.1')}</li>
            <li>{t('guide.mac.config.step.7.description.2')}</li>
            <li>{t('guide.mac.config.step.7.description.3')}</li>
            <li>{t('guide.mac.config.step.7.description.4')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={8}
        image={getImageByStep(8)}
        title={t('guide.mac.config.step.8.title')}
        description={
          <ol>
            <li>{t('guide.mac.config.step.8.description.1')}</li>
            <li>{t('guide.mac.config.step.8.description.2')}</li>
            <li>{t('guide.mac.config.step.8.description.3')}</li>
          </ol>
        }
        final={true}
      />
    </Flex>
  );
};

export { MacConfigInstruction };
