import { Device } from 'entities/Device';
import { Installation } from 'entities/Installation';
import React from 'react';
import {
  MacConfigInstruction,
  MacManualInstruction,
  WindowsConfigInstruction,
  WindowsManualInstruction,
  IphoneConfigInstruction,
  IphoneManualInstruction,
  AndroidConfigInstruction,
  AndroidManualInstruction,
  InstructionProps,
  MacAppInstruction,
  WindowsAppInstruction,
  IphoneAppInstruction,
  AndroidAppInstruction,
} from '../../ui';

const instructions: Record<
  Device,
  Record<Installation, React.FC<InstructionProps>>
> = {
  mac: {
    app: MacAppInstruction,
    manual: MacManualInstruction,
    config: MacConfigInstruction,
  },
  windows: {
    app: WindowsAppInstruction,
    manual: WindowsManualInstruction,
    config: WindowsConfigInstruction,
  },
  iphone: {
    app: IphoneAppInstruction,
    manual: IphoneManualInstruction,
    config: IphoneConfigInstruction,
  },
  android: {
    app: AndroidAppInstruction,
    manual: AndroidManualInstruction,
    config: AndroidConfigInstruction,
  },
};

export const getInstructionByParams = (device: Device, type: Installation) => {
  return instructions[device][type];
};
