import React, { ButtonHTMLAttributes, FC, RefObject } from 'react';
import classNames from 'classnames';
import cls from './Button.module.scss';

type ButtonTheme = 'clear' | 'outline' | 'background' | 'icon';
type ButtonSize = 'size_m' | 'size_l' | 'size_xl';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    theme?: ButtonTheme;
    square?: boolean;
    size?: ButtonSize;
    max?: boolean;
    hasSpinner?: boolean;
    buttonRef?: RefObject<HTMLButtonElement>;
}

export const Button: FC<ButtonProps> = (props) => {
    const {
        className,
        theme = 'clear',
        size = 'size_m',
        children,
        square,
        max = false,
        buttonRef,
        hasSpinner,
        ...otherProps
    } = props;

    return (
        <button
            ref={buttonRef}
            type="button"
            className={classNames(
                cls.Button,
                { [cls.square]: square, [cls.maxWidth]: max },
                cls[theme],
                cls[size],
                className
            )}
            {...otherProps}
        >
                {
                    hasSpinner && props.disabled ? <span className={cls.loader}></span> : children
                }
        </button>
    );
};
