import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type TagProps = {
  children: React.ReactNode;
  className?: string;
  color?: 'green' | 'red' | 'yellow' | 'gray';
};

const Tag: React.FC<TagProps> = ({ children, className, color = 'green' }) => {
  const classNamePrefix = 'tag';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-${color}`]
  );

  return <span className={_className}>{children}</span>;
};

export { Tag };
