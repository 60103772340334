import { FC, memo } from 'react';
import classNames from 'classnames';
import { Loader, Stack } from 'shared/ui';
import cls from './PageLoader.module.scss';

interface PageLoaderProps {
    className?: string;
}

export const PageLoader: FC<PageLoaderProps> = memo((props) => {
    const { className } = props;

    return (
        <Stack
            justify="center"
            align="center"
            className={classNames(cls.PageLoader, className)}
        >
            <Loader />
        </Stack>
    );
});
