import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type DescriptionItem = {
  label: string;
  description: React.ReactNode;
  highlighted?: boolean;
};

type DescriptionProps = {
  items: DescriptionItem[];
  className?: string;
};

const Description: React.FC<DescriptionProps> = ({ className, items }) => {
  const _className = classNames(className, styles.description);

  return (
    <div className={_className}>
      {items.map(({ label, description, highlighted }) => (
        <div key={label} className={styles.row}>
          <div
            className={classNames({
              [styles.label]: true,
              [styles.highlighted]: highlighted,
            })}
          >
            {label}
          </div>
          <div className={styles.value}>{description}</div>
        </div>
      ))}
    </div>
  );
};

export { Description };
export type { DescriptionItem };
