import React from 'react';
import { Device } from 'entities/Device';
import { Copy, Flex, Image } from 'shared/_ui';
import styles from './styles.module.scss';
import { useTheme } from 'entities/Theme';
import { getDeviceImageByTheme } from '../../lib';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

type ConnectionCardProps = {
  actionsMenu: React.ReactNode;
  countryMenu: React.ReactNode;
  host: string;
  device: Device;
  expiredAt: string;
  className?: string;
};

const XrayConnectionCard: React.FC<ConnectionCardProps> = ({
  actionsMenu,
  countryMenu,
  host,
  device,
  expiredAt,
  className,
}) => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();

  const expirationDate = DateTime.fromISO(expiredAt);
  const expiresIn = expirationDate
    .setLocale(i18n.language)
    .diffNow('days')
    .toHuman()
    .replace(/(,|\.)\d+/, '');

  const deviceImage = getDeviceImageByTheme(device, theme);

  const classNamePrefix = 'connection';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <Flex
      spacing={16}
      direction="column"
      justify="space-between"
      className={_className}
    >
      <div className={styles.badge}>{t('xray.connection')}</div>
      <div className={styles.actions}>{actionsMenu}</div>

      <Image
        height={272}
        className={styles.image}
        src={deviceImage}
        alt={device}
      />
      <Flex spacing={20} direction="column" className={styles.description}>
        <div className={styles.country}>{countryMenu}</div>
        <p className={styles.daysLeft}>
          {t('xray.remaining')}
          <span className={styles.days}>{expiresIn}</span>
        </p>
        <Flex spacing={4} direction="column" className={styles.params}>
          <Copy className={styles['text']} text={host} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export { XrayConnectionCard };
