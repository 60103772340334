import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { Device } from '../types';

// Привязка выбранного устройства к соединению на клиенте, пока нет реализации на сервере

const CONNECTION_DEVICES_KEY = 'CONNECTION_DEVICES';

export const useConnectionDevices = () => {
  const [connectionDevices, setConnectionDevices] = useLocalStorage<
    Record<number, Device>
  >(CONNECTION_DEVICES_KEY, {});

  const getDevice = React.useCallback(
    (connectionId: number) => {
      const device = connectionDevices[connectionId];

      return device ? device : null;
    },
    [connectionDevices]
  );

  const createDevice = React.useCallback(
    (connectionId: number, device: Device) => {
      setConnectionDevices((prev) => ({ ...prev, [connectionId]: device }));
    },
    [connectionDevices]
  );

  const changeDevice = React.useCallback(
    (connectionId: number, device: Device) => {
      setConnectionDevices((prev) => ({ ...prev, [connectionId]: device }));
    },
    [connectionDevices]
  );

  const deleteDevice = React.useCallback(
    (connectionId: number) => {
      setConnectionDevices((prev) =>
        Object.fromEntries(
          Object.entries(prev).filter(
            ([key]) => String(key) !== String(connectionId)
          )
        )
      );
    },
    [connectionDevices]
  );

  return { getDevice, createDevice, changeDevice, deleteDevice };
};
