import React from 'react';
import classNames from 'classnames';
import { Container } from 'shared/_ui';
import styles from './styles.module.scss';
import { useRefContext } from 'shared/context';
import { REF_IDS } from 'shared/consts';
import { useDebounce, useWindowSize } from '@uidotdev/usehooks';

type GuideSlideProps = {
  className?: string;
  head?: React.ReactNode;
  body?: React.ReactNode;
  controls?: React.ReactNode;
  footer?: React.ReactNode;
};

const GuideSlide: React.FC<GuideSlideProps> = ({
  className,
  head,
  body,
  controls,
  footer,
}) => {
  const classNamePrefix = 'slide';
  const _className = classNames(className, styles[classNamePrefix]);

  const [controlsStyles, setControlsStyles] =
    React.useState<React.CSSProperties>({});

  const { refs } = useRefContext();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const controlsRef = React.useRef<HTMLDivElement>(null);

  const { width } = useWindowSize();
  const debouncedWidth = useDebounce(width, 300);

  React.useEffect(() => {
    const layout = refs.current[REF_IDS.LAYOUT];
    const container = containerRef.current;
    const controls = controlsRef.current;

    if (!debouncedWidth || debouncedWidth <= 768) {
      return;
    }

    if (!layout || !container || !controls) {
      return;
    }

    const onScroll = () => {
      const parentRect = container.getBoundingClientRect();
      const elementRect = controls.getBoundingClientRect();

      const marginOffset = 488;
      const layoutPadding = 36 * 2;

      const maxTop =
        parentRect.height - elementRect.height - layoutPadding - marginOffset;

      let newTop = layout.scrollTop - marginOffset;
      newTop = Math.max(0, newTop);
      newTop = Math.min(newTop, maxTop);

      setControlsStyles({ top: `${newTop}px` });
    };

    layout.addEventListener('scroll', onScroll);

    return () => {
      layout.removeEventListener('scroll', onScroll);
    };
  }, [debouncedWidth]);

  return (
    <Container ref={containerRef} className={_className}>
      <div className={styles.left}>
        {head && <div className={styles.head}>{head}</div>}
        {body && <div className={styles.body}>{body}</div>}
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
      <div className={styles.right}>
        {controls && (
          <div
            ref={controlsRef}
            style={controlsStyles}
            className={styles.controls}
          >
            {controls}
          </div>
        )}
      </div>
    </Container>
  );
};

export { GuideSlide };
