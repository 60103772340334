import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type ContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, children }, ref) => {
    const classNamePrefix = 'container';
    const _className = classNames(className, styles[classNamePrefix]);

    return (
      <div ref={ref} className={_className}>
        {children}
      </div>
    );
  }
);

export { Container };
