import { buildSlice } from 'shared/lib/store';
import { getTheme } from '../lib/getTheme';
import {updateThemeBrawser} from "../../User/lib/updateThemeBrawser";
import { Mode, ThemeSchema } from './themeSchema';

const initialState: ThemeSchema = {
    mode: Mode.DARK,
};

const themeSlice = buildSlice({
    name: 'theme',
    initialState,
    reducers: {
        setThemeData: (state) => {
            const currentTheme = state.mode === Mode.DARK ? Mode.LIGHT : Mode.DARK
            updateThemeBrawser(currentTheme)
            state.mode = currentTheme;
        },
        initThemeData: (state) => {
            const theme = getTheme()
            updateThemeBrawser(theme)
            state.mode = theme;
        },
    },
});

export const { actions: themeActions } = themeSlice;
export const { useActions: useThemeActions } = themeSlice;
export const { reducer: themeReducer } = themeSlice;
