import { $rtkApi } from './rtkApi';

export interface PaymentProvider {
    id: number;
    name: string;
}

const CACHE_TIMER = 3600;

const fetchProvidersApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchProviders: build.query<PaymentProvider[], null>({
            query: () => ({
                url: '/api/dictionary/payment_providers',
            }),
            keepUnusedDataFor: CACHE_TIMER,
        }),
    }),
    overrideExisting: false,
});

export const useFetchProviders = fetchProvidersApi.useFetchProvidersQuery;
