import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';

const PrivateGate: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className);
  return (
    <svg
      className={_className}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.269 13.339H34.8856V16.417H32.8341V13.339H29.756V16.417H25.355C24.472 13.4502 21.7237 11.2868 18.4701 11.2868C14.5033 11.2868 11.2877 14.5024 11.2877 18.4692C11.2877 22.4359 14.5033 25.6516 18.4701 25.6516C21.7237 25.6516 24.4721 23.4881 25.355 20.5212L36.8891 20.5213C35.8817 29.7869 28.0326 36.9984 18.4992 36.9984C8.28235 36.9984 0 28.716 0 18.4992C0 8.28237 8.28235 0 18.4992 0C26.925 0 34.0352 5.63321 36.269 13.339Z"
        fill="url(#paint0_linear_2443_6368)"
      />
      <path
        d="M15.7593 18.4692C15.7593 19.9663 16.973 21.1799 18.4701 21.1799C19.9672 21.1799 21.1808 19.9663 21.1808 18.4692C21.1808 16.9721 19.9672 15.7584 18.4701 15.7584C16.973 15.7584 15.7593 16.9721 15.7593 18.4692Z"
        fill="url(#paint1_linear_2443_6368)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2443_6368"
          x1="30.6907"
          y1="32.8338"
          x2="12.1934"
          y2="0.0467698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0BC455" />
          <stop offset="1" stopColor="#81FD5A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2443_6368"
          x1="30.6907"
          y1="32.8338"
          x2="12.1934"
          y2="0.0467698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0BC455" />
          <stop offset="1" stopColor="#81FD5A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { PrivateGate };
