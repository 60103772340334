import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleState, useUiControlsState } from '../../model';

export const useOpen = (initial: boolean | null) => {
  const [isOpen, setIsOpen] = React.useState<boolean | null>(initial);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, open, close, setIsOpen };
};

export const useStoreUiControls = (elementId: string) => {
  const state = useUiControlsState(elementId);
  const dispatch = useDispatch();

  const open = React.useCallback(() => {
    dispatch(toggleState({ elementId, newState: { isOpen: true } }));
  }, [elementId, state]);

  const close = React.useCallback(() => {
    dispatch(toggleState({ elementId, newState: { isOpen: false } }));
  }, [elementId, state]);

  const setIsOpen = React.useCallback(
    (isOpen: boolean) => {
      dispatch(toggleState({ elementId, newState: { isOpen } }));
    },
    [elementId, state]
  );

  const setData = React.useCallback(
    (data: any) => {
      dispatch(toggleState({ elementId, newState: { data } }));
    },
    [elementId, state]
  );

  return {
    isOpen: state?.isOpen ?? null,
    data: state?.data,
    open,
    close,
    setIsOpen,
    setData,
  };
};
