import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Question: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.3799 22.2012C5.39648 22.2012 0.422852 17.2275 0.422852 11.2441C0.422852 5.25 5.38574 0.287109 11.3691 0.287109C17.3633 0.287109 22.3369 5.25 22.3369 11.2441C22.3369 17.2275 17.374 22.2012 11.3799 22.2012ZM11.3799 20.375C16.4502 20.375 20.5107 16.3145 20.5107 11.2441C20.5107 6.17383 16.4395 2.11328 11.3691 2.11328C6.29883 2.11328 2.25977 6.17383 2.25977 11.2441C2.25977 16.3145 6.30957 20.375 11.3799 20.375ZM11.1543 13.4678C10.6064 13.4678 10.2949 13.1777 10.2949 12.6191V12.4795C10.2949 11.4268 10.875 10.8574 11.6484 10.3203C12.583 9.66504 13.0342 9.31055 13.0342 8.58008C13.0342 7.76367 12.4004 7.20508 11.4229 7.20508C10.7031 7.20508 10.1445 7.57031 9.76855 8.19336L9.5752 8.45117C9.38184 8.70898 9.14551 8.88086 8.78027 8.88086C8.42578 8.88086 8.0498 8.62305 8.0498 8.13965C8.0498 7.95703 8.08203 7.78516 8.13574 7.61328C8.42578 6.625 9.63965 5.74414 11.4873 5.74414C13.3135 5.74414 14.9033 6.71094 14.9033 8.49414C14.9033 9.7832 14.1621 10.4062 13.1201 11.0938C12.3682 11.5879 12.0244 11.9746 12.0244 12.5762V12.7051C12.0244 13.124 11.6914 13.4678 11.1543 13.4678ZM11.1328 16.6689C10.5098 16.6689 9.97266 16.1641 9.97266 15.5518C9.97266 14.9395 10.499 14.4346 11.1328 14.4346C11.7666 14.4346 12.293 14.9287 12.293 15.5518C12.293 16.1748 11.7559 16.6689 11.1328 16.6689Z" />
    </svg>
  );
};

export { Question };
