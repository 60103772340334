import React from 'react';
import styles from './styles.module.scss';
import { Collapse, Flex, QueryStatusBoundary, Typography } from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import { useFetchTariffs } from 'shared/api';
import classNames from 'classnames';
import { useStoreUiControls } from 'shared/lib';
import { useRefContext } from 'shared/context';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { useFetchUserInfo } from 'entities/Connection';
import { AvailableTariff } from '../AvailableTariff';

const AvailableTariffs: React.FC = () => {
  const { t } = useTranslation();

  const { setRef } = useRefContext();

  const { isOpen, setIsOpen } = useStoreUiControls(CONTROL_IDS.AVAILABLE_PLANS);

  const { data: tariffs, isLoading, isError, refetch } = useFetchTariffs(null);
  const { data: userInfo } = useFetchUserInfo(null);

  return (
    <Collapse
      ref={(node) => setRef(REF_IDS.AVAILABLE_PLANS, node)}
      isOpen={isOpen}
      onToggle={setIsOpen}
      className={classNames(styles.collapse, {
        [styles['collapse-closed']]: !isOpen,
      })}
      arrowClassName={styles.arrow}
      header={
        <Typography.Title level={2}>
          {t('tariff.availableList')}
        </Typography.Title>
      }
    >
      <QueryStatusBoundary
        isLoading={isLoading}
        isError={isError}
        isEmpty={!tariffs?.length}
        refresh={refetch}
      >
        <Flex className={styles.tariffs} wrap spacing={20}>
          {tariffs?.map((tariff, index) => (
            <AvailableTariff
              key={tariff.id}
              current={userInfo?.tariff.id === tariff.id}
              type={
                index === tariffs.length - 1
                  ? 'beneficial'
                  : index === tariffs.length - 2
                  ? 'popular'
                  : 'default'
              }
              baseTariff={tariffs[0]}
              tariff={tariff}
            />
          ))}
        </Flex>
      </QueryStatusBoundary>
    </Collapse>
  );
};

export { AvailableTariffs };
