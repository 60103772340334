import { useLocalStorage } from 'usehooks-ts';
import { useFetchUserInfo } from 'entities/Connection';
import React from 'react';
import { CONTROL_IDS } from 'shared/consts';
import { daysUntilDate, useStoreUiControls } from 'shared/lib';

export const useExpirationPlan = () => {
  const [isBannerThreeShowed, setIsBannerThreeShowed] =
    useLocalStorage<boolean>(CONTROL_IDS.PREMIUM_EXPIRATION_BANNER, false);
  const [isBannerNineShowed, setIsBannerNineShowed] = useLocalStorage<boolean>(
    CONTROL_IDS.PREMIUM_EXPIRATION_BANNER,
    false
  );
  const [isBannerOneShowed, setIsBannerOneShowed] = useLocalStorage<boolean>(
    CONTROL_IDS.PREMIUM_EXPIRATION_BANNER_1,
    false
  );

  const { open: threeOpen } = useStoreUiControls(
    CONTROL_IDS.PREMIUM_EXPIRATION_BANNER
  );
  const { open: nineOpen } = useStoreUiControls(
    CONTROL_IDS.PREMIUM_EXPIRATION_BANNER_9
  );
  const { open: oneOpen } = useStoreUiControls(
    CONTROL_IDS.PREMIUM_EXPIRATION_BANNER_1
  );

  const { data: userInfo } = useFetchUserInfo(null);

  React.useEffect(() => {
    if (!userInfo || !userInfo.paidTo) return;

    const days = daysUntilDate(userInfo.paidTo);

    if (days <= 9) {
      if (isBannerNineShowed) return;

      nineOpen();
      setIsBannerNineShowed(true);
    }

    if (days <= 3) {
      if (isBannerThreeShowed) return;

      threeOpen();
      setIsBannerThreeShowed(true);
    }

    if (days <= 1) {
      if (isBannerOneShowed) return;

      oneOpen();
      setIsBannerOneShowed(true);
    }
  }, [userInfo]);
};
