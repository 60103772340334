import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserActions } from 'entities/User';
import { notify } from 'shared/lib/notification';
import { Button, Flex, MaterialInput, Typography } from 'shared/_ui';
import { getRestore } from 'shared/consts/routes';
import { LoginValues, useLogin } from '../../api/login';
import { validationScheme } from '../../lib/validationScheme';

import styles from './styles.module.scss';

type LoginFormProps = {
  authProviders?: () => React.ReactNode;
};

export const LoginForm: React.FC<LoginFormProps> = ({ authProviders }) => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLogin();
  const { setAuthData } = useUserActions();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValues>({
    mode: 'onSubmit',
    defaultValues: { login: '', password: '' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (values: LoginValues) => {
    await login(values)
      .unwrap()
      .then((res) => {
        setAuthData(res);
      })
      .catch(() => {
        notify.error(t('authentication.login.error'));
      });
  };

  return (
    <Flex direction="column" spacing={20}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" spacing={20} className={styles.wrapper}>
          <div className={styles.field}>
            <MaterialInput placeholder="Email" {...register('login')} />
            {errors.login && (
              <Typography.Text type="danger">
                {t(
                  errors.login?.message
                    ? errors.login?.message
                    : 'shared.error.base'
                )}
              </Typography.Text>
            )}
          </div>
          <div className={styles.field}>
            <MaterialInput.Password
              placeholder={t('authentication.login.password')}
              {...register('password')}
            />
            {errors.password && (
              <Typography.Text type="danger">
                {t(
                  errors.password?.message
                    ? errors.password?.message
                    : 'shared.error.base'
                )}
              </Typography.Text>
            )}
          </div>
          <Typography.Link type="secondary" className={styles.link}>
            <Link to={getRestore()}>{t('authentication.password.forgot')}</Link>
          </Typography.Link>
          <Button loading={isLoading} type="primary">
            {t('authentication.login.loginButton')}
          </Button>
        </Flex>
      </form>
      {authProviders?.()}
    </Flex>
  );
};
