import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RestoreForm } from 'features/RestoreAuth';
import { useUserAuthData } from 'entities/User';
import { getRouteLogin, getRouteMain } from 'shared/consts/routes';
import { AuthenticationContainer } from 'entities/Authentication';
import { Typography } from 'shared/_ui';
import styles from './styles.module.scss';

export const RestorePage: React.FC = () => {
  const { t } = useTranslation();
  const authData = useUserAuthData();
  const navigate = useNavigate();

  // Это должно решаться на уровне routes
  React.useEffect(() => {
    if (authData) navigate(getRouteMain());
  }, [authData, navigate]);

  return (
    <AuthenticationContainer
      title={t('authentication.password.restore')}
      hint={
        <div className={styles.hint}>
          <Typography.Text type="secondary">
            {t('authentication.registration.alreadyHaveAccount')}
          </Typography.Text>
          <Typography.Link type="primary">
            <Link to={getRouteLogin()}>
              {t('authentication.registration.login')}
            </Link>
          </Typography.Link>
        </div>
      }
    >
      <RestoreForm />
    </AuthenticationContainer>
  );
};
