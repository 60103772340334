import { Connection } from 'entities/Connection';
import { $rtkApi } from 'shared/api/rtkApi';

const createConnectionApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        createConnection: build.mutation<Connection, number>({
            query: (id) => ({
                url: '/api/radius/user/create',
                method: 'POST',
                body: { outgoingCountryId: id },
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});

export const useCreateConnection =
    createConnectionApi.useCreateConnectionMutation;
