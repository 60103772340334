import React from 'react';
import classNames from 'classnames';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { useRefContext } from 'shared/context';
import { REF_IDS } from '../../consts';
import styles from './styles.module.scss';

type LayoutProps = {
  children: React.ReactNode;
  controls?: React.ReactNode | React.ReactNode[];
  className?: string;
};

const Layout: React.FC<LayoutProps> & {
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
} = ({ children, className, controls }) => {
  const { setRef } = useRefContext();

  const classNamePrefix = 'layout';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <div ref={(node) => setRef(REF_IDS.LAYOUT, node)} className={_className}>
      <div className={styles.controls}>{controls}</div>
      {children}
    </div>
  );
};

Layout.Header = Header;
Layout.Content = Content;
Layout.Footer = Footer;

export { Layout };
