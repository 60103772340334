import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Copy1: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7671 19.334V13.9717C15.7671 12.71 15.5723 11.6338 14.5332 10.5854L9.26367 5.3252C8.22461 4.28613 7.17627 3.98926 5.87744 3.98926H4.53223V3.46045C4.53223 1.60498 5.5249 0.621582 7.4082 0.621582H12.353V6.10449C12.353 7.06934 12.9004 7.6167 13.8745 7.6167H19.0049V16.4209C19.0049 18.3506 18.0308 19.334 16.1289 19.334H15.7671ZM14.0508 6.45703C13.6704 6.45703 13.5312 6.29932 13.5312 5.91895L13.522 1.00195C13.8096 1.03906 14.0786 1.24316 14.394 1.57715L18.3184 5.57568C18.6431 5.90039 18.8472 6.16016 18.8936 6.45703H14.0508ZM2.90869 23.9634C0.988281 23.9634 0.0327148 22.9893 0.0327148 21.0503V8.16406C0.0327148 6.2251 0.997559 5.25098 2.90869 5.25098H6.27637V11.9678C6.27637 13.1553 6.83301 13.7212 8.02979 13.7212H14.5054V21.0503C14.5054 22.98 13.5498 23.9634 11.6294 23.9634H2.90869ZM8.02051 12.4502C7.70508 12.4502 7.53809 12.2925 7.53809 11.9771V5.48291C7.8999 5.58496 8.25244 5.86328 8.66064 6.28076L13.6147 11.3276C14.0322 11.7544 14.2456 12.0884 14.3384 12.4502H8.02051Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export { Copy1 };
