import React, { useEffect } from 'react';
import { ToastContainer, ToastOptions } from 'react-toastify';
import { PageLoader } from 'widgets/PageLoader';
import {
  useInitAuthData,
  useTokenLifetimeVerificationTimer,
  useUserInited,
} from 'entities/User';
import { useTheme } from 'entities/Theme';
import { AppRouter } from './providers/AppRouter';
import './styles/index.scss';
import { useLocalStorageVersion } from 'shared/lib';
import {
  useExpirationPlan,
  useExpiredPlan,
  useFreeTariff,
  useNewUser,
  useSpeedLimit,
  useUserPlan,
} from 'features/UserInformation';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'entities/Language';

export const App: React.FC = () => {
  useInitAuthData();
  useTokenLifetimeVerificationTimer();
  useLocalStorageVersion();
  useUserPlan();
  useNewUser();
  useFreeTariff();
  useExpirationPlan();
  useExpiredPlan();
  useSpeedLimit();
  const inited = useUserInited();

  const { theme } = useTheme();

  const toastifyConfig: ToastOptions = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: theme,
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    if (
      !document.referrer ||
      document.referrer.startsWith(window.location.origin)
    ) {
      return;
    }

    if (/\/ru$/.test(document.referrer)) {
      i18n.changeLanguage(LANGUAGES[1]);
      return;
    }

    const localstorageLanguage = localStorage.getItem('i18nextLng');

    if (localstorageLanguage) {
      i18n.changeLanguage(localstorageLanguage);
      return;
    }

    i18n.changeLanguage(LANGUAGES[0]);
  }, []);

  return (
    <>
      <React.Suspense fallback={<PageLoader />}>
        {inited && <AppRouter />}
      </React.Suspense>
      <ToastContainer {...toastifyConfig} />
    </>
  );
};
