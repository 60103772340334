import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Telegram: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86753 7.33196C7.50352 4.94042 11.2555 3.3513 13.1395 2.58035C18.5017 0.393346 19.6289 0.0157338 20.3536 0C20.5146 0 20.8689 0.0314679 21.1104 0.220274C21.3036 0.377612 21.3519 0.582152 21.3841 0.73949C21.4163 0.896828 21.4486 1.22724 21.4163 1.47898C21.1265 4.46841 19.8705 11.7217 19.2264 15.0573C18.9526 16.4733 18.4212 16.9453 17.9059 16.9925C16.7787 17.0869 15.9253 16.2688 14.8464 15.5765C13.1395 14.4908 12.1894 13.8143 10.5308 12.7444C8.61461 11.5172 9.85453 10.8406 10.9495 9.73924C11.2394 9.45603 16.1829 5.05056 16.2796 4.65721C16.2957 4.61001 16.2957 4.4212 16.1829 4.3268C16.0702 4.2324 15.9092 4.26387 15.7804 4.29533C15.6032 4.3268 12.898 6.08899 7.63234 9.56616C6.8594 10.0854 6.16698 10.3371 5.53897 10.3214C4.84655 10.3057 3.52612 9.94377 2.52775 9.6291C1.32003 9.25149 0.353865 9.04695 0.434379 8.38613C0.482688 8.03998 0.965772 7.69384 1.86753 7.33196Z"
        fill="white"
      />
    </svg>
  );
};

export { Telegram };
