import React from 'react';
import { Button, Flex, Select, Typography } from 'shared/_ui';
import styles from './styles.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationScheme } from '../lib/validationScheme';
import { Payment, useCreatePaymentRefresh } from 'shared/api';
import { useForm, Controller } from 'react-hook-form';
import { createPaymentFrame, createPaymentWidgetScript } from 'entities/Tariff';
import { useTranslation } from 'react-i18next';
import { getProviderName } from '../lib/getProviderName';
import { PAYMENT_PROVIDER_IDS } from '../const';
import { PaymentModal } from 'entities/Payment';
import { useModal } from 'shared/lib';

type PaymentFormProps = {
  tariffId: number;
};

const PaymentForm: React.FC<PaymentFormProps> = ({ tariffId }) => {
  const { t } = useTranslation();

  const [currentPayment, setCurrentPayment] = React.useState<Payment | null>(
    null
  );

  const { isOpen, isMount, openModal, setIsOpen } = useModal(false);

  const [createPayment] = useCreatePaymentRefresh();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ providerId: number }>({
    mode: 'onSubmit',
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: { providerId: number }) => {
    try {
      const response = await createPayment({ ...data, tariffId }).unwrap();
      setCurrentPayment(response);
      openModal();
      createPaymentWidgetScript(response?.widgetUrl);
      if (response?.paymentUrl) {
        setTimeout(() => {
          createPaymentFrame(response.paymentUrl);
        }, 100);
      }
    } catch (error) {
      console.error(t('shared.error.base'));
    }
  };

  const options = Object.values(PAYMENT_PROVIDER_IDS).map<{
    label: string;
    value: number;
  }>((id) => ({
    label: t(getProviderName(id)),
    value: id,
  }));

  React.useEffect(() => {
    if (!isOpen) {
      setCurrentPayment(null);
    }
  }, [isOpen]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" spacing={28} className={styles.form}>
          <Flex direction="column" spacing={16}>
            <Typography.Title level={1}>{t('payment.title')}</Typography.Title>
            <Flex direction="column" spacing={8}>
              <Typography.Text size={1} type="secondary">
                {t('payment.paymentMethod')}
              </Typography.Text>
              <Controller
                control={control}
                name="providerId"
                render={({ field: { value, onChange } }) => (
                  <Select
                    placeholder={t('payment.selectPaymentMethod')}
                    options={options}
                    value={options?.find((c) => c.value === value)}
                    onChange={(value: any) => onChange(value.value)}
                  />
                )}
              />
              {errors.providerId && (
                <Typography.Text type="danger">
                  {t(
                    errors.providerId?.message
                      ? errors.providerId?.message
                      : 'shared.error.base'
                  )}
                </Typography.Text>
              )}
            </Flex>
          </Flex>
          <Button type="primary">
            <Typography.Text size={2} strong>
              {t('payment.pay')}
            </Typography.Text>
          </Button>
        </Flex>
      </form>
      {isMount && (
        <PaymentModal
          isOpen={!!isOpen}
          setIsOpen={setIsOpen}
          currentPayment={currentPayment}
        />
      )}
    </>
  );
};

export { PaymentForm };
