import { GuideStep } from 'entities/Guide';
import { Language } from 'entities/Language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Copy, Flex, Typography } from 'shared/_ui';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { InstructionProps } from '../instruction.props';

const IphoneManualInstruction: React.FC<InstructionProps> = ({
  connection,
}) => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'iphone',
    'manual',
    i18n.language as Language
  );

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.iphone.manual.step.1.title')}
        description={
          <ul>
            <li>{t('guide.iphone.manual.step.1.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.iphone.manual.step.2.title')}
        description={
          <ol>
            <li>{t('guide.iphone.manual.step.2.description.1')}</li>
            <li>{t('guide.iphone.manual.step.2.description.2')}</li>
            <li>{t('guide.iphone.manual.step.2.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.iphone.manual.step.3.title')}
        description={
          <ul>
            <li>{t('guide.iphone.manual.step.3.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={4}
        image={getImageByStep(4)}
        title={t('guide.iphone.manual.step.4.title')}
        description={
          <ul>
            <li>{t('guide.iphone.manual.step.4.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(5)}
        title={t('guide.iphone.manual.step.5.title')}
        description={
          <ol>
            <li>{t('guide.iphone.manual.step.5.description.1')}</li>
            <li>{t('guide.iphone.manual.step.5.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={6}
        image={getImageByStep(6)}
        title={t('guide.iphone.manual.step.6.title')}
        description={
          <ol>
            <li>{t('guide.iphone.manual.step.6.description.1')}</li>
            <Flex
              direction="column"
              spacing={12}
              style={{ width: 'fit-content', marginTop: 16, marginBottom: 32 }}
            >
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.iphone.title.description')}
                </Typography.Text>
                <Copy variant="filled" text={connection.name} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.iphone.title.server')}
                </Typography.Text>
                <Copy variant="filled" text={connection.server} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.iphone.title.remote')}
                </Typography.Text>
                <Copy variant="filled" text={connection.server} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.iphone.title.user')}
                </Typography.Text>
                <Copy variant="filled" text={connection.login} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.iphone.title.password')}
                </Typography.Text>
                <Copy variant="filled" text={connection.password} />
              </Flex>
            </Flex>
            <li>{t('guide.iphone.manual.step.6.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={7}
        image={getImageByStep(7)}
        title={t('guide.iphone.manual.step.7.title')}
        description={
          <ol>
            <li>{t('guide.iphone.manual.step.7.description.1')}</li>
            <li>{t('guide.iphone.manual.step.7.description.2')}</li>
          </ol>
        }
        final={true}
      />
    </Flex>
  );
};

export { IphoneManualInstruction };
