import { $rtkApi } from 'shared/api';

interface XrayConnection {
  country: string;
  id: number;
  serverId: number;
  subscription: string;
  userId: number;
  expiredAt: string;
}

const fetchUserInfoApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    fetchXrayConnections: build.query<XrayConnection[], null>({
      query: () => ({
        url: '/api/xray/connections',
      }),
      providesTags: ['UserInfo'],
    }),
  }),
  overrideExisting: false,
});

export const useFetchXrayConnections =
  fetchUserInfoApi.useFetchXrayConnectionsQuery;
export const useFetchXrayConnectionsResult =
  fetchUserInfoApi.endpoints.fetchXrayConnections.useQueryState;
