import React from 'react';
import { Button, Dropdown, Flex, Question, Typography } from 'shared/_ui';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { CONTROL_IDS } from 'shared/consts';
import { useOpen, useStoreUiControls } from 'shared/lib';

const Faq: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useOpen(null);

  const { open: openGuide } = useStoreUiControls(CONTROL_IDS.GUIDE);

  const onGuideShow = () => {
    openGuide();
    close();
  };

  return (
    <Dropdown
      isOpen={isOpen}
      position="bottom-right"
      content={
        <Flex className={styles.content}>
          <Typography.Link onClick={onGuideShow}>
            <Typography.Text size={2}>
              {t('navigation.howToInstall')}?
            </Typography.Text>
          </Typography.Link>
        </Flex>
      }
    >
      <Button
        onClick={open}
        className={styles.button}
        type="text"
        shape="round"
        size="large"
        icon={<Question className={styles.question} />}
      >
        {t('navigation.faq')}
      </Button>
    </Dropdown>
  );
};

export { Faq };
