import React from 'react';
import { Container, Flex, Image, Typography } from 'shared/_ui';
import styles from './styles.module.scss';
import drone from 'shared/assets/images/drone.svg';
import { useTranslation } from 'react-i18next';
import { PromoCodeForm } from 'features/PromoCodeForm';

const PromoCode: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container className={styles.container}>
      <Flex justify="space-between" spacing={32}>
        <Flex spacing={20} direction="column" className={styles.form}>
          <Typography.Title level={3}>{t('promoCode.title')}</Typography.Title>
          <Typography.Text size={3} type="secondary">
            {t('promoCode.enterPromoCodeToGetOrExtendPremium')}
          </Typography.Text>
          <PromoCodeForm />
        </Flex>
        <Image className={styles.img} src={drone} alt="drone" />
      </Flex>
    </Container>
  );
};

export { PromoCode };
