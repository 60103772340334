import { $rtkApi } from 'shared/api/rtkApi';

export interface ChangePasswordValues {
    currentPassword: string;
    newPassword: string;
}

const changePasswordApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        changePassword: build.mutation<object, ChangePasswordValues>({
            query: (params) => ({
                url: '/api/user/pw_reset',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});

export const useChangePassword = changePasswordApi.useChangePasswordMutation;
