import { useFetchUserInfo } from 'entities/Connection';
import { getTariffType } from 'entities/Tariff';
import React from 'react';
import { CONTROL_IDS } from 'shared/consts';
import { useStoreUiControls } from 'shared/lib';

export const useFreeTariff = () => {
  const { open: openPlans } = useStoreUiControls(CONTROL_IDS.AVAILABLE_PLANS);

  const { data: userInfo } = useFetchUserInfo(null);

  React.useEffect(() => {
    if (!userInfo?.tariff) return;

    if (getTariffType(userInfo.tariff) === 'free') {
      openPlans();
    }
  }, [userInfo]);
};
