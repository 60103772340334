import { Device } from 'entities/Device';
import { Theme } from 'entities/Theme';
import macDark from 'shared/assets/images/devices/mac-dark.png';
import macLight from 'shared/assets/images/devices/mac-light.png';
import windowsDark from 'shared/assets/images/devices/windows-dark.png';
import windowsLight from 'shared/assets/images/devices/windows-light.png';
import iphoneDark from 'shared/assets/images/devices/iphone-dark.png';
import iphoneLight from 'shared/assets/images/devices/iphone-light.png';
import androidDark from 'shared/assets/images/devices/android-dark.png';
import adroidLight from 'shared/assets/images/devices/android-light.png';

const deviceImagesData: Record<Device, Record<Theme, string>> = {
  mac: { dark: macDark, light: macLight },
  windows: { dark: windowsDark, light: windowsLight },
  iphone: { dark: iphoneDark, light: iphoneLight },
  android: { dark: androidDark, light: adroidLight },
};

export const getDeviceImageByTheme = (device: Device, theme: Theme) => {
  return deviceImagesData[device][theme];
};
