import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Apple: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.6005 8.1816C18.4652 8.2896 16.075 9.6744 16.075 12.7536C16.075 16.3152 19.1164 17.5752 19.2074 17.6064C19.1934 17.6832 18.7242 19.332 17.6038 21.012C16.6048 22.4904 15.5615 23.9664 13.9742 23.9664C12.387 23.9664 11.9785 23.0184 10.1462 23.0184C8.36061 23.0184 7.72572 23.9976 6.27387 23.9976C4.82203 23.9976 3.80901 22.6296 2.64427 20.9496C1.29513 18.9768 0.205078 15.912 0.205078 13.0032C0.205078 8.3376 3.15545 5.8632 6.05913 5.8632C7.60201 5.8632 8.88812 6.9048 9.8568 6.9048C10.7788 6.9048 12.2166 5.8008 13.9719 5.8008C14.6371 5.8008 17.0273 5.8632 18.6005 8.1816ZM13.1386 3.8256C13.8645 2.94 14.3781 1.7112 14.3781 0.4824C14.3781 0.312 14.364 0.1392 14.3337 0C13.1526 0.0456 11.7475 0.8088 10.9002 1.8192C10.2349 2.5968 9.61405 3.8256 9.61405 5.0712C9.61405 5.2584 9.64439 5.4456 9.65839 5.5056C9.73309 5.52 9.85446 5.5368 9.97584 5.5368C11.0355 5.5368 12.3683 4.8072 13.1386 3.8256Z" />
    </svg>
  );
};

export { Apple };
