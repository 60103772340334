import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InfoEntry } from '../../model';
import { Button, Typography } from 'shared/_ui';

const Chevron = () => (
  <svg
    width="17"
    height="10"
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9L8.5 1L1 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface Props {
  topic: string;
  action?: InfoEntry['action'];
  defaultOpen?: boolean;
}

export const InfoCollapse = ({ defaultOpen, topic, action }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLSpanElement>(null);

  const [open, setIsOpen] = useState(defaultOpen);
  const { t, i18n } = useTranslation();

  const handleTitleClick = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!ref.current) return;

    if (open) {
      ref.current.style.height = `${ref.current?.scrollHeight}px`;
      return;
    }

    ref.current.style.height = '0px';
  }, [open]);

  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.open]: open,
      })}
    >
      <div onClick={handleTitleClick} className={styles.title}>
        {t(`howItWorks.${topic}.title`)}
        <div
          className={classNames({
            [styles.icon]: true,
            [styles.open]: open,
          })}
        >
          <Chevron />
        </div>
      </div>
      <div
        className={classNames({ [styles.content]: true, [styles.open]: open })}
        ref={ref}
      >
        <span ref={descriptionRef}>{t(`howItWorks.${topic}.description`)}</span>
        {i18n.exists(`howItWorks.${topic}.links`) &&
          t(`howItWorks.${topic}.links`)
            .split('||')
            .map((linkEntry, linkIndex) => {
              const [prefix, link] = linkEntry.split('--');

              return (
                <span key={linkIndex}>
                  {prefix} <Typography.Link href={link}>{link}</Typography.Link>
                </span>
              );
            })}
        {action && (
          <div className={styles.actionWrapper}>
            <Button type="primary" className={styles.action} onClick={action}>
              {t(`howItWorks.${topic}.action`)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
