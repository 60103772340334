export const translationRu = {
  shared: {
    error: {
      base: 'Произошла ошибка',
      invalidRequest: 'Недопустимое содержание запроса',
      required: 'Обязательное поле',
      email: 'Неправильный email',
      minSixLetter: 'Минимум 6 символов',
      copy: 'Произошла ошибка при копировании',
      data: 'Произошла ошибка при загрузке данных',
      video: 'Ваш браузер не поддерживает видео тег',
      timeSessionExpired: 'Время сессии истекло',
      download: 'Произошла ошибка при загрузке файла',
    },
    message: {
      copy: 'Скопировано',
    },
    text: {
      yes: 'Да',
      no: 'Нет',
      empty: 'Нет данных',
      upTo: 'До',
      checkConnectionAndTryAgain:
        'Проверьте интернет-соединение и попробуйте ещё раз',
      repeat: 'Повторить попытку',
      noData: 'Нет данных',
      continue: 'Продолжить',
      back: 'Назад',
      finish: 'Готово',
      ok: 'Ок',
    },
    plural: {
      days_many: '{{count}} дней',
      days_one: '{{count}} день',
      days_few: '{{count}} дня',
      months_many: '{{count}} месяцев',
      months_one: '{{count}} месяц',
      months_few: '{{count}} месяца',
    },
  },
  theme: {
    toggle: 'Сменить тему',
  },
  banner: {
    premiumExpiration: {
      title: 'Привет👋',
      subtitle:
        'Ненавязчиво предупреждаем, что ваша Премиум подписка закончится через 3 дня.',
      description: 'Продлите сейчас, чтобы не думать об этом дальше 👇',
      button: 'Продлить Премиум',
    },
    premiumExpiration_1: {
      title: 'Привет! ⏳ Завтра истекает срок твоей Premium подписки.',
      subtitle: `Представь, как много ты можешь потерять, вернувшись к бесплатной версии: Текущий VPN на технологии X-Ray, который невозможно заблокировать, перестанет работать.
        
        Продление сейчас гарантирует тебе продолжение безопасного и неограниченного доступа к интернету. Сделай выбор в пользу своей удобности и безопасности.
        `,
      description: 'Продли прямо сейчас, не жди до последнего момента! 👇',
      button: 'Продлить Премиум',
    },
    premiumExpiration_9: {
      title: `Привет👋 Помнишь,
что мы заботливо
подарили тебе
14 дней Premium
с самого старта?`,
      subtitle: `После окончания пробного периода ты можешь продолжить пользоваться VPN бесплатно, но для этого нужно будет установить наш альтернативный VPN (без приложений). Протокол X-Ray VPN (через приложение) доступен только на Premium.
        
Если тебя устраивает текущая работа нашего VPN, рекомендуем оплатить сейчас и не думать об этом в самый неподходящий момент.
        `,
      description:
        'Чтобы не ломать голову и продолжать включать VPN одной кнопкой, оплати подписку сейчас 👇',
      button: 'Продлить Премиум',
    },
    premiumExpired: {
      title: `😿 Твой тариф поменялся 
на бесплатный`,
      subtitle: `Если у тебя был установлен VPN через приложение (технология X-Ray), то он перестал работать. Чтобы продолжить пользоваться VPN через приложение нужно вернуться на Премиум.
      
      Для бесплатного доступа тебе нужно установить наш альтернативный VPN протокол без приложений в настройках твоего устройства. Сделать это можно через "Профиль".
      `,
      description:
        'Чтобы вернуться на Премиум тариф и продолжить пользоваться VPN без головной боли, нажми "Купить VPN" 👇',
      button: 'Продлить Премиум',
    },
    premiumExpired_7: {
      title: `Перестал работать VPN?`,
      subtitle: `Это потомучто у тебя бесплатный тариф с ограничением по скорости. Для бесплатного доступа тебе нужно установить наш альтернативный VPN протокол без приложений. Сделать это можно через "Профиль".`,
      description:
        'Чтобы "вернуть все как было" и продолжить пользоваться неограниченного доступом к интернету, продли подписку сейчас 👇',
      button: 'Купить Премиум',
    },
    speedLimit: {
      title: `Перестал 
      работать VPN?`,
      subtitle: `Это потому что у тебя бесплатный тариф с ограничением по скорости. Для бесплатного доступа тебе нужно установить наш альтернативный VPN протокол без приложений. Сделать это можно через "Профиль".`,
      description:
        'Чтобы "вернуть все как было" и продолжить пользоваться неограниченного доступом к интернету, продли подписку сейчас 👇',
      button: 'Купить Премиум',
    },
    successfulPayment: {
      heading: 'Спасибо что выбрали нас! Оплата прошла успешно',
      hint: 'Чтобы вернуться на главную страницу нажмите на кнопку:',
      button: 'На главную',
    },
  },
  society: {
    facebook: 'https://www.facebook.com/groups/privategatevpn',
    telegram: 'https://t.me/privategatevpn_ru',
    vk: 'https://vk.com/privategate_vpn',
    twitter: 'https://twitter.com/privategate_vpn',
    youtube: 'https://youtube.com/@PrivateGateEN?si=ovsYcKMzX25r-fSE',
  },
  links: {
    offer: 'https://privategatevpn.com/offerru',
    privacyPolicy: 'https://privategatevpn.com/privacypolicyru',
  },
  navigation: {
    faq: 'FAQ',
    howToInstall: 'Как установить',
    usefulLinks: 'Полезные ссылки',
    howItWorks: 'Как это работает',
    changePassword: 'Сменить пароль',
    enterPromoCode: 'Ввести промокод',
    bringFriend: 'Приведи друга',
    support: 'Поддержка',
    logout: 'Выйти',
  },
  footer: {
    offer: 'Оферта',
    privacyPolicy: 'Политика конфиденциальности',
  },
  howItWorks: {
    howItWorks: {
      title: 'Как это работает',
      description: `В Private Gate VPN есть два VPN протокола и 2 метода установки:
  
  1) Через приложение (РЕКОМЕНДУЕМ).
  
  Технология X-Ray (VLESS) для регионов с высоким уровнем цензуры. Новейший VPN протокол, который маскирует ваш трафик в интернете.
  
  ✅ Невозможно заблокировать
  ✅ Работает всегда и везде
  ✅ Простая и быстрая установка
  ✅ Стабильный
  ✅ Работает на любых устройствах
  
  2) Без приложений (для продвинутых).
  
  Технология IKEv2 для регионов с низким уровнем цензуры. Подходит для продвинутых пользователей, любящих полный контроль.
  
  ✅ Установка без приложений
  ✅ Работает на любых устройствах
  ✅ Стабильный
  ✅ Быстрый`,
    },
    gift: {
      title: '14 дней Premium в подарок',
      action: 'Перейти на Premium',
      description: `Мы заботливо дарим всем 14 дней Premium с самого старта.

После окончания пробного периода ты можешь продолжить пользоваться VPN бесплатно, но для этого нужно будет установить наш альтернативный VPN (без приложений). 
Протокол X-Ray VPN (через приложение) доступен только на Premium.

Если тебя устраивает текущая работа нашего VPN, рекомендуем оплатить сейчас и не думать об этом в самый неподходящий момент. 
Чтобы не ломать голову и продолжать включать VPN одной кнопкой, оплати подписку сейчас: 
`,
    },
    free: {
      title: 'Бесплатный VPN',
      action: 'Купить VPN',
      description: `Если у тебя был установлен VPN через приложение (технология X-Ray), то он перестал работать. Чтобы продолжить пользоваться VPN через приложение нужно вернуться на Премиум.

      Для бесплатного доступа тебе нужно установить наш альтернативный VPN протокол без приложений в настройках твоего устройства. Сделать это можно через создание нового подключения.

      Чтобы вернуться на Премиум тариф и продолжить пользоваться VPN без головной боли, нажми "Купить VPN" 👇
`,
    },
    bot: {
      title: 'Наш телеграм бот',
      links: `Запускай бота по ссылке:--https://t.me/PrivateGateVPNbot`,
      description: `Неудобно пользоваться через браузер? У нас есть удобный бот в Telegram, через который также можно получить VPN. 
      
      `,
    },
    chat: {
      title: 'Наш телеграм канал и чат',
      links: `📣 Канал--https://t.me/privategatevpn_ru ||
💬 Чат--https://t.me/privategatevpn_chat_ru `,
      description: `Давай всегда оставаться на связи 🤙

📣 Подпишись на наш Телеграм канал, где мы делимся уникальными предложениями, акциями, новостями и лайфхаками для безопасного использования интернета.

💪 Присоединяйся сейчас и начни получать эксклюзивные предложения и пользу уже сегодня!

Мы ждем тебя💚
`,
    },
    feedback: {
      title: 'Оставить обратную связь',
      links: `👉 Все, что нужно сделать, — это пройти 2-минутный опрос по ссылке--https://forms.gle/PBgYpydECWGkrzSH8 `,
      description: `Если у тебя есть идеи, как мы можем улучшить твое приключение с Private Gate VPN, пожалуйста, поделись ими. 
Мы ценим твое мнение и готовы помочь с любыми вопросами. 

`,
    },
  },
  authentication: {
    notify: {
      successRegister: 'Аккаунт успешно создан',
      passwordResetLinkSent: 'Ссылка для смены пароля отправлена на почту',
      invitationAlreadySent:
        'На указанный адрес электронной почты уже отправлено приглашение',
      userNotFound: 'Пользователь не найден',
      wrongCaptcha: 'Неверно введен код с картинки',
      passwordChanged: 'Пароль успешно изменен',
      alreadySend:
        'На указанный адрес электронной почты уже отправлено приглашение',
    },
    login: {
      title: 'Логин',
      noAccountYet: 'Ещё нет аккаунта?',
      register: 'Зарегистрируйтесь',
      error: 'Неверный логин или пароль',
      password: 'Пароль',
      loginButton: 'Войти',
    },
    registration: {
      title: 'Регистрация',
      agreement: 'Регистрируясь, вы соглашаетесь со следующими документами:',
      and: 'и',
      offerAgreement: 'Оферта',
      privacyPolicy: 'Политика конфиденциальности',
      alreadyHaveAccount: 'Уже есть аккаунт?',
      login: 'Войти',
      next: 'Далее',
      email: 'Email',
    },
    password: {
      title: 'Пароль',
      new: 'Новый пароль',
      current: 'Текущий пароль',
      create: 'Создайте пароль',
      change: 'Смена пароля',
      restore: 'Восстановление пароля',
      reset: 'Сброс пароля',
      forgot: 'Забыли пароль?',
      enter: 'Введите пароль ',
      createButton: 'Cоздать пароль',
      changeButton: 'Сменить пароль',
      alreadyHavePassword: 'Уже есть пароль?',
      passShouldNotMatch: 'Пароли не должны совпадать',
      wrong: 'Неверный пароль',
    },
    mail: {
      confirmationLinkSent: 'Ссылка с подтверждением отправлена вам на почту',
      description:
        'Для завершения регистрации зайдите на почту и перейдите по ссылке.',
      checkSpam: 'Если не видите письма – проверьте в папке «cпам».',
      didNotReceiveEmail: 'Не получили письмо?',
      sendAgain: 'Отправить еще раз',
      sendCooldown:
        'Приглашение на указанный адрес электронной почты уже отправлено. Пожалуйста, повторите попытку позже.',
      countdown: 'Следующая попытка доступна через ',
    },
    restore: {
      send: 'Отправить',
      email: 'Email',
      instructionLinkSent:
        'Инструкция по сбросу пароля отправлена вам на почту',
      description:
        'Для завершения сброса пароля зайдите на почту и следуйте инструкции.',
      captcha: 'Код с картинки',
      enterCaptcha: 'Введите символы с изображения',
      reloadCaptcha: 'Обновить',
    },
  },
  instruction: {
    step: 'Шаг',
    final: 'Финал',
    image: 'Инструкция',
  },
  provider: {
    name: {
      rf: 'Банковская карта (РФ)',
      cloud: 'Банковская карта (кроме РФ)',
      crypto: 'Оплата криптовалютой',
      payze: 'Банковская карта (кроме РФ)',
      ruCloudPayments: 'Банковская карта (РФ)',
    },
  },
  connection: {
    full: 'Невозможно создать новое подключение. Достигнуто максимальное количество соединений. Удалите одно из имеющихся соединений, чтобы создать новое',
    error: {
      create: 'Произошла ошибка при создании подключения',
    },
    actions: 'Действия',
    downloadConfig: 'Скачать VPN конфигурацию',
    whichDevice:
      'На какое устройство вы хотите загрузить файл конфигурации VPN?',
    howToInstall: 'Как установить',
    changeDevice: 'Сменить устройство',
    delete: 'Удалить',
    confirm: {
      changeDevice: 'Уверены, что хотите сменить устройство?',
      delete: 'Уверены, что хотите удалить подключение?',
    },
    notify: {
      successDeleted: 'Подключение успешно удалено',
    },
    list: 'Ваши устройства и подключения',
    add: 'добавить устройство',
    addNewDevice: 'Добавить новое устройство',
    cancelAdding: 'Закрыть',
    name: 'Private Gate',
    server: 'Сервер подключения',
    login: 'Имя пользователя',
    password: 'Пароль',
    mac: {
      title: {
        name: 'Отображаемое имя',
        server: 'Адрес сервера',
        remote: 'Удаленный ID',
        user: 'Имя пользователя',
        password: 'Пароль',
      },
    },
    windows: {
      title: {
        provider: 'Поставщик услуг VPN',
        name: 'Имя подключения',
        server: 'Имя или адрес сервера',
        vpnType: 'Тип VPN',
        dataType: 'Тип данных для входа',
        user: 'Имя пользователя',
        password: 'Пароль',
      },
      value: {
        provider: 'Windows (встроенные)',
        vpnType: 'IKEv2',
        dataType: 'Имя пользователя и пароль',
      },
    },
    iphone: {
      title: {
        description: 'Описание',
        server: 'Сервер',
        remote: 'Удаленный ID',
        user: 'Имя пользователя',
        password: 'Пароль',
      },
    },
    android: {
      title: {
        server: 'Сервер',
        user: 'Логин',
        password: 'Пароль',
      },
    },
  },
  country: {
    selectCountry: 'Выберите страну для подключения',
    selectCountryDescription:
      'Страна к которой будет подключено ваше устройство, в дальнейшем сможете сменить:',
    name: {
      empty: '🏳️ Не выбрана',
      kz: '🇰🇿 Казахстан',
      de: '🇩🇪 Германия',
      us: '🇺🇸 США',
      ru: '🇷🇺 Россия',
      nl: '🇳🇱 Нидерланды',
      sg: '🇸🇬 Сингапур',
      fr: '🇫🇷 Франция',
      gb: '🇬🇧 Великобритания',
      ae: '🇦🇪 Арабские Эмираты',
    },
    confirm: {
      change: 'Уверены, что хотите сменить страну?',
    },
    notify: {
      successChanged: 'Страна успешно изменена',
    },
  },
  payment: {
    title: 'Оплата',
    paymentMethod: 'Метод оплаты',
    selectPaymentMethod: 'Выберите метод оплаты',
    pay: 'Оплатить',
    disclaimer:
      'Валюта списания может отличаться, но общая фактическая сумма от этого не меняется. Чтобы завершить платеж, нажмите на кнопку "Оплатить"',
  },
  tariff: {
    name: {
      free: 'Бесплатный',
      freePremium: 'Бесплатная премиум версия',
      premium: 'Премиум',
    },
    bandwidth: {
      bytes: 'Бит/c',
      kbytes: 'Кбит/c',
      mbytes: 'Мбит/с',
    },
    params: {
      remaining: 'Осталось',
      speed: 'Скорость',
      deviceCount: 'Кол-во устройств',
      discount: 'Скидка',
      monthly: 'Ежемесячно',
      free: 'Бесплатно',
      paidTo: 'Тариф оплачен до',
    },
    activateFor: 'Подключить Премиум за',
    renewFor: 'Продлить Премиум за',
    renew: 'Продлить Премиум',
    change: 'Сменить тариф',
    connectFree: 'Подключить бесплатно',
    popularChoice: 'Популярный выбор',
    beneficial: 'Выгодно!',
    availableList: 'Доступные планы',
    current: 'Выбранный тариф',
  },
  currency: {
    usd: '$',
    rub: '₽',
  },
  promoCode: {
    title: 'Промокод',
    enter: 'Введите промокод',
    enterPromoCodeToGetOrExtendPremium:
      'Получите или продлите Премиум тариф, введя промокод',
    activateButton: 'Активировать',
    yourPromoCode: 'Ваш промокод',
    confirmButton: 'Подтвердить',
    have: 'Есть промокод?',
    apply: 'Подтвердить',
    error:
      'Не верный промокод или он уже был активирован.Попробуйте ввести еще раз',
    success: 'Ура, ваш промокод активирован!',
  },
  installation: {
    app: {
      label: 'Через приложение X-Ray',
      time: 'Менее минуты',
      description:
        'Технология X-Ray (VLESS) для регионов с высоким уровнем цензуры. Новейший VPN протокол, который маскирует ваш трафик в интернете.',
      benefits:
        'Невозможно заблокировать*Работает всегда и везде*Простая и быстрая установка*Стабильный*Работает на любых устройствах',

      freeDescription: `Протокол X-Ray VPN (через приложение) доступен только на Premium тарифе.

Чтобы продолжить пользоваться VPN, который невозможно заблокировать, нужно оплатить перейти на  Premium тариф.
`,
      recommended: 'Рекомендовано',
    },
    manual: {
      label: 'Ручная Настройка',
      time: '≈ 4 минуты, но зато надежно',
      description:
        'Без приложений. Приложения могут отслеживать ваши действия и передавать данные рекламодателям и другим третьим лицам.\n\nУстановка VPN в ручном режиме  прямо на свое устройство без сторонних приложений.',
    },
    config: {
      label: 'Установка VPN конфигурации',
      time: '≈ 2 минуты и свобода ваша',
      description:
        'Без приложений. Приложения могут отслеживать ваши действия и отправлять данные рекламодателям и другим третьим лицам. Мы предоставляем чистую конфигурацию VPN, которую вы можете установить самостоятельно прямо на свое устройство без сторонних приложений.',
    },
    select: 'Выберите метод установки',
    description:
      'Каждый из них подходит для разных предпочтений: от простоты до полного контроля.',
    finish: 'Поздравляем!\nВы успешно установили Private Gate VPN!',
  },
  device: {
    select: 'Выберите ваше устройство',
    selectDeviceToProtect:
      'Начните безопасный интернет-серфинг, выбрав устройство для защиты',
  },
  xray: {
    remaining: 'Осталось: ',
    connection: 'Приложение X-Ray',
    duplicateConnection:
      'У тебя уже создан ключ-ссылка для  выбранной страны. Используй ключ выданный ранее!',
    createError: 'При создании подключения произошла ошибка',
    countryChangeHint:
      '⚠️ При смене страны текущее соединение перестанет работать. На Премиум тарифе ты можешь создать 3 подключения c разными странами выхода и использовать их одновременно на разных устройствах. Ты точно хочешь изменить страну выхода на текущем соединении?',
  },
  'non-app': {
    connection: 'Без приложений',
  },
  guide: {
    app: {
      title: 'Следуйте инструкциям метода “Приложение”',
      foxray: 'Скачать приложение FoXray',
      streisand: 'Скачать приложение Streisand',
      hiddifynext: 'Скачать приложение Hiddify-Next',
      rayn: 'Скачать приложение 2RayN',
      linkKey: 'Ключ ссылка',
    },
    manual: { title: 'Следуйте инструкциям метода “Ручная установка”' },
    config: {
      title: 'Следуйте инструкциям метода “Через Конфигурации”',
      download: 'Скачать файл VPN конфигурации',
      downloadApp: 'Скачать приложение',
    },
    description: 'Используй видео инструкцию',
    stepByStep: 'Или пошаговую инструкцию',
    mac: {
      app: {
        step: {
          1: {
            title: 'Устанавливаем приложение',
            description: {
              1: 'Из списка устройств выбираем «MacOs»',
              2: 'Переходим в App Store',
              3: 'Устанавливаем приложение FoXray',
            },
          },
          2: {
            title: 'Открываем приложение',
            description: {
              1: 'Открываем установленное приложение',
              2: 'Соглашаемся с правилами политики конфиденциальности (кнопка “Agree” в правом нижнем углу)',
            },
          },
          3: {
            title: 'Копируем VPN ключ',
            description: {
              1: 'Копируем ссылку-ключ',
            },
          },
          4: {
            title: 'Вставляем ключ',
            description: {
              1: 'Откройте установленное приложение',
              2: 'В правой верхней части экрана нажмите на иконку папки-планшета',
              3: 'При необходимости нажмите “Добавить из буфера обмена” и “Разрешить вставку”',
              4: 'VPN ключ импортирован!',
            },
          },
          5: {
            title: 'Запускаем VPN',
            description: {
              1: 'Нажимаем на кнопку запуска напротив импортированного ключа (не важно первого или второго)',
              2: 'Поздравляем, VPN запущен!',
              3: 'Безопасного интернет-серфинга с Private Gate',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Скачайте файл конфигурации',
            description: {
              1: `Нажмите на файл VPN конфигурации и скачайте его`,
              2: 'Нажмите “Показать в Finder”',
            },
          },
          2: {
            title: 'Установите VPN конфиг',
            description: {
              1: `В “Finder” запустите файл VPN конфигурации (обычно файл находится в загрузках в самом верху)`,
              2: 'В правом углу экрана появится уведомление “Установка профиля”',
            },
          },
          3: {
            title: 'Входим в профили',
            description: {
              1: `Перейдите в “Системные настройки” (в левом верхнем углу экрана)`,
              2: 'В поле поиска напишите “профили”',
              3: 'В правой колонке пролистайте вниз и нажмите на “Профили”',
            },
          },
          4: {
            title: 'Нажмите на “Private Gate”',
            description: {
              1: `Нажмите на профиль “Private Gate”`,
            },
          },
          5: {
            title: 'Нажмите “Установить”',
          },
          6: {
            title: 'Нажмите “Установить”',
          },
          7: {
            title: 'Включить отображение VPN',
            description: {
              1: `Перейдите в “Системные настройки” (значок яблока)`,
              2: 'Нажмите на “Пункт управления” (в середине меню)',
              3: 'В правой части меню внизу найдите “VPN”',
              4: 'В пункте “VPN” поменяйте настройку на “Показывать в строке меню”',
            },
          },
          8: {
            title: 'Включайте VPN Легко',
            description: {
              1: `В правом верхнем углу экрана (где часы) нажмите на иконку VPN (пять полосок)`,
              2: 'Нажмите “Подключить Private Gate” / “Отключить Private Gate”',
              3: 'Безопасного интернет-серфинга с Private Gate!',
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Входим в “системные настройки”',
            description: {
              1: 'В левом верхнем углу нажимаем на иконку Apple',
              2: 'Выбираем "Системные настройки"',
            },
          },
          2: {
            title: 'Добавляем конфигурацию VPN',
            description: {
              1: 'В левой части меню выбираем “Сеть”',
              2: 'В правой части меню нажимаем на иконку с тремя точками',
              3: 'Выбираем "Добавить конфигурацию VPN" - “IKEv2”',
            },
          },
          3: {
            title: 'Копируйте и вставляйте данные',
            description: {
              1: 'Перейдите в бота (или на сайт)',
              2: 'Копируйте и вставляйте данные в соответствующие поля:',
              3: 'Аутентификация-Имя пользователя',
              4: 'Нажмите “Создать”',
            },
          },
          4: {
            title: 'Включить отображение VPN',
            description: {
              1: 'Перейдите в “Системные настройки” (значок яблока)',
              2: 'Нажмите на “Пункт управления” (в середине меню)',
              3: 'В правой части меню внизу найдите “VPN”',
              4: 'В пункте “VPN” поменяйте настройку на “Показывать в строке меню”',
            },
          },
          5: {
            title: 'Включайте VPN Легко',
            description: {
              1: `В правом верхнем углу экрана (где часы) нажмите на иконку VPN (пять полосок)`,
              2: 'Нажмите “Подключить Private Gate” / “Отключить Private Gate”',
              3: 'Безопасного интернет-серфинга с Private Gate!',
            },
          },
        },
      },
    },
    windows: {
      app: {
        step: {
          1: {
            title: 'Скачиваем программу',
            description: {
              1: 'Из списка устройств выбираем «Windows»',
              2: 'Подтверждаем открытие ссылки. Скачивание начинается',
            },
          },
          2: {
            title: 'Запускаем загруженный файл',
            description: {
              1: 'В открывшемся браузере нажмите на кнопку «последний загруженный файл»',
              2: 'Нажимаем на последний скачанный файл. Это будет архив с программой',
            },
          },
          3: {
            title: 'Извлекаем архив',
            description: {
              1: 'В открывшемся архиваторе файлов нажимаем копку «Извлечь»',
            },
          },
          4: {
            title: 'Сохраняем программу',
            description: {
              1: 'Выбираем путь сохранения файлов программы',
              2: 'После выбора пути сохранения в конце обязательно пишите «\\hiddify»',
              3: 'Лучше выбрать путь сохранения: «C:\\Program Files\\hiddify»',
            },
          },
          5: {
            title: 'Создаем ярлык',
            description: {
              1: 'Заходим в проводник и входим в папку, куда сохраняли программу',
              2: 'Нажимаем на файл «HiddifyN.exe» правой клавишей',
              3: 'Выбираем: «Отправить» - «На рабочий стол»',
              4: 'Ярлык программы создан на рабочем столе',
            },
          },
          6: {
            title: 'Запускаем программу',
            description: {
              1: 'На рабочем столе запускаем «HiddifyN» от имени администратора',
              2: 'Для этого нажимаем на ярлыке «HiddifyN» правой клавишей',
              3: 'Нажимаем на «Запустить от имени Администратора»',
            },
          },
          7: {
            title: 'Копируем VPN ключ',
            description: {
              1: 'Копируем ссылку-ключ',
            },
          },
          8: {
            title: 'Вставляем ключ',
            description: {
              1: 'Возвращаемся в программу «Hiddify». Для этого нажимаем на свернутую иконку программы внизу',
              2: 'В программе «Hiddify» в правом верхнем углу нажимаем на «Import» (иконка с «+»)',
              3: 'Поздравляем, ключ импортирован!',
            },
          },
          9: {
            title: 'Запускаем VPN',
            description: {
              1: 'Нажимаем на большую иконку в центре программы',
              2: 'Безопасного интернет-серфинга с Private Gate!',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Пуск – Параметры',
            description: {
              1: `Перейдите в “Пуск” - “Параметры” или нажмите на клавиатуре "Win + I"`,
            },
          },
          2: {
            title: 'Cеть и интернет',
            description: {
              1: 'Перейдите в раздел “Сеть и Интернет”',
            },
          },
          3: {
            title: 'Добавить VPN',
            description: {
              1: `Выберите “VPN” (в левом меню) Нажмите на “Добавить VPN-подключение” (в правой части)`,
              2: 'Нажмите на “Добавить VPN-подключение” (в правой части)',
            },
          },
          4: {
            title: 'Копируйте и вставляйте',
            description: {
              1: 'Дальше нужно просто копировать и вставлять данные, полученные в боте (или на сайте) в соответствующие поля в Параметрах',
            },
          },
          5: {
            title: 'Включайте VPN Легко',
            description: {
              1: `Включать и выключать VPN вы можете одной кнопкой:
              1.  Нажмите на иконку соединения в правом нижнем углу (где часы) 
              2.  Подключитесь к VPN

              Удачного интернет-серфинга с Private Gate!`,
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Пуск – Параметры',
            description: {
              1: `Перейдите в “Пуск” - “Параметры” или нажмите на клавиатуре "Win + I"`,
            },
          },
          2: {
            title: 'Cеть и интернет',
            description: {
              1: 'Перейдите в раздел “Сеть и Интернет”',
            },
          },
          3: {
            title: 'Добавить VPN',
            description: {
              1: `Выберите “VPN” (в левом меню) Нажмите на “Добавить VPN-подключение” (в правой части)`,
              2: 'Нажмите на “Добавить VPN-подключение” (в правой части)',
            },
          },
          4: {
            title: 'Копируйте и вставляйте',
            description: {
              1: 'Дальше нужно просто копировать и вставлять данные, полученные в боте (или на сайте) в соответствующие поля в Параметрах',
            },
          },
          5: {
            title: 'Включайте VPN Легко',
            description: {
              1: `Включать и выключать VPN вы можете одной кнопкой:  
              1.  Нажмите на иконку соединения в правом нижнем углу (где часы) 
              2.  Подключитесь к VPN`,
              2: 'Удачного интернет-серфинга с Private Gate!',
            },
          },
        },
      },
    },
    iphone: {
      app: {
        step: {
          1: {
            title: 'Устанавливаем приложение',
            description: {
              1: 'Из списка устройств выбираем «iOS»',
              2: 'Переходим в App Store',
              3: 'Устанавливаем приложение Streisand',
            },
          },
          2: {
            title: 'Открываем приложение',
            description: {
              1: 'Открываем установленное приложение',
            },
          },
          3: {
            title: 'Копируем VPN ключ',
            description: {
              1: 'Копируем ссылку-ключ',
            },
          },
          4: {
            title: 'Вставляем ключ',
            description: {
              1: 'Откройте установленное приложение',
              2: 'В верхней части экрана нажмите на +плюс',
              3: 'Нажмите “Добавить из буфера обмена” и “Разрешить вставку”',
              4: 'VPN ключ импортирован!',
            },
          },
          5: {
            title: 'Запускаем VPN',
            description: {
              1: 'Нажимаем на кнопку запуска',
              2: 'Поздравляем, VPN запущен!',
              3: 'Безопасного интернет-серфинга с Private Gate!',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Нажмите на файл',
            description: {
              1: `Нажмите на файл VPN конфигурации Private Gate, который был получен в боте (или на сайте)`,
            },
          },
          2: {
            title: 'Сохраните файл',
            description: {
              1: `В левом нижнем углу экрана нажмите на иконку “поделиться файлом” (квадрат со стрелкой вверх)`,
            },
          },
          3: {
            title: 'Сохраните в файлы',
            description: {
              1: `Пролистайте вниз и выберите в меню пункт “Сохранить в Файлы”`,
            },
          },
          4: {
            title: 'Сохранить',
            description: {
              1: `Сохраните файл VPN конфигурации Private Gate на iPhone или в iCloud Drive`,
            },
          },
          5: {
            title: 'Нажмите готово',
            description: {
              1: `В правом верхнем углу экрана нажмите “Готово”`,
              2: `Перейдите на главный экран iPhone`,
            },
          },
          6: {
            title: 'Перейдите в файлы',
            description: {
              1: `На главном экране iPhone свайпните вниз`,
              2: `В строке поиска (внизу) напишите “файлы”`,
              3: `Перейдите в “Файлы” (в верхней части экрана)`,
            },
          },
          7: {
            title: 'Установите VPN конфиг',
            description: {
              1: `Перейдите в место сохранения конфига (память iPhone или iCloud Drive)`,
              2: `Нажмите на профиль “Private Gate” (файл находится вверху списка)`,
            },
          },
          8: {
            title: 'Профиль загружен',
            description: {
              1: `Вы получите уведомление об успешной загрузке профиля VPN`,
            },
          },
          9: {
            title: 'Входим в профили',
            description: {
              1: `Переходим в Настройки`,
              2: `Нажимаем на “Профиль загружен” (вверху экрана)`,
            },
          },
          10: {
            title: 'Нажмите “Установить”',
          },
          11: {
            title: 'Нажмите “Установить”',
          },
          12: {
            title: 'Профиль установлен',
            description: {
              1: `В правой верхней части экрана нажмите “Готово”`,
              2: `Вернитесь в Настройки`,
            },
          },
          13: {
            title: 'Включайте VPN Легко',
            description: {
              1: `Перейдите в Настройки`,
              2: `Нажмите на “VPN” чтобы Подключить / Отключить VPN`,
              3: `Безопасного интернет-серфинга с Private Gate!`,
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Настройки',
            description: {
              1: `Войдите в Настройки (иконка шестеренки ⚙️)`,
            },
          },
          2: {
            title: 'Строка поиска',
            description: {
              1: `В Настройках нажмите на строку поиска (в верхней части экрана)`,
              2: `В строке поиска напишите - “vpn”`,
              3: `Нажмите на “VPN и управление устройством”`,
            },
          },
          3: {
            title: 'Выберите “VPN”',
            description: {
              1: `Нажмите на “VPN” (в верхней части экрана)`,
            },
          },
          4: {
            title: 'Добавить Конфиг',
            description: {
              1: `Нажмите на “Добавить конфигурацию VPN”`,
            },
          },
          5: {
            title: 'Перейдите в бота или на сайт',
            description: {
              1: `Перейдите в бота (или на сайт)`,
              2: `Копируйте и вставляйте данные в соответствующие поля`,
            },
          },
          6: {
            title: 'Копируйте и вставляйте',
            description: {
              1: `Копируйте и вставляйте данные в соответствующие поля:`,
              2: `Нажмите “Готово”`,
            },
          },
          7: {
            title: 'Включайте VPN Легко',
            description: {
              1: `Перейдите в Настройки`,
              2: `Нажмите на “VPN”`,
              3: `Нажмите на “Не подключен” 
              Безопасного интернет-серфинга с Private Gate!`,
            },
          },
        },
      },
    },
    android: {
      app: {
        step: {
          1: {
            title: 'Устанавливаем приложение',
            description: {
              1: 'Из списка устройств выбираем «Android»',
              2: 'Переходим в Google Play',
              3: 'Устанавливаем приложение Hiddify',
            },
          },
          2: {
            title: 'Открываем приложение',
            description: {
              1: 'Открываем установленное приложение',
              2: 'Устанавливаем язык, выбираем регион (Россия или Другие), оставляем или выключаем сбор аналитики',
              3: 'Нажимаем Далее',
            },
          },
          3: {
            title: 'Копируем VPN ключ',
            description: {
              1: 'Копируем ссылку-ключ',
            },
          },
          4: {
            title: 'Вставляем ключ',
            description: {
              1: 'Откройте установленное приложение',
              2: 'В верхней части экрана нажмите на +плюс',
              3: 'Нажмите “Добавить из буфера обмена”',
              4: 'Разрешаем уведомления',
            },
          },
          5: {
            title: 'Даем разрешения',
            description: {
              1: 'Разрешаем запрос на подключение',
              2: 'VPN ключ импортирован!',
            },
          },
          6: {
            title: 'Запускаем VPN',
            description: {
              1: 'Нажимаем на кнопку запуска',
              2: 'Поздравляем, VPN запущен!',
              3: 'Безопасного интернет-серфинга с Private Gate!',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Перейдите в google play',
            description: {
              1: `Вы можете установить Private Gate VPN на Android при помощи приложения strongSwan. Это безопасное приложение, которое не передает данные и находится в открытом доступе.

              1. Перейдите по ссылке в <0>Google Play</0>
              2. Для пользователей <1>Huawei</1>`,
            },
          },
          2: {
            title: 'Установите strongswan',
            description: {
              1: `Скачайте и установите (в Google Play) приложение strongSwan VPN `,
            },
          },
          3: {
            title: 'Нажмите на файл',
            description: {
              1: `Нажмите на файл VPN конфигурации Private Gate, который был получен в боте (или на сайте) `,
            },
          },
          4: {
            title: 'Cохраните файл',
            description: {
              1: `Для сохранения файла VPN конфигурации выберите “Chrome” или браузер, который вы используете`,
            },
          },
          5: {
            title: 'Перейдите в strongswan',
            description: {
              1: `Перейдите в приложение strongSwan`,
              2: `В правом верхнем углу экрана нажмите “три точки”`,
              3: `Нажмите “Import VPN profile”`,
            },
          },
          6: {
            title: 'Найдите конфиг',
            description: {
              1: `В верхнем углу экрана пролистайте вправо`,
              2: `Нажмите на “На этой неделе”`,
              3: `Сортировка успешно выполнена`,
            },
          },
          7: {
            title: 'Выберите конфиг',
            description: {
              1: `Нажмите на файл VPN конфигурации Private Gate Файл будет вверху списка (формат файла “user2023110121035312413.profile”) `,
            },
          },
          8: {
            title: 'Последний шаг',
            description: {
              1: `Вам остается только ввести пароль`,
              2: `Вернитесь в Telegram-бот (или на сайт)`,
            },
          },
          9: {
            title: 'Скопируйте пароль',
            description: {
              1: `Скопируйте пароль, который вы получили в Telegram-боте (или на сайте)`,
            },
          },
          10: {
            title: 'Вставьте пароль',
            description: {
              1: `Вставьте скопированный пароль`,
              2: `Нажмите “Import” (в правом верхнем углу экрана)`,
            },
          },
          11: {
            title: 'Запустите VPN',
            description: {
              1: `Нажмите на “Private Gate VPN”`,
            },
          },
          12: {
            title: 'Нажмите “ОК”',
          },
          13: {
            title: 'Нажмите “ОК”',
          },
          14: {
            title: 'Нажмите “Разрешить”',
          },
          15: {
            title: 'VPN подключен',
            description: {
              1: `Поздравляем! Вы подключились к VPN! Безопасного интернет-серфинга с Private Gate!`,
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Настройки',
            description: {
              1: `Вы можете установить Private Gate VPN на Android при помощи приложения strongSwan Это безопасное приложение, которое не передает данные и находится в открытом доступе 

              1. Перейдите по ссылке в <0>Google Play</0>
              2. Для пользователей <1>Huawei</1>`,
            },
          },
          2: {
            title: 'Установите strongswan',
            description: {
              1: `Скачайте и установите (в Google Play) приложение strongSwan VPN `,
            },
          },
          3: {
            title: 'Перейдите в strongswan',
            description: {
              1: `Перейдите в приложение strongSwan`,
              2: `В правом верхнем углу экрана нажмите “ДОБАВИТЬ VPN ПРОФИЛЬ”`,
            },
          },
          4: {
            title: 'Перейдите в бота или на сайт',
            description: {
              1: `Вернитесь в Telegram-бот (или на сайт)`,
              2: `Копируйте и вставляйте данные в соответствующие поля`,
            },
          },
          5: {
            title: 'Копируйте и вставляйте',
            description: {
              1: `Копируйте и вставляйте данные в соответствующие поля:`,
              2: `Нажмите “Сохранить” (в правом верхнем углу)`,
            },
          },
          6: {
            title: 'Включайте VPN Легко',
            description: {
              1: `1. Нажмите на Private Gate VPN
              2. Поздравляем! Вы подключились к VPN!
              Безопасного интернет-серфинга с Private Gate!`,
            },
          },
        },
      },
    },
    success: {
      mac: 'Для Mac (macOS)',
      windows: 'Для PC (Windows)',
      iphone: 'Для IPhone (IOS)',
      android: 'Для Android',
    },
    gift: {
      title: 'Ура, вам был активирован Премиум тариф на 7 дней!',
      subtitle: 'Это подарок от Private Gate VPN',
      description:
        'Дальше вы сможете пользоваться бесплатным тарифом с ограничением по скорости или вернуться к преимуществам Премиум подписки',
      thanks: 'Спасибо!',
    },
  },
};
