import { FC, memo } from 'react';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import { Button, Stack, Typography } from 'shared/ui';

import cls from './PageError.module.scss';

interface PageErrorProps {
    className?: string;
}

export const PageError: FC<PageErrorProps> = memo(({ className }) => {
    const { t } = useTranslation();
    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    return (
        <Stack
            max
            direction="column"
            align="center"
            justify="center"
            spacing={15}
            className={classNames(cls.PageError, className)}
        >
            <Typography size="size_l">{t('errorRequest')}</Typography>
            <Button theme="background" onClick={reloadPage}>
                {t('reload')}
            </Button>
        </Stack>
    );
});
