import * as yup from 'yup';

export const validationScheme = yup
  .object()
  .shape({
    currentPassword: yup.string().trim().required('shared.error.required'),
    newPassword: yup
      .string()
      .trim()
      .required('shared.error')
      .test(
        'password-match',
        'authentication.password.passShouldNotMatch',
        (value, context) => {
          return context.parent.currentPassword !== value;
        }
      ),
  })
  .required();
