import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIControlsSchema } from './uiControlsShema';
import { CONTROL_IDS } from 'shared/consts';

const initialState: UIControlsSchema = {
  [CONTROL_IDS.AVAILABLE_PLANS]: { isOpen: true },
};

const uiControlsSlice = createSlice({
  name: 'uiControls',
  initialState,
  reducers: {
    toggleState: (
      state,
      action: PayloadAction<{ elementId: string; newState: any }>
    ) => {
      const { elementId, newState } = action.payload;
      state[elementId] = { ...state[elementId], ...newState };
    },
  },
});

export const { toggleState } = uiControlsSlice.actions;
export const { reducer: uiControlsReducer } = uiControlsSlice;
