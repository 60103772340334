import React from 'react';
import classNames from 'classnames';
import { Portal } from '../Portal';
import { Close } from '../Icon';
import { Button } from '../Button';
import styles from './styles.module.scss';
import { Logo } from '../Logo';

type ModalProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  contentClassName?: string;
  hint?: React.ReactNode;
  logo?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  className,
  contentClassName,
  hint,
  logo = true,
}) => {
  const [containerStyles, setContainerStyles] =
    React.useState<React.CSSProperties>({ display: isOpen ? 'block' : 'none' });

  const classNamePrefix = 'modal';
  const _className = classNames(className, styles[classNamePrefix]);

  React.useEffect(() => {
    let timer: number | null = null;

    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = window.setTimeout(
      () => {
        setContainerStyles({ display: isOpen ? 'block' : 'none' });
      },
      isOpen ? 0 : 300
    );
  }, [isOpen]);

  return (
    <Portal>
      <div
        style={containerStyles}
        className={classNames(styles.container, {
          [styles[`container-open`]]: isOpen,
          [styles[`container-closed`]]: !isOpen,
        })}
      >
        <div className={styles.overlay} onClick={onClose}>
          <div className={_className} onClick={(e) => e.stopPropagation()}>
            <div className={classNames(styles.content, contentClassName)}>
              {logo && <Logo />}
              <Button
                type="text"
                className={styles.close}
                icon={<Close />}
                onClick={onClose}
              />
              {children}
            </div>
          </div>
          {hint && <div className={styles.hint}>{hint}</div>}
        </div>
      </div>
    </Portal>
  );
};

export { Modal };
