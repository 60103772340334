import React from 'react';
import styles from './styles.module.scss';
import { Popover } from '../Popover';
import { Flex } from '../Flex';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { PopoverHandles } from '../Popover/Popover';

type PopconfirmProps = {
  title: React.ReactNode;
  body?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  disabled?: boolean;
};

const Popconfirm: React.FC<PopconfirmProps> = ({
  body,
  children,
  onCancel,
  onConfirm,
  title,
  className,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const popoverRef = React.useRef<PopoverHandles>(null);

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const confirm = () => {
    onConfirm();
    popoverRef.current?.close();
  };

  const cancel = () => {
    onCancel?.();
    popoverRef.current?.close();
  };

  return (
    <Popover
      position={['top']}
      reposition
      force
      disabled={disabled}
      ref={popoverRef}
      className={className}
      contentClassName={styles.popover}
      content={
        <Flex
          className={styles.content}
          spacing={16}
          direction="column"
          onMouseUp={stopPropagation}
          onMouseDown={stopPropagation}
        >
          <Typography.Text size={3}>{title}</Typography.Text>
          {body && (
            <Typography.Text size={3} type="secondary">
              {body}
            </Typography.Text>
          )}
          <Flex spacing={8} justify="flex-end">
            <Button size="small" type="default" onClick={cancel}>
              {t('shared.text.no')}
            </Button>
            <Button size="small" type="primary" onClick={confirm}>
              {t('shared.text.yes')}
            </Button>
          </Flex>
        </Flex>
      }
    >
      {children}
    </Popover>
  );
};

export { Popconfirm };
