import {Mode, STORAGE_KEY} from "../model/themeSchema";

export const getTheme = (): Mode => {
    const theme = localStorage.getItem(STORAGE_KEY);
    if (!theme) {
        const systemTheme = 'dark'
        /**
         * TODO: remove comments after decide set prefers theme
            // window.matchMedia('(prefers-color-scheme: dark)')
            // .matches
            // ? 'dark'
            // : 'light';
        */
        localStorage.setItem('theme', systemTheme);
        return systemTheme as Mode;
    } else {
        return theme as Mode;
    }
};
