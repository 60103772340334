import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { getRouteMain } from 'shared/consts/routes';
import { Button, Stack } from 'shared/ui';

import cls from './NotFoundPage.module.scss'

const NotFoundPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClickNext = () => {
        navigate(getRouteMain());
    };
    return (
        <div className="full-container">
            <div style={{ margin: '200px auto 0' }}>
                <Stack spacing={30} direction="column" justify="center">
                    <h2>{t('notFound')}</h2>
                    <Button theme="background" onClick={handleClickNext} className={cls.returnButton}>
                        {t('toMainPage')}
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

export default NotFoundPage;
