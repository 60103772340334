import React from 'react';
import { getTariffType } from 'entities/Tariff';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS } from 'shared/consts';
import { useUserData } from 'entities/User';
import { useFetchUserInfo } from 'entities/Connection';

export const useNewUser = () => {
  const { open: openGuide, setData } = useStoreUiControls(CONTROL_IDS.GUIDE);

  const { data: userInfo } = useFetchUserInfo(null);

  const { userData, setUserData } = useUserData();

  React.useEffect(() => {
    if (
      userInfo &&
      ['free-premium', 'free'].includes(
        getTariffType(userInfo.tariff)
      ) &&
      userData.isNew
    ) {
      openGuide();
      setData({ isConnectionCreate: true });
      setUserData((prev) => ({ ...prev, isNew: false }));
    }
  }, [userInfo]);
};
