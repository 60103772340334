import { $rtkApi } from 'shared/api/rtkApi';

export interface ResetValues {
  token: string;
  password: string;
}

const resetApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    reset: build.mutation<null, ResetValues>({
      query: (params) => ({
        url: '/api/reset/check',
        method: 'POST',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const useReset = resetApi.useResetMutation;
