import { useEffect } from 'react';
import { useUserActions } from '../model/userSlice';

export const useInitAuthData = () => {
    const { initAuthData } = useUserActions();

    useEffect(() => {
        initAuthData();
    }, [initAuthData]);
};
