import { useLocalStorage } from 'usehooks-ts';
import { useFetchUserInfo } from 'entities/Connection';
import React from 'react';
import { CONTROL_IDS } from 'shared/consts';
import { daysUntilDate, useStoreUiControls } from 'shared/lib';
import { useUserData } from 'entities/User';

export const useExpiredPlan = () => {
  const [isBannerShowed, setIsBannerShowed] = useLocalStorage<boolean>(
    CONTROL_IDS.PREMIUM_EXPIRED_BANNER,
    false
  );
  const [isBannerAfterSevenDaysShowed, setIsBannerAfterSevenDaysShowed] =
    useLocalStorage<boolean>(
      CONTROL_IDS.PREMIUM_EXPIRED_BANNER_SEVEN_DAYS,
      false
    );

  const { open } = useStoreUiControls(CONTROL_IDS.PREMIUM_EXPIRED_BANNER);

  const { open: openSevenDaysBanner } = useStoreUiControls(
    CONTROL_IDS.PREMIUM_EXPIRED_BANNER_SEVEN_DAYS
  );

  const { userData } = useUserData();

  const { data: userInfo } = useFetchUserInfo(null);

  React.useEffect(() => {
    if (!userInfo || !userInfo.paidTo) return;

    const days = daysUntilDate(userInfo.paidTo);

    if (!isBannerShowed && days <= 0) {
      open();
      setIsBannerShowed(true);
    }

    if (
      !isBannerAfterSevenDaysShowed &&
      userData.lastPlanChangeDate &&
      daysUntilDate(userData.lastPlanChangeDate) <= -6
    ) {
      openSevenDaysBanner();
      setIsBannerAfterSevenDaysShowed(true);
    }
  }, [userInfo]);
};
