/* eslint-disable no-restricted-imports */
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { App } from 'app/App';
import { StoreProvider } from 'app/providers/StoreProvider';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import './i18n.ts';
import { RefContextProvider } from 'shared/context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <StoreProvider>
      <RefContextProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </RefContextProvider>
    </StoreProvider>
  </BrowserRouter>
);
