import React, { ReactNode } from 'react';

import { Text } from './Text';
import { Title } from './Title';
import { Link } from './Link';
import { Mark } from './Mark';

type TypographyProps = {
  children: ReactNode;
};

const Typography: React.FC<TypographyProps> & {
  Text: typeof Text;
  Title: typeof Title;
  Link: typeof Link;
  Mark: typeof Mark;
} = ({ children }) => {
  return <Text>{children}</Text>;
};

Typography.Text = Text;
Typography.Title = Title;
Typography.Link = Link;
Typography.Mark = Mark;

export { Typography };
