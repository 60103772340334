import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type MarkProps = {
  children: React.ReactNode;
  strong?: boolean;
  className?: string;
};

const Mark: React.FC<MarkProps> = ({ children, strong, className }) => {
  const classNamePrefix = 'mark';
  const _className = classNames(className, styles[classNamePrefix], {
    [styles[`${classNamePrefix}-strong`]]: strong,
  });

  return <p className={_className}>{children}</p>;
};

export { Mark };
