import React from 'react';
import styles from './styles.module.scss';
import { Button } from '../Button';
import { Arrow, Copy1, Copy2 } from '../Icon';
import { Typography } from '../Typography';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TextType } from '../Typography/Text';

type CopyProps = {
  text: string;
  displayText?: string;
  textSize?: number;
  variant?: 'filled' | 'outlined';
  className?: string;
  disabled?: boolean;
  truncate?: boolean;
};

const Copy: React.FC<CopyProps> = ({
  text,
  displayText,
  textSize = 3,
  variant = 'outlined',
  className,
  disabled = false,
  truncate,
}) => {
  const { t } = useTranslation();

  const defaultText = displayText ?? text;

  const classNamePrefix = 'copy';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-${variant}`],
    {
      [styles[`${classNamePrefix}-disabled`]]: disabled,
      [styles.truncated]: truncate,
    }
  );

  const [buttonText, setButtonText] = React.useState<string>(defaultText);
  const [textType, setTextType] = React.useState<TextType>('default');

  const timerRef = React.useRef<number | null>(null);

  const onTextCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);

      setButtonText(t('shared.message.copy'));
      setTextType('success');
    } catch (err) {
      setButtonText(t('shared.error.copy'));
      setTextType('danger');
    } finally {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
        timerRef.current = null;
      }

      timerRef.current = window.setTimeout(() => {
        setButtonText(defaultText);
        setTextType('default');
      }, 2000);
    }
  };

  React.useEffect(() => {
    setButtonText(defaultText);
  }, [defaultText]);

  return (
    <Button
      disabled={disabled}
      onClick={onTextCopy}
      className={_className}
      prefix={disabled ? <Arrow className={styles.arrow} /> : null}
      suffix={
        disabled ? null : variant === 'outlined' ? (
          <Copy1 className={styles.icon1} />
        ) : (
          <Copy2 className={styles.icon2} />
        )
      }
      type="text"
    >
      <Typography.Text type={textType} size={textSize} className={styles.text}>
        {buttonText}
      </Typography.Text>
    </Button>
  );
};

export { Copy };
