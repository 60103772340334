import React from 'react';
import { Flex, QueryStatusBoundary, Typography } from 'shared/_ui';
import { useCreateXrayConnection } from 'features/CreateXrayConnection';

import {
  ConnectionAdd,
  ConnectionCard,
  useDeleteXrayConnection,
  useFetchUserInfo,
  useFetchXrayConnections,
} from 'entities/Connection';
import { useTranslation } from 'react-i18next';
import {
  ConnectionActions,
  getCountryServer,
} from 'features/ConnectionActions';
import { CountrySelect } from 'features/CountrySelect';
import styles from './styles.module.scss';
import { CONTROL_IDS } from 'shared/consts';
import { useStoreUiControls } from 'shared/lib';
import { useConnectionDevices } from 'entities/Device';
import { getCountryCode } from 'entities/Country';
import { XrayConnectionCard } from 'entities/Connection';
import { XrayConnectionActions } from 'features/ConnectionActions';
import { XrayCountrySelect } from 'features/XrayCountrySelect';
import { notify } from 'shared';

const ConnectionList: React.FC = () => {
  const { t } = useTranslation();

  const {
    isLoading: isUserDataLoading,
    data: userData,
    isError: isUserDataError,
    refetch: refetchUserData,
  } = useFetchUserInfo(null);

  const { open, setData } = useStoreUiControls(CONTROL_IDS.GUIDE);
  const { data: xrayConnections } = useFetchXrayConnections(null);
  const { getDevice } = useConnectionDevices();

  const onConnectionAdd = () => {
    setData({ isConnectionCreate: true });
    open();
  };

  const isAddDisabled = userData
    ? userData.connections.length +
        (xrayConnections ? xrayConnections?.length : 0) >=
      userData.tariff.numberOfAccounts
    : true;

  const [createXrayConnection, { isLoading: isCreateXrayLoading }] =
    useCreateXrayConnection();

  const [deleteXrayConnection, { isLoading: isDeleteXrayConnectionLoading }] =
    useDeleteXrayConnection();

  return (
    <Flex direction="column" spacing={20}>
      <Typography.Title level={2}>{t('connection.list')}</Typography.Title>
      <Flex direction="column">
        <QueryStatusBoundary
          isLoading={isUserDataLoading}
          isError={isUserDataError}
          refresh={refetchUserData}
        >
          <div className={styles.container}>
            {xrayConnections?.map((xrayConnection) => (
              <XrayConnectionCard
                expiredAt={xrayConnection.expiredAt}
                key={xrayConnection.id}
                actionsMenu={<XrayConnectionActions id={xrayConnection.id} />}
                countryMenu={
                  <XrayCountrySelect
                    isNewConnectionLoading={
                      isCreateXrayLoading || isDeleteXrayConnectionLoading
                    }
                    onConfirm={async (
                      connectionId: number,
                      countryId: number
                    ) => {
                      if (
                        xrayConnections.some(
                          (connection) => connection.serverId === countryId
                        )
                      ) {
                        notify.info(t('xray.duplicateConnection'));
                        return;
                      }
                      await deleteXrayConnection(connectionId);

                      createXrayConnection(countryId);
                    }}
                    country={xrayConnection.country}
                    id={xrayConnection.id}
                  />
                }
                host={xrayConnection.subscription}
                device={getDevice(xrayConnection.id) ?? 'windows'}
              />
            ))}

            {userData?.connections.map((connection) => {
              const countryCode = getCountryCode(connection.outgoingCountry);
              const server = countryCode
                ? getCountryServer(countryCode).server
                : '';

              return (
                <ConnectionCard
                  key={connection.id}
                  actionsMenu={
                    <ConnectionActions id={connection.id} server={server} />
                  }
                  countryMenu={
                    <CountrySelect
                      id={connection.id}
                      country={connection.outgoingCountry}
                    />
                  }
                  device={getDevice(connection.id) ?? 'windows'}
                  host={server}
                  user={connection.login}
                  password={connection.password}
                />
              );
            })}
            <ConnectionAdd
              disabled={isAddDisabled}
              onConnectionAdd={onConnectionAdd}
            />
          </div>
        </QueryStatusBoundary>
      </Flex>
    </Flex>
  );
};

export { ConnectionList };
