import React from 'react';
import { Button, Moon, Sun } from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'entities/Theme';
import styles from './styles.module.scss';

const ThemeSwitcher: React.FC = () => {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation();

  const toggleTheme = () => {
    setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  return (
    <Button
      className={styles.button}
      type="text"
      shape="round"
      size="large"
      icon={
        theme === 'dark' ? (
          <Sun className={styles.icon} />
        ) : (
          <Moon className={styles.icon} />
        )
      }
      onClick={toggleTheme}
    >
      {t('theme.toggle')}
    </Button>
  );
};

export { ThemeSwitcher };
