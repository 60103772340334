import React from 'react';
import { ChangePasswordForm } from 'features/ChangePassword';
import { AuthenticationContainer } from 'entities/Authentication';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/_ui';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { getRouteLogin } from 'shared/consts';

export const ChangePasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthenticationContainer
      title={t('authentication.password.change')}
      hint={
        <div className={styles.hint}>
          <Typography.Text type="secondary">
            {t('authentication.registration.alreadyHaveAccount')}
          </Typography.Text>
          <Typography.Link type="primary">
            <Link to={getRouteLogin()}>
              {t('authentication.registration.login')}
            </Link>
          </Typography.Link>
        </div>
      }
    >
      <ChangePasswordForm />
    </AuthenticationContainer>
  );
};
