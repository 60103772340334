import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { translationEn } from '_locales/en/translation';
import { translationRu } from '_locales/ru/translation';

const initOptions: InitOptions = {
  resources: {
    en: {
      translation: translationEn,
    },
    ru: {
      translation: translationRu,
    },
  },
  cache: ['localStorage'],
  fallbackLng: 'en',
  returnNull: false,
  react: {
    defaultTransParent: 'div', // a valid react element - required before react 16
    transEmptyNodeValue: '', // what to return for empty Trans
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
    transWrapTextNodes: '',
  },
  load: 'languageOnly',
  detection: { convertDetectedLanguage: (language) => language.split('-')[0] },
};

i18next.use(initReactI18next).use(LanguageDetector).init(initOptions);

const t = i18next.t.bind(i18next);
export { t };
