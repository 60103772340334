import { LOCAL_STORAGE_USER_KEY } from 'shared/consts';

export const clearStorages = (isReload: boolean) => {
  if (isReload) {
    window.location.reload();
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  sessionStorage.clear();
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++)
    document.cookie = cookies[i] + '=;expires=' + new Date(0).toUTCString();
};
