import { useLocalStorage } from 'usehooks-ts';
import { useFetchUserInfo } from 'entities/Connection';
import { getTariffType } from 'entities/Tariff';
import { useUserData } from 'entities/User';
import React from 'react';
import { CONTROL_IDS } from 'shared/consts';
import { useStoreUiControls } from 'shared/lib';

export const useSpeedLimit = () => {
  const [isBannerShowed, setIsBannerShowed] = useLocalStorage<boolean>(
    CONTROL_IDS.SPEED_LIMIT_BANNER,
    false
  );

  const { userData } = useUserData();

  const { open } = useStoreUiControls(CONTROL_IDS.SPEED_LIMIT_BANNER);

  const { data: userInfo } = useFetchUserInfo(null);

  React.useEffect(() => {
    if (!userInfo || isBannerShowed) return;

    const tariffType = getTariffType(userInfo.tariff);

    if (
      (tariffType === 'free' && userData.prevPlan === 'free-premium') ||
      (tariffType === 'free' && userData.prevPlan === 'premium')
    ) {
      open();
      setIsBannerShowed(true);
    }
  }, [userInfo, userData]);
};
