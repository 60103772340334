import React from 'react';
import { Flex, Modal } from 'shared/_ui';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { InfoCollapse } from '../InfoCollapse';
import styles from './styles.module.scss';
import { InfoEntry } from '../../model';
import { useRefContext } from 'shared/context';

const HowItWorksModal: React.FC = () => {
  const { isOpen, close } = useStoreUiControls(CONTROL_IDS.HOW_IT_WORKS_MODAL);

  const { refs } = useRefContext();

  const scrollToTariffList = () => {
    const layoutRef = refs.current[REF_IDS.LAYOUT];
    const availablePlansRef = refs.current[REF_IDS.AVAILABLE_PLANS];

    if (layoutRef && availablePlansRef) {
      const scrollOffset = 32;

      const elementPosition =
        availablePlansRef.getBoundingClientRect().top + layoutRef.scrollTop;
      const offsetPosition = elementPosition - scrollOffset;

      layoutRef.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const INFO: InfoEntry[] = [
    {
      title: 'howItWorks',
    },
    {
      title: 'gift',
      action: () => {
        scrollToTariffList();
        close();
      },
    },
    {
      title: 'free',
      action: () => {
        scrollToTariffList();
        close();
      },
    },
    { title: 'bot' },
    { title: 'chat' },
    { title: 'feedback' },
  ];

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      <div className={styles.wrapper}>
        <Flex direction="column" spacing={8}>
          {INFO.map((topic, topicIndex) => (
            <InfoCollapse
              key={topicIndex}
              topic={topic.title}
              action={topic?.action}
            />
          ))}
        </Flex>
      </div>
    </Modal>
  );
};

export { HowItWorksModal };
