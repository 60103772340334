import { $rtkApi } from './rtkApi';

export enum ConfigVpnType {
    ANDROID_SWAN= "ANDROID_SSWAN",
    APPLE_NATIVE = "APPLE_NATIVE"

}

export interface ProfileType {
    type: ConfigVpnType,
    description: string
}

const CACHE_TIMER = 3600;

const fetchProfileTypesApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchProfileTypes: build.query<ProfileType[], null>({
            query: () => ({
                url: '/api/dictionary/profile_types',
            }),
            keepUnusedDataFor: CACHE_TIMER,
        }),
    }),
    overrideExisting: false,
});

export const useFetchProfileTypes = fetchProfileTypesApi.useFetchProfileTypesQuery;
