import { $rtkApi } from 'shared/api/rtkApi';
import {Connection} from "../../Connection";

interface Country {
    id: number;
    name: string;
}

const CACHE_TIMER = 3600;

const fetchCountriesApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchCountries: build.query<Country[], null>({
            query: () => ({
                url: '/api/dictionary/countries',
            }),
            keepUnusedDataFor: CACHE_TIMER,
        }),
    }),
    overrideExisting: false,
});

interface Params {
    id: number;
    newOutgoingCountryId: number;
}

const editCountriesApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        editCountries: build.mutation<Connection, Params>({
            query: (params) => ({
                url: '/api/radius/user/country',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});

export const useEditCountries = editCountriesApi.useEditCountriesMutation;

export const useFetchCountries = fetchCountriesApi.useFetchCountriesQuery;
