import React from 'react';
import classNames from 'classnames';
import { IconProps } from './icon.props';
import styles from './styles.module.scss';

const Download: React.FC<IconProps> = ({ className }) => {
  const _className = classNames(className, styles.icon);

  return (
    <svg
      className={_className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 21H3M18 11L12 17M12 17L6 11M12 17V3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Download };
