import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLoader } from 'widgets/PageLoader';
import { AppRoutesProps, routeConfig } from '../config/routeConfig';
import { RequireAuth } from './RequireAuth';

export const AppRouter: FC = () => {
    const renderWithWrapper = ({ path, element, authOnly }: AppRoutesProps) => (
        <Route
            key={path}
            path={path}
            element={authOnly ? <RequireAuth>{element}</RequireAuth> : element}
        ></Route>
    );

    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
        </Suspense>
    );
};
