import type { PayloadAction } from '@reduxjs/toolkit';
import { buildSlice } from 'shared/lib/store';
import { LOCAL_STORAGE_USER_KEY } from 'shared/consts/localStorage';
import { modifyAuthData } from '../lib/modifyAuthData';
import { ResponseUser, UserSchema } from './userSchema';

const initialState: UserSchema = { _inited: false };

const userSlice = buildSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<ResponseUser>) => {
            const authData = modifyAuthData(action.payload);

            localStorage.setItem(
                LOCAL_STORAGE_USER_KEY,
                JSON.stringify(authData)
            );
            state.authData = authData;
        },
        initAuthData: (state) => {
            const user = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
            if (user) {
                state.authData = JSON.parse(user);
            }
            state._inited = true;
        },
        logout: (state) => {
            state.authData = undefined;
        },
    },
});

export const { actions: userActions } = userSlice;
export const { useActions: useUserActions } = userSlice;
export const { reducer: userReducer } = userSlice;
