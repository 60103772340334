import { FC, ReactNode, memo } from 'react';
import classNames from 'classnames';
import cls from './Typography.module.scss';

type TextTheme = 'primary' | 'error';
type TextSize = 'size_s' | 'size_m' | 'size_l' | 'size_xl' | 'size_xxl';
type TextAlign = 'right' | 'left' | 'center';
type TextWeight = 'light' | 'normal' | 'bold';
type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

interface TextProps {
    className?: string;
    title?: string;
    text?: string;
    theme?: TextTheme;
    size?: TextSize;
    align?: TextAlign;
    weight?: TextWeight;
    TitleTag?: TitleTag;
    emptyBlock?: boolean;
    children?: ReactNode;
}

export const Typography: FC<TextProps> = memo((props) => {
    const {
        className,
        children,
        theme = 'primary',
        size = 'size_m',
        align = 'left',
        weight = 'normal',
        TitleTag = 'p',
        emptyBlock = false,
    } = props;
    return (
        <div
            className={classNames(
                className,
                cls[theme],
                cls[size],
                cls[align],
                cls[weight],
                { [cls.emptyBlock]: emptyBlock }
            )}
        >
            {children && <TitleTag className={cls.text}>{children}</TitleTag>}
        </div>
    );
});
