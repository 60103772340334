import React from 'react';
import { Device } from 'entities/Device';
import { Copy, Flex, Image } from 'shared/_ui';
import styles from './styles.module.scss';
import { useTheme } from 'entities/Theme';
import { getDeviceImageByTheme } from '../../lib';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type ConnectionCardProps = {
  actionsMenu: React.ReactNode;
  countryMenu: React.ReactNode;
  host: string;
  user: string;
  password: string;
  device: Device;
  className?: string;
};

const ConnectionCard: React.FC<ConnectionCardProps> = ({
  actionsMenu,
  countryMenu,
  host,
  user,
  password,
  device,
  className,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const deviceImage = getDeviceImageByTheme(device, theme);

  const classNamePrefix = 'connection';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <Flex
      spacing={16}
      direction="column"
      justify="space-between"
      className={_className}
    >
      <div className={styles.actions}>{actionsMenu}</div>
      <div className={styles.badge}>{t('non-app.connection')}</div>

      <Image
        height={272}
        className={styles.image}
        src={deviceImage}
        alt={device}
      />
      <Flex spacing={20} direction="column" className={styles.description}>
        <div className={styles.country}>{countryMenu}</div>
        <Flex spacing={4} direction="column" className={styles.params}>
          <Copy text={host} />
          <Copy text={user} />
          <Copy text={password} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export { ConnectionCard };
