import { GuideStep } from 'entities/Guide';
import { Language } from 'entities/Language';
import React from 'react';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { useTranslation, Trans } from 'react-i18next';
import { Copy, Flex, Typography } from 'shared/_ui';
import { InstructionProps } from '../instruction.props';

const AndroidManualInstruction: React.FC<InstructionProps> = ({
  connection,
}) => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'android',
    'manual',
    i18n.language as Language
  );

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.android.manual.step.1.title')}
        description={
          <ul>
            <li>
              <Trans i18nKey="guide.android.manual.step.1.description.1">
                <a
                  href="https://play.google.com/store/apps/details?id=org.strongswan.android"
                  target="_blank"
                  rel="noreferrer"
                ></a>
                <a
                  href="https://drive.google.com/file/d/162fspHvI5kQR34ElfQwLFmmsyM1LdS2P/view"
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </Trans>
            </li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.android.manual.step.2.title')}
        description={
          <ul>
            <li>{t('guide.android.manual.step.2.description.1')}</li>
          </ul>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.android.manual.step.3.title')}
        description={
          <ol>
            <li>{t('guide.android.manual.step.3.description.1')}</li>
            <li>{t('guide.android.manual.step.3.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={4}
        image={getImageByStep(4)}
        title={t('guide.android.manual.step.4.title')}
        description={
          <ol>
            <li>{t('guide.android.manual.step.4.description.1')}</li>
            <li>{t('guide.android.manual.step.4.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(5)}
        title={t('guide.android.manual.step.5.title')}
        description={
          <ol>
            <li>{t('guide.android.manual.step.5.description.1')}</li>
            <Flex
              direction="column"
              spacing={12}
              style={{ width: 'fit-content', marginTop: 16, marginBottom: 32 }}
            >
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.android.title.server')}
                </Typography.Text>
                <Copy variant="filled" text={connection.server} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.android.title.user')}
                </Typography.Text>
                <Copy variant="filled" text={connection.login} />
              </Flex>
              <Flex direction="column" spacing={8}>
                <Typography.Text type="secondary">
                  {t('connection.android.title.password')}
                </Typography.Text>
                <Copy variant="filled" text={connection.password} />
              </Flex>
            </Flex>
            <li>{t('guide.android.manual.step.5.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={6}
        image={getImageByStep(6)}
        title={t('guide.android.manual.step.6.title')}
        description={
          <ul>
            <li>{t('guide.android.manual.step.6.description.1')}</li>
          </ul>
        }
        final={true}
      />
    </Flex>
  );
};

export { AndroidManualInstruction };
