import { useCallback, useEffect, useState } from 'react';
import {
    notify,
    Payment,
    PaymentStatus,
    setBodyOverflow,
    useGetPaymentTariffRefresh,
} from 'shared';
import { useTranslation } from 'react-i18next';

interface Props {
    setIsOpen: (newIsOpenState: boolean) => void;
    setOpenModal: (newIsOpenState: boolean) => void;
    currentPayment: Payment | null;
    isOpen: boolean;
}

type ScriptStatus = 'ready' | 'error' | 'notLoaded';

type MapFunc = { [key: string]: () => void };

const DELAY = 5000;
const DELAY_CLOSE_OVERLAY = 500;

export enum WidgetTypes {
    CLOUDPAYMENTS = 'CLOUDPAYMENTS',
    U_KASSA = 'U_KASSA',
    PAYCOS = 'PAYCOS',
    OXPROCESSING = 'OXPROCESSING',
}

export const useCreatePay = ({
    setIsOpen,
    currentPayment,
    isOpen,
    setOpenModal,
}: Props) => {
    const { t } = useTranslation();
    const [paymentStatus, setPaymentStatus] =
        useState<PaymentStatus>('IN_PROGRESS');
    const [configCreated, setConfigCreated] = useState(false);
    const [scriptStatus, setScriptStatus] = useState<ScriptStatus>('notLoaded');
    const [isLoading, setIsLoading] = useState(true);
    const [getPaymentStatus] = useGetPaymentTariffRefresh();
    let timer: NodeJS.Timeout | undefined = undefined;

    const handleScript = (e: Event) => {
        setIsLoading(false);
        setScriptStatus(e.type === 'load' ? 'ready' : 'error');
    };

    const handleOnClose = useCallback(() => {
        if (scriptStatus) {
            clearTimeout(timer);
            setIsLoading(true);
            setPaymentStatus('IN_PROGRESS');
            setScriptStatus('notLoaded');
            setConfigCreated(false);
            const widgetScript = document.getElementById('widget_vpn');
            if (widgetScript) {
                widgetScript.remove();
            }
            const modalContent = document.getElementById('modalUkass');
            modalContent && modalContent.classList.add('close-modal-state');
            setTimeout(() => {
                setIsOpen(false);
                setOpenModal(false);
                setBodyOverflow(false);
                setIsLoading(false);
            }, DELAY_CLOSE_OVERLAY);
        }
    }, [setIsOpen, setOpenModal, scriptStatus, timer]);

    let checkStatus = useCallback(() => {
        setTimeout(() => {
            console.log('initial checkStatus');
        }, DELAY);
    }, []);

    const handleGetPaymentStatus = useCallback(() => {
        const notifyPayment: MapFunc = {
            SUCCESS: () => notify.success(t('successPay')),
            FAIL: () => notify.error(t('errorRequest')),
        };

        if (currentPayment?.paymentId && paymentStatus === 'IN_PROGRESS') {
            getPaymentStatus(currentPayment.paymentId)
                .unwrap()
                .then((data) => {
                    setPaymentStatus(data.paymentStatus);
                    if (data.paymentStatus === 'SUCCESS') {
                        notifyPayment[data.paymentStatus]();
                        handleOnClose();
                    }
                    if (data.paymentStatus === 'FAIL') {
                        notifyPayment.FAIL();
                        handleOnClose();
                    }
                    if (data.paymentStatus === 'IN_PROGRESS') {
                        checkStatus();
                    }
                })
                .catch(() => {
                    notifyPayment.FAIL();
                    setPaymentStatus('FAIL');
                    handleOnClose();
                });
        }
    }, [
        currentPayment,
        getPaymentStatus,
        handleOnClose,
        paymentStatus,
        t,
        checkStatus,
    ]);

    checkStatus = () => {
        timer = setTimeout(() => {
            handleGetPaymentStatus();
        }, DELAY);
    };

    useEffect(() => {
        const configCloudPayments = () => {
            if (currentPayment?.token && !configCreated) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const payments = new cp.CloudPayments();
                payments.pay(
                    'charge',
                    {
                        publicId: currentPayment?.token, // 'test_api_00000000000000000000002',
                        description: '',
                        amount: currentPayment?.amount,
                        currency: currentPayment?.currency,
                        invoiceId: currentPayment?.paymentId, // 1234567,
                        data: currentPayment?.receiptData,
                    },
                    {
                        onSuccess: function () {
                            setIsLoading(true);
                            checkStatus();
                        },
                        onFail: function () {
                            setPaymentStatus('FAIL');
                            notify.error(t('errorRequest'));
                            handleOnClose();
                        },
                    }
                );
            }
        };
        const configUKassa = () => {
            if (currentPayment?.token && !configCreated) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const checkout = new window.YooMoneyCheckoutWidget({
                    confirmation_token: currentPayment?.token,
                    error_callback: function () {
                        setPaymentStatus('FAIL');
                        notify.error(t('errorRequest'));
                        handleOnClose();
                    },
                });
                checkout.render('payment-form');
                checkout.on('complete', () => {
                    setIsLoading(true);
                    checkStatus();
                    checkout.destroy();
                });
                setConfigCreated(true);
            }
        };

        const configPaycos = () => {
            if (currentPayment?.paymentId && !configCreated) {
                setConfigCreated(true);
                checkStatus();
            }
        };
        const configOxPressing = () => {
            if (currentPayment?.paymentId && !configCreated) {
                setConfigCreated(true);
                checkStatus();
            }
        };

        const widgetTypeMap: MapFunc = {
            CLOUDPAYMENTS: () => configCloudPayments(),
            U_KASSA: () => configUKassa(),
            PAYCOS: () => configPaycos(),
            OXPROCESSING: () => configOxPressing(),
        };
        const configWidget = () => {
            if (currentPayment?.widgetType) {
                widgetTypeMap[currentPayment.widgetType]();
            }
        };
        if (scriptStatus === 'ready' && currentPayment?.widgetType) {
            configWidget();
        }
        if (scriptStatus === 'error') {
            notify.error(t('errorLoadScript'));
            handleOnClose();
        }
    }, [
        t,
        scriptStatus,
        configCreated,
        currentPayment,
        checkStatus,
        handleOnClose,
    ]);

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            const script = document.getElementById('widget_vpn');
            if (currentPayment?.widgetType === WidgetTypes.PAYCOS) {
                setScriptStatus('ready');
            } else if (
                currentPayment?.widgetType === WidgetTypes.OXPROCESSING
            ) {
                setScriptStatus('ready');
            } else {
                script && script.addEventListener('load', handleScript);
            }
        } else {
            handleOnClose();
        }
    }, [isOpen, currentPayment?.widgetType, scriptStatus, handleOnClose]);

    return {
        handleOnClose,
        isLoading,
    };
};
