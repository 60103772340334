import { LANGUAGES } from 'entities/Language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Typography } from 'shared/_ui';
import styles from './styles.module.scss';

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  return (
    <Flex spacing={20} className={styles.group}>
      {LANGUAGES.map((lang) =>
        lang === i18n.language ? (
          <Button
            key={lang}
            type="text"
            className={styles.language}
            onClick={() => i18n.changeLanguage(lang)}
          >
            <Typography.Text size={3} type="default" transform="uppercase">
              {lang}
            </Typography.Text>
          </Button>
        ) : (
          <Button
            key={lang}
            type="text"
            className={styles.language}
            onClick={() => i18n.changeLanguage(lang)}
          >
            <Typography.Text size={3} type="secondary" transform="uppercase">
              {lang}
            </Typography.Text>
          </Button>
        )
      )}
    </Flex>
  );
};
