export const createPaymentFrame = (url?: string) => {
  if (!url) return;
  const frameElement = document.createElement('iframe');

  frameElement.src = url;
  frameElement.id = 'widget_vpn';
  frameElement.setAttribute('class', 'framePaycos');
  frameElement.setAttribute(
    'sandbox',
    'allow-same-origin allow-scripts allow-popups allow-forms'
  );
  const paycosModal = document.getElementById('modalPaycos');

  if (paycosModal) {
    paycosModal.appendChild(frameElement);
  }
};
