import React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';

type SelectProps = React.ComponentProps<typeof ReactSelect>;

const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  value,
  ...otherProps
}) => {
  const colourStyles: StylesConfig<unknown> = {
    control: (styles, { menuIsOpen, isFocused }) => ({
      ...styles,
      backgroundColor: 'var(--select-bg-color)',
      borderRadius: menuIsOpen ? '10px 10px 0 0' : '10px',
      boxShadow: 'none',
      color: 'var(--select-font-color)',
      cursor: 'pointer',
      borderColor: isFocused
        ? 'var(--select-border-color-active)'
        : 'var(--select-border-color)',
      ':hover': {
        borderColor: 'var(--select-border-color-active)',
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'var(--select-placeholder-color)',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: `var(--select-font-color)`,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: `var(--select-font-color)`,
      ':hover': {
        color: `var(--select-font-color)`,
      },
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: '0 0 10px 10px',
      overflow: 'hidden',
      margin: '0',
      backgroundColor: 'var(--select-menu-bg)',
    }),

    option: (styles, { isSelected }) => ({
      ...styles,
      background: isSelected
        ? 'var(--select-option-bg)'
        : 'var(--select-menu-bg)',
      cursor: 'pointer',
      color: `var(--select-font-color)`,
      ':hover': {
        background: 'var(--select-option-bg-active)',
      },
    }),
  };

  return (
    <ReactSelect
      isMulti={false}
      styles={colourStyles}
      isSearchable={false}
      options={options}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export { Select };
