import React from 'react';

type RefSetter = (id: string, node: HTMLElement | null) => void;

interface RefContextType {
  refs: React.MutableRefObject<Record<string, HTMLElement | null>>;
  setRef: RefSetter;
}
const RefContext = React.createContext<RefContextType>(null!);

const useRefContext = () => React.useContext(RefContext);

type RefContextProviderProps = {
  children: React.ReactNode;
};

const RefContextProvider: React.FC<RefContextProviderProps> = ({
  children,
}) => {
  const refs = React.useRef<Record<string, HTMLElement | null>>({});

  const setRef = React.useCallback<RefSetter>((id, node) => {
    refs.current[id] = node;
  }, []);

  return (
    <RefContext.Provider value={{ refs, setRef }}>
      {children}
    </RefContext.Provider>
  );
};

export { useRefContext, RefContextProvider };
