import * as yup from 'yup';

export const validationScheme = yup.object().shape({
  login: yup
    .string()
    .trim()
    .lowercase()
    .email('shared.error.email')
    .required('shared.error.required'),
  password: yup.string().required('shared.error.required'),
});
