import { $rtkApi } from 'shared/api';

export interface CreateReset {
    email: string;
}

const resetApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        create: build.mutation<null, CreateReset>({
            query: (params) => ({
                url: '/api/reset/create',
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});

export const useCreateReset = resetApi.useCreateMutation;
