import { Navigate, useLocation } from 'react-router-dom';
import { useUserAuthData, useUserData } from 'entities/User';
import { getRouteLogin, getSignUp } from 'shared/consts/routes';

interface RequireAuthProps {
  children: React.ReactNode;
}

export function RequireAuth({ children }: RequireAuthProps): JSX.Element {
  const authData = useUserAuthData();
  const location = useLocation();
  const { userData } = useUserData();

  if (!authData) {
    return (
      <Navigate
        to={userData.isNew ? getSignUp() : getRouteLogin()}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}
