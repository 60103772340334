export const calculateDiscountPercentage = (
  basePrice: number,
  discountedPrice: number
): number => {
  if (basePrice <= 0) {
    return 0;
  }

  if (discountedPrice > basePrice) {
    return 0;
  }

  const discountAmount = basePrice - discountedPrice;
  return Math.round((discountAmount / basePrice) * 100);
};
