const daysUntilDate = (targetDate: string): number => {
  const now = new Date();
  const target = new Date(targetDate);
  const differenceInMilliseconds = target.getTime() - now.getTime();
  const days = differenceInMilliseconds / (1000 * 3600 * 24);
  return Math.ceil(days);
};

const formatDateToEuro = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export { daysUntilDate, formatDateToEuro };
