import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from 'entities/User';
import { themeReducer } from 'entities/Theme';
import { $rtkApi } from 'shared/api/rtkApi';
import { StateSchema } from './StateSchema';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';
import { uiControlsReducer } from 'shared/model';

export function createReduxStore(initialState: StateSchema) {
  return configureStore({
    reducer: {
      [$rtkApi.reducerPath]: $rtkApi.reducer,
      user: userReducer,
      theme: themeReducer,
      uiControls: uiControlsReducer,
    },
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat($rtkApi.middleware)
        .concat(rtkQueryErrorLogger),
  });
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
