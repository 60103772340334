import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type TitleProps = {
  children: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5;
  className?: string;
};

const Title: React.FC<TitleProps> = ({ children, level, className }) => {
  const classNamePrefix = 'title';
  const _className = classNames(className, styles[classNamePrefix]);

  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  return <Tag className={_className}>{children}</Tag>;
};

export { Title };
