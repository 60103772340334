import classNames from 'classnames';
import { FC, memo } from 'react';
import cls from './Loader.module.scss';

interface LoaderProps {
    className?: string;
}

export const Loader: FC<LoaderProps> = memo(({ className }) => (
    <div className={classNames(cls['lds-ellipsis'], className)}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
));
